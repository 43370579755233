import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    backgroundColor: colors.blue10,
    color: colors.blue120,
    padding: '8px 16px',
    paddingTop: 9,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 12,
    marginBottom: 12,
  },
}));
