import React, { useEffect, useState } from 'react';

import { Loader } from '@amazd/common/components';
import { CallActionTypeEnum } from '@amazd/common/components/RingingPopup/types';
import StreamChat from '@amazd/common/components/StreamChat';
import { useVideoCallContext } from '@amazd/common/components/VideoChatView/contexts/videoCallContext';
import { useAuthInfo } from '@amazd/common/hooks/auth';
import { useChannelActors } from '@amazd/common/hooks/useStreamChannel';
import { useChannelList } from '@amazd/common/hooks/useStreamChannelList';
import { amazdDetailsActions } from '@amazd/common/redux/actions';
import { Storage } from '@amazd/common/storage';
import { UserRoleEnum, UserType } from '@amazd/common/types';
import { trackChatPageView, trackGreetingMessageScheduleClick } from '@amazd/common/utils/analytics/events';
import { useTranslation } from '@amazd/common/utils/i18n';
import { hasAccessTo, Permission } from '@amazd/common/utils/permissions';
import { Box, SelectChangeEvent } from '@mui/material';
import ChatHeader from 'components/ChatHeader';
import InboxLeftSidebar from 'components/InboxLeftSidebar';
import InboxRecentSearch from 'components/InboxRecentSearch';
import InboxSearchBar from 'components/InboxSearchBar';
import IOSSwitch from 'components/IOSSwitch';
import ReassignDialog from 'components/ReassignDialog';
import { SegmentPill } from 'components/SegmentPill';
import { ShopifyCataloguePopupProvider } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { ShopifyCartProvider } from 'components/Shopify/wishBag/hooks/useCart';
import { MessageInputWrapper } from 'components/StreamChat';
import { CustomUserMessageBox } from 'components/StreamChat/messages/customUserMessageBox';
import { useHead } from 'contexts/headContext';
import { AmazdsTab, useAmazdsTabs } from 'hooks/useAmazdsList';
import { useScreenSize } from 'hooks/useScreenSize';
import { SnackbarProvider, useSnackbar } from 'hooks/useSnackbar';
import { useWidgetScreen } from 'hooks/useWidgetScreen';
import DashboardLayout from 'layouts/DashboardLayout';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import type { Channel, ChannelFilters } from 'stream-chat';
import { screenSize1280 } from 'styles/theme';
import { CompanyExpert, useMyCompanyExperts } from 'views/InboxView/useCompanyExperts';

import { ChatSidebar } from './ChatSidebar';
import { ChatSidebarProvider } from './ChatSidebar/context/sidebarContext';
import NoAmazds from './NoAmazds';
import useStyles from './styles';

const SCROLL_THRESHOLD_PX = 50;

const InboxView: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const { isWidgetScreen } = useWidgetScreen();
  const head = useHead();
  const { query } = router;
  const listRef = React.useRef<HTMLUListElement>(null);

  const { ownUser, ownUserType } = useAuthInfo();
  const isExpert = ownUser?.role === UserRoleEnum.USER && ownUser?.userTypes?.[0]?.isExpert === true;
  const { myCompanyExperts } = useMyCompanyExperts({
    skip: !isExpert && !hasAccessTo(ownUser?.role, Permission.SEE_TEAM),
  });

  const size = useScreenSize();
  const [showChatSidebar, setShowChatSidebar] = useState(size.x >= screenSize1280);
  const openChatSidebar = () => setShowChatSidebar(true);

  const [selectedTabIndex, selectTab] = useState(0);
  const [currentAmazdsListTab, setCurrentAmazdsListTab] = useState<AmazdsTab | null>(null);
  const [reachedScrollThresholdOnce, setReachedScrollThreshold] = useState(false);

  // const [focusOnInput, setFocusOnInput] = useState(false);
  const [selectedAssignTo, setSelectedAssignTo] = useState<CompanyExpert>();

  const [selectedExpertOption, setSelectedExpertedOption] = useState<string>(
    (query.selectedOption as string) || 'my-chats',
  );
  const [selectedExpertUserId, setSelectedExpertUserId] = useState<string>(
    (query.selectedOption && query.selectedOption !== 'my-chats' ? query.expertUserId : ownUser?.id) as string,
  );

  const [reassignDialogState, setReassignDialogState] = useState(false);

  const { tabs } = useAmazdsTabs();
  const [streamQuery, setStreamQuery] = useState<{ page: number; filter: ChannelFilters; showUnReadOnly: boolean }>();
  const [showUnReadOnly, setShowUnReadOnly] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { showSnackbar } = useSnackbar();

  const {
    channels,
    loading,
    findChannel,
    connected: streamConnected,
  } = useChannelList(streamQuery, {
    onUnreadChannelsChange: (count) => {
      let currentTitle = window.document.title;
      const searchString = ') ';
      const idx = currentTitle.indexOf(searchString);
      if (idx > -1) {
        currentTitle = currentTitle.slice(idx + searchString.length);
      }
      currentTitle = (count ? `(${count}) ` : '') + currentTitle;
      head.changeTitle(currentTitle);
    },
  });

  const [channel, setChannel] = useState<Channel>();
  const { owner, expert } = useChannelActors(channel);

  const { startCall } = useVideoCallContext();

  useEffect(() => {
    if (isSearchMode) setShowUnReadOnly(false);
  }, [isSearchMode]);

  useEffect(() => {
    trackChatPageView();
  }, []);

  useEffect(() => {
    if (!ownUser) return;

    if (hasAccessTo(ownUser.role, Permission.SEE_TEAM)) {
      if (selectedExpertUserId) {
        if (selectedExpertUserId === ownUser.id) {
          setStreamQuery({
            page: 0,
            filter: {
              type: 'messaging',
              members: { $in: [ownUser.id] },
              member_count: { $gte: 2 },
              hidden: currentAmazdsListTab?.archived,
              'member.user.name': searchQuery ? { $autocomplete: searchQuery } : undefined,
            },
            showUnReadOnly: showUnReadOnly && !currentAmazdsListTab?.archived,
          });
        } else {
          setStreamQuery({
            page: 0,
            filter: {
              type: 'messaging',
              team: ownUserType?.company?.id,
              hidden: currentAmazdsListTab?.archived,
              expert_user_id: selectedExpertUserId,
              member_count: { $gte: 2 },
            },
            showUnReadOnly: showUnReadOnly && !currentAmazdsListTab?.archived,
          });
        }
      } else {
        setStreamQuery({
          page: 0,
          filter: {
            type: 'messaging',
            $or: [{ members: { $in: [ownUser.id] } }, { team: ownUserType?.company?.id }],
            hidden: currentAmazdsListTab?.archived,
            member_count: { $gte: 2 },
          },
          showUnReadOnly: showUnReadOnly && !currentAmazdsListTab?.archived,
        });
      }
    } else {
      setStreamQuery({
        page: 0,
        filter: {
          type: 'messaging',
          members: { $in: [ownUser.id] },
          member_count: { $gte: 2 },
          team: { $ne: '*' },
          hidden: currentAmazdsListTab?.archived,
          'member.user.name': searchQuery ? { $autocomplete: searchQuery } : undefined,
        },
        showUnReadOnly: showUnReadOnly && !currentAmazdsListTab?.archived,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExpertUserId, currentAmazdsListTab?.archived, showUnReadOnly, searchQuery]);

  useEffect(() => {
    if (channels?.length && !channel) {
      const first = channels[0];
      setChannel(first);
      router.push({ pathname: '/', query: { ...query, amazdId: first.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels]);

  useEffect(() => {
    if (channels?.length && listRef.current && currentAmazdsListTab && !loading && streamQuery) {
      if (listRef.current.scrollHeight <= listRef.current.clientHeight) {
        // Channels loaded but inbox has no scroll.
        // The screen is too big and there are not enough chats to fill the list so scroll won't auto load.
        // https://app.shortcut.com/amazd/story/9473/large-screen-cannot-scroll-through-inbox
        setStreamQuery({
          ...streamQuery,
          page: streamQuery.page + 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels?.length]);

  useEffect(() => {
    if (!query.amazdId && channels?.length)
      router.push({ pathname: '/', query: { ...query, amazdId: channels[0].id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const loadChannel = async () => {
      const { amazdId, ...restQueryParams } = query;
      try {
        const channel = await findChannel(amazdId as string);
        if (channel) {
          // Check if current user still has an access to the channel, as it might be lost due to reassigment.
          // https://app.shortcut.com/amazd/story/9025/security-issue-conversations-still-accessible-by-experts-after-reassign
          if (ownUser && (channel.state?.members[ownUser.id] || hasAccessTo(ownUser.role, Permission.SEE_TEAM))) {
            setChannel(channel);
          } else {
            // otherwise, remove amazd id from query params and navigate to the first channel on the list
            router.push({ pathname: '/', query: restQueryParams });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (query.amazdId && streamConnected && ownUser) {
      loadChannel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamConnected, query.amazdId, ownUser]);

  useEffect(() => {
    // If video param is set then let the user to call or schedule a call
    if (channel && query.video == 'true') {
      openChatSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id]);

  useEffect(() => {
    if (tabs && tabs.length) {
      const tab = tabs[selectedTabIndex];
      setCurrentAmazdsListTab(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const handleOnSelectAmazd = (selected: Channel) => {
    if (channels && channel?.id !== selected.id) {
      router.push({ pathname: '/', query: { ...query, amazdId: selected.id } });
      setChannel(selected);
    }
  };

  const handleTabIndexChange = (_: any, index: number) => {
    if (tabs) {
      selectTab(index);
      const tab = tabs[index];
      router.push({ pathname: '/', query: {} });
      setCurrentAmazdsListTab(tab);
    }
  };

  const handleHideChannel = async (channelId: string) => {
    if (channels) {
      const index = channels.findIndex((x) => x.id === channelId);
      if (index >= 0) {
        const nextToSelect = channels[index + 1] || channels[0];
        await channels[index].hide();
        if (channels.length) handleOnSelectAmazd(nextToSelect);
      }
    }
  };

  const handleShowChannel = async (channelId: string) => {
    if (channels) {
      const index = channels.findIndex((x) => x.id === channelId);
      if (index >= 0) {
        const nextToSelect = channels[index + 1] || channels[0];
        await channels[index].show();
        if (channels.length) handleOnSelectAmazd(nextToSelect);
      }
    }
  };

  const openReassignDialog = () => {
    setReassignDialogState(true);
  };

  const handleReassignDialogClose = () => {
    setReassignDialogState(false);
  };

  const handleAssignToChange = (change: SelectChangeEvent<unknown>) => {
    const selectedUserId = change.target.value as string;
    const selectedUser = myCompanyExperts?.find((x) => x.id === selectedUserId);
    setSelectedAssignTo(selectedUser);
    openReassignDialog();
  };

  const handleReassign = async (description: string) => {
    if (channel && selectedAssignTo) {
      // clear unsent message from local storage
      const unsentMessageStorage = new Storage(`draftmsg-${channel.id as string}`);
      unsentMessageStorage.setItem('');
      dispatch(amazdDetailsActions.reassignAmazd(channel.id as string, selectedAssignTo?.id, description));
      showSnackbar(
        t('reassign-dialog-success-toast-content', {
          shopperFullName: owner?.name,
          expertFullName: `${selectedAssignTo.firstName} ${selectedAssignTo.lastName}`,
        }),
        'success',
        t('reassign-dialog-success-toast-title'),
        false,
      );
      if (channels) {
        const index = channels.findIndex((x) => x.id === channel.id);
        const nextOrPrevToSelect = channels[index + 1] || channels[index - 1];
        if (nextOrPrevToSelect) {
          handleOnSelectAmazd(nextOrPrevToSelect);
        } else {
          await router.push({ pathname: '/', query: {} });
        }
      }
      setReassignDialogState(false);
    }
  };

  const handleScroll = (e: any) => {
    const reached = e.target.scrollHeight - e.target.scrollTop - SCROLL_THRESHOLD_PX <= e.target.clientHeight;
    if (reached && currentAmazdsListTab && !reachedScrollThresholdOnce && !loading && streamQuery) {
      setStreamQuery({
        ...streamQuery,
        page: streamQuery?.page + 1,
      });
    }
    setReachedScrollThreshold(reached);
  };

  const handleExpertFilterChange = (change: SelectChangeEvent<unknown>) => {
    const selectedOption = change.target.value as string;

    setSelectedExpertedOption(selectedOption);

    if (selectedOption === 'my-chats') selectTab(0);

    setShowUnReadOnly(false);

    if (tabs && tabs.length) {
      const expertUserId =
        selectedOption === 'all-chats' ? undefined : selectedOption === 'my-chats' ? ownUser?.id : selectedOption;

      router.push({ pathname: '/', query: { selectedOption, expertUserId } });

      setCurrentAmazdsListTab(tabs[0]);
      setSelectedExpertUserId(expertUserId as string);
    }
  };

  const handleScheduleCall = () => {
    trackGreetingMessageScheduleClick();
    openChatSidebar();
  };

  const handleJoinVideoCall = (emitEvent: boolean) => {
    if (channel?.id) {
      if (emitEvent) {
        startCall(channel.id, { callEventType: CallActionTypeEnum.CALLING });
        return;
      }
      startCall(channel.id);
    }
  };
  // const handleViewExpertProfileClick = (target?: EventTarget) => {
  //   if (expertUserType) {
  //     showProfile(target, <Profile user={expertUserType.user} userType={expertUserType} />);
  //   }
  // };
  if (!ownUser || !ownUserType) return <Loader size={100} />;

  return (
    <DashboardLayout hideNavbar={isWidgetScreen} className={classes.root} title={'Chat'}>
      {!tabs && <Loader size={100} />}
      {tabs && (
        <>
          <InboxLeftSidebar
            loading={loading}
            channels={channels}
            ownUser={ownUser}
            ownUserType={ownUserType as UserType}
            companyExperts={myCompanyExperts}
            selectedFilterOption={selectedExpertOption}
            selectedAmazdId={channel?.id || ''}
            onArchive={(amazdId) => handleHideChannel(amazdId)}
            onUnarchive={(amazdId) => handleShowChannel(amazdId)}
            onFilterChange={handleExpertFilterChange}
            onChannelSelect={handleOnSelectAmazd}
            onScroll={handleScroll}
            archiveToggle={
              <SegmentPill
                className={classes.amazdTabs}
                onChange={handleTabIndexChange}
                defaultSelected={tabs[selectedTabIndex].tabName}
                options={tabs?.map((tab) => tab.tabName) || []}
              />
            }
            isArchievedList={currentAmazdsListTab?.archived}
            unReadSwitch={
              (!selectedTabIndex || selectedExpertOption === 'all-chats') && !isSearchMode ? (
                <div className={classes.unReadSwitch}>
                  <IOSSwitch
                    key={selectedTabIndex}
                    value={showUnReadOnly}
                    defaultChecked={showUnReadOnly}
                    onChange={() => setShowUnReadOnly(!showUnReadOnly)}
                  />
                  <div className={classes.unReadSwitchLabel}>{t('inbox-unread-switch-label')}</div>
                </div>
              ) : null
            }
            showUnReadOnly={showUnReadOnly && !selectedTabIndex}
            searchBar={
              <InboxSearchBar
                isExpertView={!hasAccessTo(ownUser?.role, Permission.COMPANY_AMAZDS)}
                defaultSearchMode={isSearchMode && hasAccessTo(ownUser?.role, Permission.COMPANY_AMAZDS)}
                placeholder={t('inbox-search-bar-placeholder')}
                value={searchQuery}
                onChangeText={(value) => setSearchQuery(value)}
                onChangeSearchMode={(isSearchMode) => setIsSearchMode(isSearchMode)}
              />
            }
            isSearchMode={isSearchMode}
            onFocusSearch={() => setIsSearchMode(true)}
            showOnlyRecentSearch={isSearchMode && searchQuery === ''}
            recentSearch={
              <InboxRecentSearch onSearchItem={(searchHistoryValue) => setSearchQuery(searchHistoryValue)} />
            }
            listRef={listRef}
          ></InboxLeftSidebar>
          {channels && channels.length === 0 && !showUnReadOnly && !loading && currentAmazdsListTab && !isSearchMode ? (
            <NoAmazds icon={currentAmazdsListTab.noAmazdIcon} text={currentAmazdsListTab.noAmazdText} />
          ) : (
            <>
              {channel && (
                <ShopifyCataloguePopupProvider channel={channel}>
                  <ShopifyCartProvider channel={channel}>
                    <ChatSidebarProvider
                      value={{
                        showSideBar: showChatSidebar,
                        setShowSideBar: () => null,
                        setShowScheduleTab: () => null,
                        setSelectedTab: () => null,
                        channel,
                        onJoinVideoCall: () => handleJoinVideoCall(false),
                        showCallsModal: query.showCallsModal === 'true',
                      }}
                    >
                      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                        <div className={classes.chatSection}>
                          <Box component="div">
                            <ChatHeader
                              channel={channel}
                              ownUser={ownUser}
                              ownUserType={ownUserType as UserType}
                              companyExperts={myCompanyExperts}
                              onAssignedToChange={handleAssignToChange}
                              onJoinVideoCall={() => handleJoinVideoCall(true)}
                            />
                          </Box>
                          <div className={classes.chatContainer}>
                            <StreamChat
                              channel={channel}
                              systemMessageActions={{
                                onJoinVideoCall: () => handleJoinVideoCall(false),
                                onScheduleCallClick: handleScheduleCall,
                                // onViewExpertProfileClick: handleViewExpertProfile,
                              }}
                              isWidgetScreen={false}
                              CustomUserMessageBox={CustomUserMessageBox as React.FC}
                              MessageInputWrapper={MessageInputWrapper}
                            />
                          </div>
                        </div>
                        <ChatSidebar companyExperts={myCompanyExperts} />
                      </div>
                    </ChatSidebarProvider>
                  </ShopifyCartProvider>
                </ShopifyCataloguePopupProvider>
              )}
            </>
          )}
        </>
      )}
      {/* {profilePopover} */}
      {channel && expert && selectedAssignTo && (
        <ReassignDialog
          channel={channel}
          fromUser={expert}
          fromUserIsExpert={isExpert}
          toUser={selectedAssignTo}
          open={reassignDialogState}
          onCancel={handleReassignDialogClose}
          onReassign={handleReassign}
        />
      )}
    </DashboardLayout>
  );
};

const InboxViewWithSnackbar = () => {
  return (
    <SnackbarProvider>
      <InboxView />
    </SnackbarProvider>
  );
};

export default InboxViewWithSnackbar;
