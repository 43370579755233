import React from 'react';

import { ProductUnavailableLabel } from '@amazd/common/components/shopify/productUnavailableLabel';
import { ShopProduct } from '@amazd/common/types/shop.types';
import { truncateText } from '@amazd/common/utils';
import { getCurrencySymbol } from '@amazd/common/utils/money';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';

import useStyles from './styles';

export interface ShopifyPopupProductCardProps {
  item: ShopProduct;
}

export const ShopifyPopupProductCard = ({ item }: ShopifyPopupProductCardProps) => {
  const classes = useStyles();

  const { changePage } = useShopifyCataloguePopupContext();

  return (
    <div className={classes.root} onClick={() => changePage('productVariantPicker', item)}>
      <div className={classes.productImageWrapper}>
        {item.featuredImage && <img className={classes.productImage} src={item.featuredImage?.url} />}
      </div>
      <div className={classes.right}>
        {((item.tracksInventory && !item.totalInventory) ||
          item.publishedOnCurrentPublication === false ||
          item.availableForSale === false) && (
          <ProductUnavailableLabel
            type={item.publishedOnCurrentPublication === false ? 'not-published' : 'out-of-stock'}
          />
        )}
        <div className={classes.name}>{truncateText(item.title, 40)}</div>
        <div className={classes.price}>
          {getCurrencySymbol(item.priceRangeV2?.minVariantPrice?.currencyCode as CurrencyCode)}
          {item.priceRangeV2?.minVariantPrice?.amount}
        </div>
      </div>
    </div>
  );
};
