import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  pill: {
    margin: '0px 4px',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    padding: '5px 12px',
    borderRadius: 16,
    cursor: 'pointer',
    backgroundColor: colors.grey30,
    color: colors.black,
  },
  pillSelected: {
    backgroundColor: colors.purple100,
    color: colors.white,
  },
}));
