import React from 'react';

import CloseXIcon from '@amazd/common/icons/CloseXIcon';
import SearchIcon from '@amazd/common/icons/SearchIcon';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { colors } from 'styles/theme';

import useStyles from './styles';

export interface ProductCatalogueHeaderProps {
  title?: string;
}

export const ProductCatalogueHeader = ({ title }: ProductCatalogueHeaderProps) => {
  const classes = useStyles();

  const { changePage, setOpen } = useShopifyCataloguePopupContext();

  return (
    <div className={classes.root}>
      <div className={classes.iconButton} onClick={() => changePage('search')}>
        <SearchIcon color={colors.white} style={{ fontSize: 24 }} />
      </div>
      <div className={classes.title}>{title}</div>
      <div className={classes.close}>
        <div className={classes.iconButton} onClick={() => setOpen(false)}>
          <CloseXIcon color={colors.white} style={{ fontSize: 32 }} />
        </div>
      </div>
    </div>
  );
};
