import { gql } from '@apollo/client';

import { APPOINTMENT_FIELDS } from '../../graphql/fragments/bookingFields';

export const bookSlotMutation = gql`
  ${APPOINTMENT_FIELDS}
  mutation ($data: BookingDto!) {
    booking(data: $data) {
      ...AppointmentFields
    }
  }
`;

export const cancelBookingMutation = gql`
  mutation ($data: CancelBookingDto!) {
    cancelAppointment(data: $data)
  }
`;
