import React from 'react';

import { colors } from '@amazd/common/static';
import { Tab, TabProps, Tabs } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';

export const AntTabs = withStyles({
  root: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  indicator: {
    backgroundColor: colors.amazdPurple,
  },
})(Tabs);

export const AntTab = withStyles(() =>
  createStyles({
    root: {
      minWidth: 56,
      height: 59,
      opacity: 1,
      '&:hover': {
        color: colors.amazdPurple,
      },
      '&$selected': {
        color: colors.amazdPurple,
      },
      '&:focus': {
        color: colors.amazdPurple,
      },
    },
    selected: {},
  }),
)((props: TabProps) => <Tab disableRipple {...props} />);
