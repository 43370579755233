import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WishBagEmptySvg: React.FC<any> = (props): React.ReactElement => (
  <SvgIcon viewBox="0 0 112 112" fill="none" {...props}>
    <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="25" y="29" width="62" height="60" rx="8" stroke="#BABDCE" strokeWidth="1.5" />
      <path d="M37 43H60" stroke="#BABDCE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37 51H42" stroke="#BABDCE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37 59H42" stroke="#BABDCE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M54.9281 50.198L54.5 50.622L54.0405 50.174C53.6562 49.8009 53.2004 49.5055 52.6992 49.3049C52.198 49.1042 51.6612 49.0022 51.1196 49.0047C50.5781 49.0072 50.0423 49.1141 49.543 49.3193C49.0438 49.5245 48.5908 49.824 48.2101 50.2007C47.4342 50.98 47 52.0247 47 53.1124C47 54.2001 47.4342 55.2448 48.2101 56.0241L52.3238 60.1091C52.608 60.3912 52.9468 60.6153 53.3204 60.7683C53.694 60.9212 54.095 61 54.5 61C54.905 61 55.306 60.9212 55.6796 60.7683C56.0532 60.6153 56.392 60.3912 56.6762 60.1091L60.7899 56.0241C61.5658 55.2448 62 54.2001 62 53.1124C62 52.0247 61.5658 50.98 60.7899 50.2007C60.4071 49.8206 59.9508 49.5188 59.4477 49.3126C58.9445 49.1065 58.4044 49.0002 57.8589 49C57.3134 48.9998 56.7732 49.1055 56.2698 49.3112C55.7665 49.5169 55.3099 49.8183 54.9268 50.198H54.9281Z"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43 29V27C43 19.8203 48.8203 14 56 14C63.1797 14 69 19.8203 69 27V29"
        stroke="#BABDCE"
        strokeWidth="1.5"
      />
      <path
        d="M86.8485 67.9608C85.8214 68.3928 84.86 68.9615 83.989 69.6494C83.0983 68.948 82.1135 68.3713 81.0613 67.9377C79.5586 67.3184 77.9486 67.0033 76.3237 67.0109C74.6989 67.0186 73.0919 67.3488 71.5951 67.9822C70.0983 68.6156 68.7417 69.5394 67.6023 70.6997L67.5865 70.716C65.2847 73.0959 64 76.2822 64 79.5956C64 82.909 65.2847 86.0953 67.5865 88.4753L67.5984 88.4875L76.9229 98.0191L76.923 98.0192C77.8454 98.962 78.9459 99.7117 80.1611 100.224C81.3764 100.736 82.6814 101 84 101C85.3186 101 86.6236 100.736 87.8389 100.224C89.0541 99.7117 90.1546 98.962 91.077 98.0192L91.0771 98.0191L100.402 88.4875L100.414 88.4753C102.715 86.0953 104 82.909 104 79.5956C104 76.2822 102.715 73.0959 100.414 70.716L100.401 70.7035C99.2556 69.5325 97.889 68.6014 96.3804 67.9652C94.8718 67.329 93.2518 67.0008 91.6149 67C89.978 66.9992 88.3578 67.326 86.8485 67.9608Z"
        fill="white"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.9705 72.7954L84 73.7846L82.9585 72.7394C82.0874 71.8687 81.0543 71.1795 79.9182 70.7113C78.7821 70.2432 77.5655 70.0051 76.3379 70.0109C75.1103 70.0167 73.8959 70.2662 72.7642 70.745C71.6325 71.2239 70.6059 71.9228 69.7429 72.8016C67.9841 74.6201 67 77.0577 67 79.5956C67 82.1335 67.9841 84.5711 69.7429 86.3896L79.0674 95.9212C79.7115 96.5796 80.4794 97.1024 81.3262 97.4593C82.173 97.8162 83.0819 98 84 98C84.9181 98 85.827 97.8162 86.6738 97.4593C87.5206 97.1024 88.2885 96.5796 88.9326 95.9212L98.2571 86.3896C100.016 84.5711 101 82.1335 101 79.5956C101 77.0577 100.016 74.6201 98.2571 72.8016C97.3894 71.9148 96.3552 71.2105 95.2147 70.7295C94.0742 70.2486 92.8501 70.0006 91.6135 70C90.3769 69.9994 89.1526 70.2462 88.0116 70.7261C86.8707 71.206 85.8359 71.9094 84.9674 72.7954H84.9705Z"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="17" cy="77" r="2" fill="#FFB13F" />
      <circle cx="96" cy="47" r="3" fill="#FF0066" />
      <path
        d="M37 18C37 20.2091 35.2091 22 33 22C30.7909 22 29 20.2091 29 18C29 15.7909 30.7909 14 33 14C35.2091 14 37 15.7909 37 18Z"
        fill="#7E05FE"
      />
    </svg>
  </SvgIcon>
);

export default WishBagEmptySvg;
