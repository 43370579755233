import React, { useState } from 'react';

import ChevronRightIcon from '@amazd/common/icons/ChevronRightIcon';
import { ShopCategory } from '@amazd/common/types/shop.types';
import { Skeleton } from '@mui/material';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { colors } from 'styles/theme';

import useStyles from './styles';

export interface ProductCatalogueCardProps {
  item?: ShopCategory;
  loading?: boolean;
}

export const ProductCatalogueCard = ({ item, loading }: ProductCatalogueCardProps) => {
  const classes = useStyles();

  const { changePage } = useShopifyCataloguePopupContext();

  const [cursorHover, setCursorHover] = useState(false);

  if (!item || item.productsCount === 0) return null;

  return (
    <div
      className={classes.root}
      onClick={() => changePage('catalogueDetail', item)}
      onMouseMove={() => setCursorHover(true)}
      onMouseLeave={() => setCursorHover(false)}
    >
      <div className={classes.name}>{loading ? <Skeleton width={88} /> : item?.title}</div>
      <div className={classes.right}>
        {item?.productsCount && (
          <div className={`${classes.count} ${cursorHover && classes.countHover}`}>
            {loading ? <Skeleton width={13} /> : item?.productsCount}
          </div>
        )}
        <div className={classes.icon}>
          <ChevronRightIcon color={colors.black} style={{ fontSize: 24 }} />
        </div>
      </div>
    </div>
  );
};
