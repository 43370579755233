import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 0,
  },
  button: {
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    textAlign: 'center',
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'default',
  },
  fullWidth: {
    width: '100%',
  },
  typePrimary: {
    backgroundColor: colors.purple100,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.purple120,
    },
  },
  typeSecondary: {
    border: 'solid',
    borderWidth: 1,
    borderColor: colors.grey40,
    backgroundColor: colors.white,
    color: colors.black,
    '&:hover': {
      backgroundColor: colors.grey10,
    },
  },
  sizeSmall: {
    padding: '10px 16px',
    fontSize: 12,
  },
  sizeRegular: {
    padding: '13px 24px',
    fontSize: 16,
  },
  fontSize12: {
    fontSize: 12,
  },
  fontSize16: {
    fontSize: 16,
  },
}));
