import React from 'react';

import Loader from '@amazd/common/components/Loader';
import { useShopifyCartContext } from 'components/Shopify/wishBag/hooks/useCart';

import { WishBagContent } from './wishBagContent';
import { WishBagEmpty } from './wishBagEmpty';

export function WishBag() {
  const { loading, lineItems } = useShopifyCartContext();

  if (loading) return <Loader size={120} />;

  return <>{lineItems?.length ? <WishBagContent /> : <WishBagEmpty />}</>;
}
