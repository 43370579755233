import React from 'react';

import { useSpring, animated } from '@react-spring/web';

import useStyles from './styles';

export interface TextAreaProps {
  label: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  onChangeValue?: (value: string) => void;
}

export function TextArea({ label, placeholder, value = '', disabled, onChangeValue }: TextAreaProps) {
  const classes = useStyles();

  const stylesLabel = useSpring({
    opacity: value ? 1 : 0,
    height: value ? 12 : 0,
  });

  return (
    <div className={disabled ? classes.rootDisabled : classes.root}>
      <animated.div style={stylesLabel}>
        <span className={classes.label}>{label}</span>
      </animated.div>
      <textarea
        disabled={disabled}
        className={disabled ? classes.textareaDisabled : classes.textarea}
        placeholder={placeholder}
        rows={3}
        onChange={(e) => {
          if (onChangeValue) onChangeValue(e.target.value);
        }}
        value={value}
      />
    </div>
  );
}
