import { colors } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  left: {
    marginRight: 8,
  },
  title: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: '20px',
  },
  titlePost: {
    color: colors.grey80,
  },
}));
