import React from 'react';

import { useOpenProductPage } from '@amazd/common/hooks/useOpenProductPage';
import DeleteTrashIcon from '@amazd/common/icons/DeleteTrashIcon';
import { useTranslation } from '@amazd/common/utils/i18n';
import { getCurrencySymbol } from '@amazd/common/utils/money';
import { LineItem as LineItemType, useShopifyCartContext } from 'components/Shopify/wishBag/hooks/useCart';
import { colors } from 'styles/theme';
import { useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';

import { LineItemAlert } from './alert';
import { QuantityInput } from './quantitySelect';
import useStyles from './styles';
export interface LineItemProps {
  lineItem: LineItemType;
}

export function LineItem({ lineItem }: LineItemProps) {
  const classes = useStyles();

  const { t } = useTranslation();

  const productImageUrl = lineItem.variant?.image || lineItem.product.featuredImage;
  const { removeLineItem, incrementQuantity, decrementQuantity, loadingSave } = useShopifyCartContext();

  const { channel } = useChatSidebarContext();
  const { openProductPage } = useOpenProductPage(channel?.id as string);

  const handleOpenProduct = () => {
    openProductPage(lineItem.product, lineItem.variant);
  };

  const onClickRemove = () => {
    removeLineItem(lineItem);
  };

  const onIncrementQuantity = () => {
    incrementQuantity(lineItem);
  };

  const onDecrementQuantity = () => {
    decrementQuantity(lineItem);
  };

  const inventory = Number(lineItem.variant?.inventoryQuantity ?? lineItem.product.totalInventory);
  const isAllItemInWishBag = lineItem.product?.tracksInventory && !isNaN(inventory) && inventory <= lineItem.quantity;
  const price = lineItem.variant
    ? lineItem.variant.price
    : lineItem.product.priceRangeV2?.maxVariantPrice?.amount ?? lineItem.product.priceRangeV2?.minVariantPrice?.amount;

  return (
    <div className={classes.root}>
      {isAllItemInWishBag && <LineItemAlert title={t('wish-bag-content-line-item-warning-not-in-stock')} />}
      <div className={classes.content}>
        <div className={classes.imgWrapper} onClick={handleOpenProduct}>
          <img className={classes.img} src={productImageUrl?.url} />
        </div>
        <div className={classes.right}>
          <div className={classes.name} onClick={handleOpenProduct}>
            {lineItem.product.title}
          </div>
          <div className={classes.price}>
            {getCurrencySymbol((lineItem.product as any).priceRangeV2.minVariantPrice?.currencyCode as CurrencyCode)}
            {price}
          </div>
          <div className={classes.options}>
            {!lineItem.product.hasOnlyDefaultVariant &&
              lineItem.variant?.selectedOptions?.map((selectedOption, index) => (
                <div key={index.toString()}>
                  {selectedOption.name}: {selectedOption.value}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <QuantityInput
          isAllItemInWishBag={Boolean(isAllItemInWishBag)}
          value={lineItem.quantity}
          onIncrement={onIncrementQuantity}
          onDecrement={onDecrementQuantity}
          disabled={loadingSave}
        />
        <div className={classes.trashIcon} onClick={onClickRemove}>
          <DeleteTrashIcon color={colors.grey80} style={{ fontSize: 24 }} />
        </div>
      </div>
    </div>
  );
}
