import React, { useEffect, useState, ChangeEvent } from 'react';

import { UserAvatar } from '@amazd/common/components';
import { StreamUserExtended, useChannelActors } from '@amazd/common/hooks/useStreamChannel';
import CloseIcon from '@amazd/common/icons/CloseIcon';
import SmallArrowRightIcon from '@amazd/common/icons/SmallArrowRightIcon';
import { colors } from '@amazd/common/static';
import { useTranslation } from '@amazd/common/utils/i18n';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Alert } from 'components/Alert';
import { useForm } from 'react-hook-form';
import type { Channel } from 'stream-chat';

import useStyles from './styles';

const MAX_DESCRIPTION_TEXT_LENGTH = 250;

type FormData = {
  description: string;
};

export interface ReassignDialogProps {
  channel: Channel;
  fromUser: StreamUserExtended;
  fromUserIsExpert: boolean;
  toUser: {
    firstName: string;
    lastName: string;
    avatar: string;
  };
  open: boolean;
  onReassign: (message: string) => void;
  onCancel: () => void;
}

const UserBox = (props: { header: string; firstName: string; lastName: string; image: string }): JSX.Element => {
  return (
    <div>
      <div style={{ fontSize: 12, textTransform: 'uppercase' }}>{props.header}:</div>
      <div
        style={{
          borderRadius: 10,
          backgroundColor: colors.amazdGrey6,
          padding: '10px 16px',
          display: 'flex',
          alignItems: 'center',
          marginTop: 6,
        }}
      >
        <UserAvatar size={40} firstName={props.firstName} lastName={props.lastName} src={props.image} />
        <span style={{ fontSize: 16, marginLeft: 10, fontWeight: 500 }}>{`${props.firstName} ${props.lastName}`}</span>
      </div>
    </div>
  );
};

const ReassignDialog = ({
  fromUser,
  fromUserIsExpert,
  toUser,
  open,
  onReassign,
  onCancel,
  channel,
}: ReassignDialogProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { owner } = useChannelActors(channel);

  const {
    register,
    formState: { errors, isDirty },
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm<FormData>({ mode: 'onChange' });

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (open) {
      setValue('description', '');
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setValue('description', value);
    const overflow = value.length > MAX_DESCRIPTION_TEXT_LENGTH;
    setHasError(overflow);
    if (overflow) {
      setError('description', { type: 'maxLength' });
    } else if (errors.description) {
      clearErrors('description');
    }
  };

  const handleReassign = () => {
    const description = getValues().description;
    onReassign(description);
  };

  return (
    <Dialog className={classes.root} open={open} onClose={onCancel}>
      <DialogTitle>{t('Reassign conversation')}</DialogTitle>
      <IconButton color="primary" onClick={onCancel} className={classes.cancelButton}>
        <CloseIcon style={{ fontSize: 21, color: '#000' }} />
      </IconButton>
      <DialogContent className={classes.content}>
        {fromUserIsExpert && (
          <div>
            <Alert type="info" message={t('reassign-dialog-information')} />
          </div>
        )}
        <div style={{ marginTop: 32 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
              <UserBox
                header={t('reassign-dialog-from-label')}
                firstName={fromUser.first_name}
                lastName={`${fromUser.last_name} (${t('reassign-dialog-own-chat-indicator')})`}
                image={fromUser.image}
              />
            </div>
            <div style={{ padding: 16, marginTop: 30 }}>
              <SmallArrowRightIcon style={{ height: 11 }} />
            </div>
            <div style={{ flex: 1 }}>
              <UserBox
                header={t('reassign-dialog-to-label')}
                firstName={toUser.firstName}
                lastName={toUser.lastName}
                image={toUser.avatar}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          <Typography>{t('reassign-dialog-content-text', { ownerFirstName: owner?.first_name })}</Typography>
          {fromUserIsExpert && <Typography>{t('reassign-dialog-content-text-2')}</Typography>}
          <Typography sx={{ mt: 2 }}>
            {t('reassign-dialog-content-text-3', { ownerFirstName: owner?.first_name })}
          </Typography>
        </div>
        <div>
          <TextField
            className={classes.descriptionInput}
            variant="outlined"
            multiline={true}
            rows={2}
            placeholder={t('reassign-dialog-textbox-placeholder')}
            error={hasError && isDirty}
            helperText={
              errors.description?.type === 'maxLength'
                ? t('reassign-dialog-textbox-validation-message', { maxLength: MAX_DESCRIPTION_TEXT_LENGTH })
                : ' '
            }
            FormHelperTextProps={{
              style: { minHeight: '1rem' },
            }}
            {...register('description', { required: true, maxLength: MAX_DESCRIPTION_TEXT_LENGTH })}
            onChange={(e) => handleDescriptionChange(e)}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ marginTop: 40 }}>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          {t('reassign-dialog-cancel-button')}
        </Button>
        <Button color="primary" variant="contained" onClick={handleReassign} disabled={hasError}>
          {t('reassign-dialog-reassign-button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReassignDialog;
