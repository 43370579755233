import { useEffect, useState } from 'react';

import { getClient } from '@amazd/common/apollo';
import { gql } from '@apollo/client';

const query = gql`
  query lastVisitedPages($amazdId: String!) {
    amazd(id: $amazdId) {
      visitedPages {
        pages {
          title
          url
          visitedAt
          favicon
          img
        }
      }
    }
  }
`;

export type VisitedPage = {
  title: string;
  url: string;
  visitedAt: string;
  favicon?: string;
  img?: string;
};

export function useCustomerLastVisitedPages(amazdId: string) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<VisitedPage[]>([]);

  const refetch = async (useLoadingState = true) => {
    try {
      if (useLoadingState) {
        setLoading(true);
        setData([]);
      }
      const result = await getClient().query({
        query: query,
        variables: { amazdId },
        fetchPolicy: 'no-cache',
      });
      const visitedPages = result.data.amazd.visitedPages;
      setData(visitedPages ? visitedPages.pages ?? [] : []);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refetch().catch(console.error);
    const time = setInterval(() => refetch(false).catch(console.error), 10000);
    return () => clearInterval(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amazdId]);

  return { loading, data };
}
