import React from 'react';

import NoResultFoundSvg from '@amazd/common/assets/illustrations/noResultFoundSvg';
import { useTranslation } from '@amazd/common/utils/i18n';
import { ShopifyButton } from 'components/Shopify/button';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';

import useStyles from './styles';

export const SearchProductNoResultFound = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { changePage } = useShopifyCataloguePopupContext();

  const onGoToCatalogue = () => {
    changePage('catalogue');
  };

  return (
    <div className={classes.root}>
      <div className={classes.noResultFoundWrapper}>
        <NoResultFoundSvg style={{ fontSize: 112 }} />
        <div className={classes.noResultFoundTitle}>{t('product-catalogue-search-no-result-found')}</div>
        <div className={classes.noResultFoundDescription}>
          {t('product-catalogue-search-no-result-found-description')}
        </div>
        <div className={classes.buttonGoToCatalogue}>
          <ShopifyButton type={'primary'} size={'small'} onClick={onGoToCatalogue}>
            {t('product-catalogue-search-go-to-catalogue')}
          </ShopifyButton>
        </div>
      </div>
    </div>
  );
};
