import React, { useState } from 'react';

import { LinkButton, Loader } from '@amazd/common/components';
import useCancelAppointment from '@amazd/common/hooks/useCancelAppointment';
import CallRingIcon from '@amazd/common/icons/CallRingIcon';
import CancelIcon from '@amazd/common/icons/CancelIcon';
import ClockIcon from '@amazd/common/icons/ClockIcon';
import InfoIcon from '@amazd/common/icons/InfoIcon';
import { Appointment } from '@amazd/common/redux/types';
import { colors } from '@amazd/common/static';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Box, Button, Grid, Typography } from '@mui/material';
import BulletListItem from 'components/BulletListItem';
import ButtonLoad from 'components/ButtonLoad';
import { format } from 'date-fns';

import useStyles from './styles';

interface ScheduleContentProps {
  appointment: Appointment;
  onJoinCall?: () => void;
  reloadAmazd?: () => void;
}

const DefaultContent = ({ appointment, onJoinCall, onCancel }: ScheduleContentProps & { onCancel: () => void }) => {
  const { t, formatDate } = useTranslation();

  const { classes } = useStyles();

  const dateStarting = new Date(appointment.startingAt);
  const dateEnding = dateStarting.getTime() + appointment.duration * 60000;

  const timeDifference = (dateStarting.getTime() - new Date().getTime()) / 1000;
  const callInTime = timeDifference < 60 * 10;

  const formattedDate = formatDate(dateStarting, "EEEE',' d LLLL',' y");
  const formattedTime = `${formatDate(dateStarting, "H':'mm")} - ${formatDate(dateEnding, "H':'mm")}`;
  return (
    <>
      <BulletListItem
        className={classes.bulletList}
        left={<ClockIcon color={colors.grey80} style={{ fontSize: 18 }} />}
        title={formattedDate}
        title2={formattedTime}
        titlePost={t('schedule-tab-content-info-duration', { duration: appointment.duration })}
      />
      <BulletListItem
        className={classes.bulletList}
        left={<InfoIcon color={colors.grey80} style={{ fontSize: 18 }} />}
        title={!callInTime ? t('schedule-tab-content-info-ten-minute-before') : t('schedule-tab-content-info-on-time')}
      />
      <Button
        className={classes.joinButton}
        variant={'contained'}
        disabled={!callInTime}
        fullWidth
        onClick={onJoinCall}
      >
        <CallRingIcon color={colors.grey60} style={{ fontSize: 24, marginRight: 8 }} />
        {t('schedule-tab-content-join-call')}
      </Button>
      {!callInTime && (
        <div className={classes.joınButtonSubInfoWrapper}>
          <Typography className={classes.joınButtonSubInfo}>{t('schedule-tab-content-join-call-sub-info')}</Typography>
        </div>
      )}
      <div className={classes.cancelButton} onClick={onCancel}>
        {t('call-schedule-cancel')}
      </div>
    </>
  );
};

interface CancelContentProps {
  appointment: Appointment;
  onDecline: () => void;
  reloadAmazd?: () => void;
}

const CancelContent = ({ appointment, onDecline, reloadAmazd }: CancelContentProps) => {
  const { t } = useTranslation();

  const { classes } = useStyles();

  const { loading, cancelAppointment } = useCancelAppointment();

  if (!appointment) return null;

  const dateFormat = 'MMMM d, y';
  const timeFormat = 'HH:mm';

  const date = new Date(appointment.startingAt);

  const cancelText = t('call-schedule-cancel-text', {
    date: format(date, dateFormat),
    time: format(date, timeFormat),
  })
    .replace(format(date, dateFormat), `<b>${format(date, dateFormat)}</b>`)
    .replace(format(date, timeFormat), `<b>${format(date, timeFormat)}</b>`);

  const handleOnCancel = async () => {
    await cancelAppointment({
      variables: {
        data: { appointmentId: appointment.id },
      },
    });
    if (reloadAmazd) await reloadAmazd();
  };

  return (
    <Grid container direction="column" justifyContent="space-between" className={classes.cancelContainer}>
      <Grid container mb={2} justifyContent="center">
        <CancelIcon />
        <div
          dangerouslySetInnerHTML={{ __html: cancelText }}
          className={`${classes.bullet} ${classes.cancelText}`}
        ></div>
      </Grid>
      <Box display="flex" flexDirection="column" alignItems="center">
        <ButtonLoad loading={loading} onClick={handleOnCancel} variant="contained" color="primary">
          {t('call-schedule-cancel-button-confirm')}
          {loading && <Loader style={{ position: 'absolute' }} size={70} />}
        </ButtonLoad>
        <LinkButton disabled={loading} onClick={onDecline} className={classes.linkButton} size="large">
          {t('call-schedule-cancel-button-cancel')}
        </LinkButton>
      </Box>
    </Grid>
  );
};

const ScheduleContent = ({ appointment, onJoinCall, reloadAmazd }: ScheduleContentProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [wantsToCancel, setWantsToCancel] = useState(false);

  const handleOnCancel = () => {
    setWantsToCancel(true);
  };

  const handleOnDecline = () => {
    setWantsToCancel(false);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{t('schedule-tab-content-title')}</Typography>
      <div className={classes.body}>
        {!wantsToCancel ? (
          <DefaultContent appointment={appointment} onJoinCall={onJoinCall} onCancel={handleOnCancel} />
        ) : (
          <CancelContent appointment={appointment} reloadAmazd={reloadAmazd} onDecline={handleOnDecline} />
        )}
      </div>
    </div>
  );
};

export default ScheduleContent;
