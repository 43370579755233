import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CloseXIcon: React.FC<any> = ({ color, ...rest }: any): React.ReactElement => (
  <SvgIcon viewBox="0 0 32 32" fill="none" {...rest}>
    <path
      d="M11.1968 10.1367C10.9039 9.84377 10.4291 9.84377 10.1362 10.1367C9.84328 10.4296 9.84328 10.9044 10.1362 11.1973L11.1968 10.1367ZM20.8028 21.864C21.0957 22.1569 21.5706 22.1569 21.8635 21.864C22.1564 21.5711 22.1564 21.0962 21.8635 20.8033L20.8028 21.864ZM21.8635 11.1973C22.1564 10.9044 22.1564 10.4296 21.8635 10.1367C21.5706 9.84377 21.0957 9.84377 20.8028 10.1367L21.8635 11.1973ZM10.1362 20.8033C9.84328 21.0962 9.84328 21.5711 10.1362 21.864C10.4291 22.1569 10.9039 22.1569 11.1968 21.864L10.1362 20.8033ZM10.1362 11.1973L20.8028 21.864L21.8635 20.8033L11.1968 10.1367L10.1362 11.1973ZM20.8028 10.1367L10.1362 20.8033L11.1968 21.864L21.8635 11.1973L20.8028 10.1367Z"
      fill={color}
    />
  </SvgIcon>
);

export default CloseXIcon;
