import { colors } from '@amazd/common/static';
import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

export const CUSTOMER_SIDEBAR_WIDTH = 375;

export default makeStyles((theme: CustomTheme) => ({
  rootShowBiggerThan1280: {},
  rootNotShowBiggerThan1280: { display: 'none' },
  sidepanelWrapperBiggerThan1280: {
    minWidth: CUSTOMER_SIDEBAR_WIDTH,
    maxWidth: CUSTOMER_SIDEBAR_WIDTH,
    height: '100%',
    backgroundColor: colors.white,
  },
  sidepanelWrapperHiddenBiggerThan1280: {
    display: 'none',
  },
  rootShowSmallarThan1280: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  rootNotShowSmallarThan1280: {},
  sidepanelWrapperSmallarThan1280: {
    minWidth: CUSTOMER_SIDEBAR_WIDTH,
    maxWidth: CUSTOMER_SIDEBAR_WIDTH,
    height: '100%',
    backgroundColor: colors.white,
  },
  sidepanelWrapperHiddenSmallarThan1280: {
    minWidth: CUSTOMER_SIDEBAR_WIDTH,
    maxWidth: CUSTOMER_SIDEBAR_WIDTH,
    height: '100%',
    backgroundColor: colors.white,
  },
  sidepanelRoot: {
    width: '100%',
    height: '100%',
    borderLeftWidth: 1,
    borderLeft: 'solid',
    borderColor: colors.grey40,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  overlayShow: {
    position: 'absolute',
    top: 54,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.6)',
    zIndex: theme.zIndex.drawer - 1,
  },
  overlayNotShow: {},
  header: {
    paddingRight: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: colors.grey40,
  },
  tabWrapper: {
    alignItems: 'flex-start',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: `calc(100% - 60px)`,
  },
}));
