import React from 'react';

import { Skeleton } from '@mui/material';

import useStyles from './styles';

export interface InfoCardProps {
  title: string;
  children: React.ReactNode;
  loading?: boolean;
}

export function InfoCard({ title, children, loading }: InfoCardProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <span className={classes.title}>{loading ? <Skeleton width={50} /> : title}</span>
      </div>
      <div className={classes.cardWrapper}>{children}</div>
    </div>
  );
}
