import React from 'react';

import TrailingMinusIcon from '@amazd/common/icons/TrailingMinusIcon';
import TrailingPlusIcon from '@amazd/common/icons/TrailingPlusIcon';
import { colors } from '@amazd/common/static/colors';

import useStyles from './styles';

export interface QuantityInputProps {
  value: number;
  onDecrement: () => void;
  onIncrement: () => void;
  isAllItemInWishBag?: boolean;
  disabled?: boolean;
}

export function QuantityInput({ value, onDecrement, onIncrement, isAllItemInWishBag, disabled }: QuantityInputProps) {
  const classes = useStyles();

  const disableMinus = value <= 1;
  const disablePlus = isAllItemInWishBag;

  const onClickDecrement = () => {
    if (!disableMinus && !disabled) onDecrement();
  };

  const onClickIncrement = () => {
    if (!disablePlus && !disabled) onIncrement();
  };

  return (
    <div className={`${classes.root} ${disabled ? classes.disabled : ''}`}>
      <TrailingMinusIcon
        onClick={onClickDecrement}
        color={disableMinus ? colors.grey50 : colors.grey70}
        className={`${classes.icon} ${!disableMinus && !disabled && classes.iconActive}`}
      />
      <div className={classes.text}>{value}</div>
      <TrailingPlusIcon
        onClick={onClickIncrement}
        color={disablePlus ? colors.grey50 : colors.grey70}
        className={`${classes.icon} ${!disablePlus && !disabled && classes.iconActive}`}
      />
    </div>
  );
}
