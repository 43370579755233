import { truncateText } from '@amazd/common/utils';
import { Box, BoxProps, Tooltip, TooltipProps } from '@mui/material';

import useStyles from './styles';

interface Props extends Omit<BoxProps, 'right'> {
  label: string;
  maxLabelLength?: number;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  right?: React.ReactChild;
}

const Tag = ({ label, tooltipProps, right, maxLabelLength = 40, ...boxProps }: Props) => {
  const styles = useStyles();

  const renderLabel = () => <div className={styles.label}>{truncateText(label, maxLabelLength)}</div>;

  return (
    <Box className={`${styles.tag} ${right && styles.tagHasRight}`} {...boxProps}>
      {label.length > maxLabelLength && tooltipProps ? (
        <Tooltip {...tooltipProps}>{renderLabel()}</Tooltip>
      ) : (
        renderLabel()
      )}
      {right}
    </Box>
  );
};

export default Tag;
