import { useMutation } from '@apollo/client';

import { cancelBookingMutation } from '../redux/graphql/mutations/bookingMutations';

const useCancelAppointment = () => {
  const [cancelAppointment, { loading, error }] = useMutation(cancelBookingMutation);

  return { cancelAppointment, loading, error };
};

export default useCancelAppointment;
