import React, { useMemo } from 'react';

import InboxSearchEmptySvg from '@amazd/common/assets/illustrations/inboxSearchEmptySvg';
import UnreadListEmptySvg from '@amazd/common/assets/illustrations/unreadListEmptySvg';
import { Loader } from '@amazd/common/components';
import { SearchIcon } from '@amazd/common/icons';
import { useTranslation } from '@amazd/common/utils/i18n';
import { hasAccessTo, Permission } from '@amazd/common/utils/permissions';
import { List } from '@mui/material';
import ExpertPicker from 'components/ExpertPicker';
import InboxListItem from 'components/InboxListItem';
import { colors } from 'styles/theme';

import { useStyles } from './styles';
import { InboxSidebarProps } from './types';

export default function InboxLeftSidebar({
  channels,
  ownUser,
  ownUserType,
  companyExperts,
  onChannelSelect,
  selectedAmazdId,
  loading,
  archiveToggle,
  onScroll,
  onFilterChange,
  onArchive,
  onUnarchive,
  selectedFilterOption,
  isArchievedList,
  unReadSwitch,
  showUnReadOnly,
  searchBar,
  isSearchMode,
  onFocusSearch,
  recentSearch,
  showOnlyRecentSearch,
  listRef,
}: InboxSidebarProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const expertSelectionDropdown = useMemo(() => {
    return (
      <ExpertPicker
        onFilterChange={onFilterChange}
        selectedFilterOption={
          !selectedFilterOption ? 'all-chats' : selectedFilterOption === ownUser?.id ? 'my-chats' : selectedFilterOption
        }
        customValues={[
          {
            value: 'all-chats',
            label: t('inbox-filter-all-chats'),
          },
          {
            value: 'my-chats',
            label: t('inbox-filter-my-chats'),
          },
        ]}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyExperts, ownUser, ownUserType, selectedFilterOption]);

  const hasManagerLevelAccess = hasAccessTo(ownUser?.role, Permission.COMPANY_AMAZDS);
  const loaderSpacing = hasManagerLevelAccess ? 160 : 80;

  const primaryFilter = hasManagerLevelAccess ? (
    expertSelectionDropdown
  ) : (
    <>
      {archiveToggle}
      {unReadSwitch}
    </>
  );
  const secondaryFilter = hasManagerLevelAccess ? archiveToggle : expertSelectionDropdown;

  return (
    <div className={classes.root} data-testid="inbox-left-sidebar">
      {!hasManagerLevelAccess && <div className={classes.searchBar}>{searchBar}</div>}
      {hasManagerLevelAccess &&
        (isSearchMode ? (
          <div className={classes.searchBar}>{searchBar}</div>
        ) : (
          <div className={classes.toolbar}>
            {primaryFilter}
            {[ownUser.id, 'my-chats'].includes(selectedFilterOption as string) && (
              <div className={classes.searchIcon} onClick={onFocusSearch}>
                <SearchIcon color={colors.black} style={{ fontSize: 24 }} />
              </div>
            )}
          </div>
        ))}
      {!showOnlyRecentSearch && !hasManagerLevelAccess && <div className={classes.toolbar}>{primaryFilter}</div>}
      {!showOnlyRecentSearch && hasManagerLevelAccess && (
        <>
          {selectedFilterOption === 'my-chats' ? (
            <>
              <div className={classes.secondaryFilter}>
                {secondaryFilter}
                {unReadSwitch}
              </div>
            </>
          ) : (
            <>
              <div className={classes.hint}>{t('inbox-manager-done-hint')}</div>
            </>
          )}
        </>
      )}
      <List
        onScroll={onScroll}
        className={classes.inboxItemsList}
        ref={listRef}
        data-testid="inbox-left-sidebar-channels-list"
      >
        {showOnlyRecentSearch && recentSearch}
        {!showOnlyRecentSearch &&
          channels?.map((channel) => {
            return (
              <InboxListItem
                channel={channel}
                onChannelSelect={onChannelSelect}
                ownUser={ownUser}
                ownUserType={ownUserType}
                onArchive={onArchive}
                onUnarchive={onUnarchive}
                key={channel.id}
                active={selectedAmazdId === channel.id}
                isArchievedList={isArchievedList}
                selectedFilterOption={selectedFilterOption}
              />
            );
          })}
        {!channels?.length && showUnReadOnly && !loading && (
          <div className={classes.emptyWrapper}>
            <UnreadListEmptySvg style={{ fontSize: 112 }} />
            <div className={classes.emptyTitle}>{t('inbox-no-unread-title')}</div>
            {(selectedFilterOption === 'my-chats' || selectedFilterOption === ownUser?.id) && (
              <div className={classes.emptyDescription}>{t('inbox-no-unread-description')}</div>
            )}
          </div>
        )}
        {!channels?.length && !loading && isSearchMode && (
          <div className={classes.emptyWrapper}>
            <InboxSearchEmptySvg style={{ fontSize: 112 }} />
            <div className={classes.emptyTitle}>{t('inbox-seach-empty-title')}</div>
            <div className={classes.emptyDescription}>{t('inbox-seach-empty-description')}</div>
          </div>
        )}
        <div style={{ minHeight: loaderSpacing }}>{loading && <Loader size={80} />}</div>
      </List>
    </div>
  );
}
