import React, { useEffect, useRef, useState } from 'react';

import { SearchIcon } from '@amazd/common/icons';
import ChevronLeftIcon from '@amazd/common/icons/ChevronLeftIcon';
import CloseCircleIcon from '@amazd/common/icons/CloseCircleIcon';
import { animated, useSpring } from '@react-spring/web';
import { inboxSearchHistory } from 'components/InboxRecentSearch/searchHistory';
import { colors } from 'styles/theme';

import { useStyles } from './styles';

export interface InboxSearchBarProps {
  value: string;
  defaultSearchMode: boolean;
  placeholder: string;
  onChangeText: (value: string) => void;
  onChangeSearchMode: (isSearchMode: boolean) => void;
  isExpertView?: boolean;
}

export default function InboxSearchBar({
  value,
  defaultSearchMode,
  placeholder,
  onChangeText,
  onChangeSearchMode,
  isExpertView,
}: InboxSearchBarProps): JSX.Element {
  const classes = useStyles();

  const ref = useRef<HTMLInputElement>(null);
  const [isSearchMode, setIsSearchMode] = useState(defaultSearchMode);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isAnimationStart, setIsAnimationStart] = useState(false);

  const animation = useSpring({
    width: (isSearchMode && isFirstRender) || isExpertView ? '344px' : '50px',
    paddingRight: 10,
    onResolve: () => {
      onChangeSearchMode(isSearchMode);
      setIsAnimationStart(false);
    },
    onStart: () => {
      setIsAnimationStart(true);
    },
  });

  useEffect(() => {
    setIsFirstRender(true);
  }, []);

  useEffect(() => {
    if (!isSearchMode) {
      inboxSearchHistory.addItemToHistory(value);
      onChangeText('');
      onChangeSearchMode(isSearchMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchMode]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeText(e.target.value);
  };

  const onClickClose = () => {
    onChangeText('');
    ref.current?.focus();
  };

  if (isSearchMode || (!isSearchMode && isAnimationStart))
    return (
      <div className={classes.rootSearchMode}>
        <div onClick={() => setIsSearchMode(false)} className={classes.icon}>
          <ChevronLeftIcon color={colors.black} style={{ fontSize: 32, cursor: 'pointer' }} />
        </div>
        <animated.div style={animation}>
          <div className={classes.inputWrapper}>
            <input
              ref={ref}
              className={classes.input}
              autoFocus
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              onBlur={() => {
                inboxSearchHistory.addItemToHistory(value);
              }}
            />
            {value && (
              <div onClick={onClickClose} className={classes.icon}>
                <CloseCircleIcon color={colors.grey70} style={{ fontSize: 20 }} />
              </div>
            )}
          </div>
        </animated.div>
      </div>
    );

  return (
    <div className={classes.rootNotSearchMode} onClick={() => setIsSearchMode(true)}>
      <SearchIcon color={colors.black} style={{ fontSize: 24, marginRight: 8 }} />
      <div className={classes.labelNotSearchMode}>{placeholder}</div>
    </div>
  );
}
