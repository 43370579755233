import React from 'react';

import { ShopProduct } from '@amazd/common/types/shop.types';
import { ShopifyPopupProductCard } from 'components/Shopify/cataloguePopup/productCard';

import useStyles from './styles';

export interface ShopifyProductListProps {
  items: Array<ShopProduct>;
}

export const ShopifyProductList = ({ items }: ShopifyProductListProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {items.map((item, index) => (
        <ShopifyPopupProductCard key={index.toString()} item={item} />
      ))}
    </div>
  );
};
