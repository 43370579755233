import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0px 48px 24px',
    width: '100%',
    height: '100%',
    alignItems: 'start',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      color: colors.grey40,
    },
  },
  children: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'start',
  },
  animatedContainer: {
    display: 'flex',
    minHeight: '100px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'start',
  },
}));
