import React from 'react';

import { distanceToNow } from '@amazd/common/utils';
import { useTranslation } from '@amazd/common/utils/i18n';

import { useStyles } from './styles';

export interface TimeTagProps {
  date: Date;
  isColorful?: boolean;
  className?: string;
}

export function TimeTag({ date, isColorful, className }: TimeTagProps): JSX.Element {
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  const getColorClassName = () => {
    const hourDifference = (new Date().getTime() - date.getTime()) / 3600000;

    if (hourDifference < 1) return classes.green;
    else if (hourDifference < 24) return classes.orange;
    else return classes.red;
  };

  return (
    <div className={`${classes.root} ${className}`}>
      <div
        className={`${classes.default} ${
          isColorful ? `${classes.colorful} ${getColorClassName()}` : classes.notColorful
        }`}
      >
        {distanceToNow(i18n.language, date)}
      </div>
    </div>
  );
}
