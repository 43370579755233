import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SurveyTabIcon: React.FC<any> = (props: any): React.ReactElement => (
  <SvgIcon style={{ fill: 'white', ...props.style }} viewBox="0 0 20 20">
    <path
      d="M6.5 7V8M13.5 7V8M13.5 12.688C13.5 12.688 12.187 14 10 14C7.812 14 6.5 12.688 6.5 12.688M1 10C1 5.029 5.029 1 10 1C14.971 1 19 5.029 19 10C19 14.971 14.971 19 10 19C5.029 19 1 14.971 1 10Z"
      stroke={props.color || 'inherit'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default SurveyTabIcon;
