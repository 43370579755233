import { createContext } from 'react';

import { ShopProduct } from '@amazd/common/types/shop.types';
import { ProductOptionsHookValue } from 'components/Shopify/cataloguePopup/productVariantPicker/hooks/useProductOptions/types';

export const ProductContext = createContext<ProductContextType | null>(null);

export type ProductContextType = ShopProduct;

export const ProductOptionsContext = createContext<ProductOptionsHookValue | null>(null);
