import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MoreIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon
      className={props?.className ? props.className : ''}
      style={props?.style || {}}
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8992 41.1736L22.5655 44.0598M41.1027 29.5091L42.769 32.3953M50.5809 45.9259C50.5809 45.9259 48.9776 51.9005 42.6654 55.5449C36.3504 59.1908 30.3774 57.5904 30.3774 57.5904M10.0241 58.9972C1.74064 44.6498 6.65544 26.3075 21.0028 18.0241C35.3502 9.74064 53.6925 14.6554 61.9759 29.0028C70.2594 43.3502 65.3446 61.6925 50.9972 69.9759C36.6498 78.2594 18.3075 73.3446 10.0241 58.9972Z"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76 34C57.7998 34 43 48.7998 43 67C43 85.2002 57.7998 100 76 100C94.2004 100 109 85.1966 109 67C109 48.8031 94.1969 34 76 34Z"
        fill="white"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.323 54.6111L69.4553 55.7511M91.3815 63.7316L88.2492 62.5915M83.1189 76.6869C81.2298 81.8771 75.492 84.5527 70.3018 82.6636C65.1115 80.7745 62.4359 75.0367 64.325 69.8465L83.1189 76.6869ZM86.2627 38.8113C70.717 33.1532 53.4695 41.1958 47.8113 56.7415C42.1532 72.2871 50.1958 89.5347 65.7415 95.1929C81.2871 100.851 98.5359 92.8052 104.193 77.2627C109.85 61.7202 101.805 44.4683 86.2627 38.8113Z"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="5" cy="23" r="2" fill="#FFB13F" />
      <circle cx="37" cy="87" r="3" fill="#FF0066" />
      <path
        d="M78 25C78 27.2091 76.2091 29 74 29C71.7909 29 70 27.2091 70 25C70 22.7909 71.7909 21 74 21C76.2091 21 78 22.7909 78 25Z"
        fill="#7E05FE"
      />
    </SvgIcon>
  );
};

export default MoreIcon;
