interface IPageHistory<T> {
  push(item: T): void;
  pop(): T | undefined;
  peek(): T | undefined;
  size(): number;
}

export class PageHistory<T> implements IPageHistory<T> {
  private storage: T[] = [];

  constructor(private defaultPage: T, private memory: number = Infinity) {}

  push(item: T): void {
    if (this.size() === this.memory) {
      this.storage.shift();
    }
    this.storage.push(item);
  }

  pop(): T {
    return this.storage.pop() || this.defaultPage;
  }

  clear(): void {
    this.storage.splice(0, this.size());
  }

  peek(): T {
    return this.storage.length ? this.storage[this.storage.length - 1] : this.defaultPage;
  }

  size(): number {
    return this.storage.length;
  }
}
