import React, { useEffect, useState } from 'react';

import Loader from '@amazd/common/components/Loader';
import ChevronLeftIcon from '@amazd/common/icons/ChevronLeftIcon';
import { useTranslation } from '@amazd/common/utils/i18n';
import { useTrail, animated } from '@react-spring/web';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { ShopifyProductList } from 'components/Shopify/cataloguePopup/productList';
import { colors } from 'styles/theme';

import { useSearchProductsQuery } from './hooks/useSearchProductsQuery';
import { SearchProductNoResultFound } from './noResultFound';
import { SearchProductRecentSearches } from './recentSearches';
import { shopifyCatalogueSearchHistory } from './searchHistory';
import { SearchProductInput } from './searchProductInput';
import useStyles from './styles';

const SCROLL_THRESHOLD_PX = 50;

export const SearchProduct = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { page, goBack } = useShopifyCataloguePopupContext();

  const open = page === 'search';
  const [trail, api] = useTrail(1, () => ({
    opacity: 0,
    from: { opacity: 0 },
  }));

  useEffect(() => {
    if (open)
      setTimeout(() => {
        api.start({ opacity: 1 });
      }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [query, setQuery] = useState('');
  const { items, loading, loadNextPage, applySearch, hasNextPage } = useSearchProductsQuery();

  useEffect(() => {
    applySearch(query);
  }, [query, applySearch]);

  const handleScroll = (e: any) => {
    const reached = e.target.scrollHeight - e.target.scrollTop - SCROLL_THRESHOLD_PX <= e.target.clientHeight;
    if (reached) loadNextPage();
  };

  return (
    <div className={classes.root} onScroll={handleScroll}>
      {trail.map((style, index) => (
        <animated.div key={index.toString()} style={style}>
          <div className={classes.searchbarWrapper}>
            <div className={classes.backIconWrapper} onClick={goBack}>
              <ChevronLeftIcon color={colors.black} style={{ fontSize: 32 }} />
            </div>
            <SearchProductInput
              value={query}
              setValue={setQuery}
              onBlur={() => {
                shopifyCatalogueSearchHistory.addItemToHistory(query);
              }}
            />
          </div>
          {query ? (
            items && (items.length || loading) ? (
              <div className={classes.listRoot}>
                <div className={classes.listHeader}>
                  {t('product-catalogue-search-result-title', {
                    itemsCount: hasNextPage ? '100+' : items && items.length > 100 ? '100+' : items.length.toString(),
                  })}
                </div>
                <ShopifyProductList items={items} />
                {loading && <Loader size={100} />}
              </div>
            ) : (
              <SearchProductNoResultFound />
            )
          ) : (
            <SearchProductRecentSearches
              onSearchItem={(item) => {
                shopifyCatalogueSearchHistory.addItemToHistory(item);
                setQuery(item);
              }}
            />
          )}
        </animated.div>
      ))}
    </div>
  );
};
