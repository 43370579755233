import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 16px',
    backgroundColor: colors.blue10,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rootInfo: {
    backgroundColor: colors.blue10,
  },
  icon: {
    height: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    marginLeft: 4,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    height: 16,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  messageInfo: {
    color: colors.blue120,
  },
}));
