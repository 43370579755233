import React from 'react';

import { UserAvatar } from '@amazd/common/components';
import { useChannelActors, useChannelPeerUser } from '@amazd/common/hooks/useStreamChannel';
import ArrowDownIcon from '@amazd/common/icons/ArrowDownIcon';
import CallIcon from '@amazd/common/icons/CallIcon';
import CallRingIcon from '@amazd/common/icons/CallRingIcon';
import ToggleSidebarIcon from '@amazd/common/icons/ToggleSidebarIcon';
import { createCallRoom } from '@amazd/common/redux/actions/callRoomActions';
import { AmazdRole } from '@amazd/common/redux/types';
import { colors } from '@amazd/common/static';
import { trackCallSend } from '@amazd/common/utils/analytics';
import { useTranslation } from '@amazd/common/utils/i18n';
import { hasAccessTo, Permission } from '@amazd/common/utils/permissions';
import { Divider, IconButton, ListSubheader, MenuItem, Tooltip, Typography } from '@mui/material';
import SelectV2 from 'components/SelectV2';
import { useCompany } from 'hooks/useCompanies';
import { useScreenSize } from 'hooks/useScreenSize';
import { useDispatch } from 'react-redux';
import { screenSize1920 } from 'styles/theme';
import { useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';

import { useStyles } from './styles';
import { ChatHeaderProps } from './types';

export default function ChatHeader({
  channel,
  ownUser,
  ownUserType,
  companyExperts,
  onAssignedToChange,
  onJoinVideoCall,
}: ChatHeaderProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const { showSideBar, setShowSideBar } = useChatSidebarContext();

  const { expert, owner } = useChannelActors(channel);
  const { peerUser, peerUserCompanyId, ownUserRole } = useChannelPeerUser(channel);
  const peerUserCompany = useCompany(peerUserCompanyId);

  const showCompanyHandle = !!peerUserCompanyId && peerUserCompanyId !== ownUserType?.company?.id;
  const isExpert = ownUser?.userTypes?.[0]?.isExpert === true;
  const canReassign =
    (isExpert || hasAccessTo(ownUser?.role, Permission.REASSIGN)) &&
    channel &&
    companyExperts &&
    ownUserRole !== AmazdRole.OWNER;
  const showCustomerSidebar = expert?.id === ownUser?.id || companyExperts;
  const isExpertOwnUser = expert?.id === ownUser?.id;

  const size = useScreenSize();

  const dispatch = useDispatch();
  const onCall = () => {
    if (channel?.id) {
      dispatch(createCallRoom(channel.id));
      onJoinVideoCall();
      if (expert?.id && owner?.id) {
        trackCallSend({ channel_id: channel.id, expert_id: expert?.id, shopper_id: owner?.id });
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {peerUser && (
          <>
            <div key={peerUser.id} style={{ display: 'flex', paddingRight: 20, flex: 1 }}>
              <UserAvatar
                firstName={peerUser.first_name as string}
                lastName={peerUser.last_name as string}
                size={40}
                online={peerUser.online}
                src={peerUser.image}
              />
              <div className={classes.userInfo}>
                <Typography noWrap variant="body1">
                  {peerUser.name}
                </Typography>
                {showCompanyHandle && <span className={classes.companyHandle}>{`@${peerUserCompany?.handle}`}</span>}
              </div>
            </div>
            <Tooltip
              arrow
              title={t(isExpertOwnUser ? 'instant-call-button-tooltip' : 'instant-call-button-tooltip-disabled')}
            >
              <div>
                <IconButton onClick={onCall} data-testid="chat-header-instant-call-button" disabled={!isExpertOwnUser}>
                  <CallRingIcon style={{ fontSize: 24 }} color={isExpertOwnUser ? colors.purple100 : colors.grey60} />
                </IconButton>
              </div>
            </Tooltip>
            {canReassign && companyExperts && (
              <>
                <div className={classes.assignToSection}>
                  <SelectV2
                    disableUnderline={true}
                    MenuProps={{
                      style: { maxHeight: 'min(90vh, 450px)' },
                    }}
                    variant="standard"
                    value={expert?.id}
                    onChange={onAssignedToChange}
                    IconComponent={() => null}
                    renderValue={() => {
                      const companyExpert = companyExperts.find((companyExpert) => companyExpert.id === expert?.id);
                      if (!companyExpert) return null;
                      return (
                        <Tooltip arrow placement={'bottom'} title={t('inbox-topbar-assigned-to-tooltip')}>
                          <div className={classes.assignToSectionValue}>
                            <UserAvatar size={24} user={companyExpert} />
                            {size.x >= screenSize1920 && (
                              <div className={classes.userInfo}>
                                <Typography
                                  noWrap
                                  variant="body1"
                                  style={{ maxWidth: 260 }}
                                >{`${companyExpert.firstName} ${companyExpert.lastName}`}</Typography>
                              </div>
                            )}
                            <div className={classes.assignToSectionValueArrowIcon}>
                              <ArrowDownIcon style={{ color: colors.amazdPurple, fontSize: 12 }} />
                            </div>
                          </div>
                        </Tooltip>
                      );
                    }}
                  >
                    <ListSubheader
                      style={{
                        minHeight: 50,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '16px',
                        fontWeight: 400,
                        background: 'white',
                        color: 'black',
                        userSelect: 'none',
                        pointerEvents: 'none',
                      }}
                    >
                      <span>{t('inbox-assign-to-dropdown-header')}</span>
                      <Divider style={{ width: '100%' }} />
                    </ListSubheader>
                    {companyExperts
                      .filter((x) => x.id !== owner?.id)
                      .map((companyExpert) => {
                        const { id, firstName, lastName } = companyExpert;
                        return (
                          <MenuItem value={id} key={id} style={{ padding: '8px 16px' }}>
                            <UserAvatar size={24} user={companyExpert} />
                            <div className={classes.userInfo}>
                              <Typography
                                noWrap
                                variant="body1"
                                style={{ maxWidth: 260 }}
                              >{`${firstName} ${lastName}`}</Typography>
                            </div>
                          </MenuItem>
                        );
                      })}
                  </SelectV2>
                </div>
              </>
            )}
            <div className={classes.customerSidebarIcon}>
              {showCustomerSidebar ? (
                !showSideBar && (
                  <>
                    <div className={classes.customerSidebarIconDivider} />
                    <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                      <ToggleSidebarIcon style={{ fontSize: 24 }} color={colors.amazdPurple} />
                    </IconButton>
                  </>
                )
              ) : (
                <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                  <CallIcon color={showSideBar ? colors.amazdPurple : colors.amazdGrey0} />
                </IconButton>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
