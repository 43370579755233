import { colors } from '@amazd/common/static';
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    padding: '0px 16px',
  },
  customerCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 8,
  },
  customerCardWithShopify: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 8,
  },
  customerInfoWrapper: {
    marginLeft: 8,
  },
  customerFullName: {
    width: '100%',
    fontWeight: 500,
    fontSize: 16,
    marginTop: 4,
    marginBottom: 4,
    color: colors.black,
  },
  customerRegistrationDate: {
    width: '100%',
    fontWeight: 400,
    fontSize: 12,
    color: colors.grey80,
    marginRight: 4,
  },
  shopperTagWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  customerNoteWrapper: {
    paddingTop: 16,
  },
  customerNoteSaveWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
    height: 30,
  },
  customerShopifyInfoWrapper: {
    display: 'flex',
    marginTop: 16,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
