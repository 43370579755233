/* eslint-disable quote-props */
import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';
import { chatSideBarWidth, colors, toolbarHeight } from 'styles/theme';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: chatSideBarWidth,
    flexShrink: 0,
    background: 'white',
    maxHeight: `calc(100vh - ${toolbarHeight}px)`,
    overflow: 'hidden',
  },
  rootPaper: {
    width: chatSideBarWidth,
  },
  title: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  toolbar: {
    padding: 10,
    paddingLeft: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    borderRight: '1px solid #e0e0e0',
    borderBottom: '1px solid',
    borderBottomColor: colors.grey40,
  },
  inboxItemsList: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 85,
    display: 'block',
    height: 'calc(100vh - 113px)',
    overflow: 'auto',
    border: '1px solid',
    borderTop: 0,
    borderLeft: 0,
    borderBottom: 0,
    borderColor: colors.grey40,
  },
  itemDivider: {
    backgroundColor: colors.grey30,
  },
  searchBar: {
    height: 60,
    padding: 10,
    paddingLeft: 0,
    border: '1px solid',
    borderTop: 0,
    borderLeft: 0,
    borderColor: colors.grey40,
  },
  searchIcon: {
    minWidth: 40,
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: colors.grey20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    cursor: 'pointer',
  },
  transparentBackdrop: {
    background: 'rgb(255 255 255 / 0%)',
  },
  secondaryFilter: {
    padding: '10px 6px',
    border: '1px solid',
    borderColor: colors.grey40,
    borderLeft: 0,
    marginTop: -1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 16,
  },
  hint: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    padding: '8px 16px',
    border: '1px solid',
    borderColor: colors.grey40,
    borderLeft: 0,
    marginTop: -1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
  },
  emptyWrapper: {
    marginTop: 72,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyTitle: {
    fontSize: 18,
    fontWeight: '500',
    lineHeight: '24px',
    marginTop: 16,
    marginBottom: 4,
  },
  emptyDescription: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '20px',
    color: colors.grey80,
    textAlign: 'center',
    width: 326,
  },
}));
