import { gql, useMutation } from '@apollo/client';

const mutation = gql`
  mutation saveAmazdNote($args: SaveAmazdNoteDto!) {
    saveAmazdNote(args: $args) {
      amazdId
      note
    }
  }
`;

export const useSaveAmazdNote = () => {
  const [saveAmazdNote, { error, loading }] = useMutation(mutation, {
    refetchQueries: ['amazdNote'],
  });
  return {
    saveAmazdNote,
    error,
    loading,
  };
};
