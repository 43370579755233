import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    width: '100%',
    margin: '8px 0px',
    padding: 16,
    border: 'solid',
    borderWidth: 1,
    borderColor: colors.grey30,
    borderRadius: 16,
    boxShadow: '0px 0px 20px rgba(215, 217, 231, 0.4)',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  imgWrapper: {
    width: 64,
    height: 64,
    minWidth: 64,
    overflow: 'hidden',
    borderRadius: 8,
    backgroundColor: colors.grey10,
    cursor: 'pointer',
  },
  img: {
    width: 64,
    height: 64,
    objectFit: 'contain',
  },
  right: {
    marginLeft: 16,
    marginTop: 2,
  },
  name: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.black,
    cursor: 'pointer',
  },
  price: {
    marginTop: 6,
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
  },
  options: {
    marginTop: 10,
    fontWeight: 400,
    fontSize: 12,
    color: colors.grey80,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 80,
    marginTop: 12,
  },
  quantity: {
    marginTop: 16,
  },
  quantitySelector: {
    width: 100,
  },
  trashIcon: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
