import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CatalogueIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 18 18" fill="none" {...rest}>
    <path
      d="M3.58649 4.5435V3.75C3.58649 3.35218 3.74453 2.97064 4.02583 2.68934C4.30714 2.40804 4.68867 2.25 5.08649 2.25H12.9127C13.3106 2.25 13.6921 2.40804 13.9734 2.68934C14.2547 2.97064 14.4127 3.35218 14.4127 3.75V4.5435M14.004 15.75H3.927C3.48223 15.75 3.05568 15.5733 2.74118 15.2588C2.42668 14.9443 2.25 14.5178 2.25 14.073V6.177C2.25 5.95677 2.29338 5.7387 2.37765 5.53524C2.46193 5.33178 2.58546 5.14691 2.74118 4.99118C3.05568 4.67668 3.48223 4.5 3.927 4.5H14.004C14.2242 4.5 14.4423 4.54338 14.6458 4.62765C14.8492 4.71193 15.0341 4.83546 15.1898 4.99118C15.3455 5.14691 15.4691 5.33178 15.5533 5.53524C15.6376 5.7387 15.681 5.95677 15.681 6.177V14.073C15.681 14.5178 15.5043 14.9443 15.1898 15.2588C14.8753 15.5733 14.4488 15.75 14.004 15.75ZM8.99999 8.06142C9.22966 7.79695 9.62699 7.5 10.232 7.5C11.2903 7.5 12 8.47781 12 9.38836C12 11.2918 9.59266 12.75 8.99999 12.75C8.40733 12.75 5.99999 11.2918 5.99999 9.38836C5.99999 8.47781 6.70966 7.5 7.76799 7.5C8.37299 7.5 8.77033 7.79695 8.99999 8.06142Z"
      stroke={color}
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CatalogueIcon;
