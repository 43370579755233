import { ReactElement, ReactText } from 'react';

import { useAuthInfo } from '@amazd/common/hooks/auth';
import NoActiveAmazdsIcon from '@amazd/common/icons/NoActiveAmazdsIcon';
import { Trans } from '@amazd/common/utils/i18n';
import { Link, Typography } from '@mui/material';

import useStyles from './styles';

interface NoAmazdsProps {
  icon: ReactElement;
  text: ReactText;
}

const NoAmazds = ({ icon, text }: NoAmazdsProps) => {
  const classes = useStyles();
  const { ownUserType } = useAuthInfo();
  // todo: usePermission when isExpert is gone
  if (ownUserType?.isExpert) {
    return (
      <div className={classes.noAmazdsContainer}>
        <NoActiveAmazdsIcon />
        <Typography className={classes.noAmazdsText} variant="body1">
          <Trans i18nKey="no-amazds-add-profile-info">
            <Typography component="span" className={classes.noAmazdsBold} variant="body1">
              No active chats at the moment
            </Typography>
            <br />
            Meanwhile, <Link href={`/profile/my`}>go to your profile</Link> and add <br />
            more information about yourself.
          </Trans>
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.noAmazdsContainer}>
      {icon}
      <Typography className={classes.noAmazdsText} variant="body1">
        {text}
      </Typography>
    </div>
  );
};

export default NoAmazds;
