import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  recentSearchesText: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '24px',
    color: colors.black,
  },
  clearAllButton: {
    fontweight: 500,
    fontSize: 10,
    lineHeight: '12px',
    color: colors.purple100,
    cursor: 'pointer',
  },
  recentSearches: {},
}));
