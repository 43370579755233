import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AlarmIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 16 16" fill="none" {...rest}>
    <path
      d="M5.41751 1.76055C5.03484 1.91912 4.85318 2.35787 5.01175 2.74053C5.17031 3.12319 5.60907 3.30486 5.99173 3.14629L5.41751 1.76055ZM13.5379 5.70009L12.8448 5.98641L12.845 5.98694L13.5379 5.70009ZM10.2913 13.5334L10.5776 14.2266L10.5781 14.2264L10.2913 13.5334ZM2.45395 10.2868L3.14714 10.0004L3.14661 9.99915L2.45395 10.2868ZM5.98642 3.14283C6.36938 2.98498 6.55187 2.54658 6.39402 2.16362C6.23618 1.78066 5.79777 1.59817 5.41481 1.75601L5.98642 3.14283ZM8.55662 5.32342C8.55662 4.90921 8.22083 4.57342 7.80662 4.57342C7.3924 4.57342 7.05662 4.90921 7.05662 5.32342H8.55662ZM7.80662 8.42342H7.05662C7.05662 8.68526 7.19317 8.92814 7.41688 9.06421L7.80662 8.42342ZM9.85021 10.5442C10.2041 10.7595 10.6655 10.6471 10.8807 10.2932C11.096 9.93926 10.9836 9.47788 10.6297 9.26264L9.85021 10.5442ZM5.99173 3.14629C8.66957 2.03665 11.7367 3.3037 12.8448 5.98641L14.2311 5.41377C12.8059 1.96314 8.85967 0.334193 5.41751 1.76055L5.99173 3.14629ZM12.845 5.98694C13.9532 8.66425 12.6817 11.7322 10.0044 12.8404L10.5781 14.2264C14.0209 12.8013 15.656 8.85593 14.2309 5.41324L12.845 5.98694ZM10.005 12.8402C7.31995 13.9493 4.25252 12.6764 3.14714 10.0004L1.76076 10.5731C3.18338 14.0171 7.12928 15.6509 10.5776 14.2266L10.005 12.8402ZM3.14661 9.99915C2.03222 7.31527 3.30388 4.2485 5.98642 3.14283L5.41481 1.75601C1.96402 3.17834 0.329013 7.12491 1.76129 10.5744L3.14661 9.99915ZM7.05662 5.32342V8.42342H8.55662V5.32342H7.05662ZM7.41688 9.06421L9.85021 10.5442L10.6297 9.26264L8.19635 7.78264L7.41688 9.06421Z"
      fill={color}
    />
  </SvgIcon>
);

export default AlarmIcon;
