import React from 'react';

import { Skeleton } from '@mui/material';

import useStyles from './styles';

export interface ConversationItemProps {
  icon: React.ReactElement;
  label: string;
  value: string | React.ReactNode;
  loading?: boolean;
}

export function ConversationItem({ icon, label, value, loading }: ConversationItemProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftWrapper}>
        {loading ? (
          <Skeleton width={50} />
        ) : (
          <>
            <div className={classes.iconWrapper}>{icon}</div>
            <span className={classes.label}>{label}</span>
          </>
        )}
      </div>
      <div className={classes.valueWrapper}>
        <span className={classes.value}>{loading ? <Skeleton width={100} /> : value}</span>
      </div>
    </div>
  );
}
