import { useContext, useEffect, useState } from 'react';

import {
  ProductContext,
  ProductOptionsContext,
} from 'components/Shopify/cataloguePopup/productVariantPicker/context/productProvider/context';
import { ProductOptionsHookValue } from 'components/Shopify/cataloguePopup/productVariantPicker/hooks/useProductOptions/types';

/**
 * The `useProduct` hook returns the product object of the nearest `ProductProvider`. It must be a descendent of
 * a `ProductProvider` component.
 */
export function useProduct() {
  const data = useContext(ProductContext);
  const [state, setState] = useState<ProductOptionsHookValue | ProductOptionsContextFallback>({ ...stateFallback });
  const optionsContext = useContext(ProductOptionsContext) as ProductOptionsHookValue;
  const optionsFallbackContext = { ...stateFallback };

  useEffect(() => {
    setState(optionsContext || optionsFallbackContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsContext]);

  return {
    ...state,
    ...data,
  };
}

type ProductOptionsContextFallback = Partial<ProductOptionsHookValue>;

const stateFallback: ProductOptionsContextFallback = {
  variants: undefined,
  options: undefined,
  selectedVariant: undefined,
  setSelectedVariant: NotAvailable('setSelectedVariant'),
  selectedOptions: undefined,
  setSelectedOption: NotAvailable('setSelectedOption'),
  setSelectedOptions: NotAvailable('setSelectedOptions'),
  isOptionInStock: undefined,
};

function NotAvailable(propName: keyof ProductOptionsHookValue) {
  return () => {
    throw new Error(`The '${propName}' function is not available in server components`);
  };
}
