import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  scrollable: {
    overflowY: 'auto',
    minHeight: '100px',
    height: '100%',
  },
  header: {
    paddingTop: 16,
    padding: '0px 24px',
    fontWeight: 500,
    fontSize: 16,
    color: colors.black,
    marginBottom: 6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  body: {
    padding: '8px 16px',
  },
  footer: {
    padding: 24,
    borderTop: 'solid',
    borderTopWidth: 1,
    borderTopColor: colors.grey40,
  },
  subTotalLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  discountRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  divider: {
    backgroundColor: colors.grey40,
    height: 1,
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
  },
  totalLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  price: {
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
  },
  priceBold: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.black,
  },
  discountInputWrapper: {
    width: '100%',
    marginTop: 12,
  },
  discountInput: {
    width: '100%',
    fontSize: 40,
    fontWeight: 400,
    marginTop: 8,
  },
  discountSummaryWrapper: {
    marginBottom: 16,
  },
  discountTextWrapper: {
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  discountTagWrapper: {
    marginLeft: 8,
  },
  discountLabelClose: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 2,
    marginRight: 2,
    width: 22,
    height: 22,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  discountApplyWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: 16,
  },
  buttonSendWishBagReminder: {
    marginTop: 8,
  },
  buttonSendWishBagReminderWDiscount: {
    marginTop: 24,
  },
}));
