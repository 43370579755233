import React from 'react';

import { useTranslation } from '@amazd/common/utils/i18n';
import { useSpring, animated } from '@react-spring/web';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { ProductCatalogue } from 'components/Shopify/cataloguePopup/productCatalogue';
import type { Channel } from 'stream-chat';

import { ProductCatalogueHeader } from './header';
import { ProductCatalogueDetail } from './productCatalogueDetail';
import { ProductVariantPicker } from './productVariantPicker';
import { SearchProduct } from './searchProduct';
import useStyles from './styles';

export interface ShopifyCataloguePopupProps {
  channel: Channel;
}

export const ShopifyCataloguePopup = ({ channel }: ShopifyCataloguePopupProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { open, page } = useShopifyCataloguePopupContext();

  const renderPage = () => {
    switch (page) {
      case 'catalogue':
        return <ProductCatalogue />;
      case 'catalogueDetail':
        return <ProductCatalogueDetail />;
      case 'search':
        return <SearchProduct />;
      case 'productVariantPicker':
        return <ProductVariantPicker channel={channel} />;
      default:
        break;
    }
  };

  const animationPopup = useSpring({
    height: open ? 424 : 0,
    overflow: 'hidden',
  });

  return (
    <animated.div style={animationPopup}>
      <div className={classes.root}>
        <ProductCatalogueHeader title={t('product-catalogue-title-share-product')} />
        <div className={classes.body}>{renderPage()}</div>
      </div>
    </animated.div>
  );
};
