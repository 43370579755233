import { useCallback, useRef, useState } from 'react';

import { getClient } from '@amazd/common/apollo';
import { ShopProduct, PaginatedShopProduct } from '@amazd/common/types/shop.types';
import { gql } from '@apollo/client';
import { ProductShortFragment } from 'components/Shopify/utils/product-fragment';
import { debounce } from 'throttle-debounce';

export function useSearchProductsQuery() {
  const [items, setItems] = useState<ShopProduct[]>([]);
  const [pageInfo, setPageInfo] = useState<{ nextPage?: string } | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const searchRef = useRef<string | undefined>(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applySearch = useCallback(
    debounce(300, (query: string) => {
      if (query == searchRef.current || (!query && !searchRef.current)) {
        return;
      }
      setPageInfo(undefined);
      setItems([]);
      searchRef.current = query;
      getProducts({ mergeWithItems: [] });
    }),
    [],
  );

  const getProducts = async ({ mergeWithItems, page }: { mergeWithItems: ShopProduct[]; page?: string }) => {
    setLoading(true);

    const pagedProducts: PaginatedShopProduct = (
      await getClient().query({
        query: gql`
          query ShopProductSearch($filter: ShopProductFilterArgs!, $pagination: ShopCatalogPaginationArgs) {
            shopProductSearch(filter: $filter, pagination: $pagination) {
              items {
                 ${ProductShortFragment}
              }
              pageInfo {
                nextPage
              }
            }
          }
        `,
        variables: {
          filter: {
            productName: searchRef.current,
          },
          pagination: {
            page,
          },
        },
        fetchPolicy: 'network-only',
      })
    ).data?.shopProductSearch;

    setItems([...mergeWithItems, ...(pagedProducts?.items ?? [])]);
    setPageInfo(pagedProducts?.pageInfo);

    setLoading(false);
  };

  const loadNextPage = () => {
    if (loading) return;

    if (pageInfo?.nextPage) {
      getProducts({ mergeWithItems: items, page: pageInfo?.nextPage });
    }
  };

  return {
    items,
    loading,
    loadNextPage,
    applySearch,
    hasNextPage: !!pageInfo?.nextPage,
  };
}
