import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    padding: '8px 8px 8px 16px',
    marginBottom: '16px',
    margin: '0px 4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    width: '48% ',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: colors.grey20,
    },
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  count: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.grey20,
    padding: '3px 6px 2px',
    borderRadius: 100,
    color: colors.grey80,
    fontWeight: 500,
    fontSize: 10,
    textAlign: 'right',
  },
  countHover: {
    backgroundColor: colors.white,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
