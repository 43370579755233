import { colors } from '@amazd/common/static';
import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    padding: 0,
    maxWidth: 'inherit',
    height: '100%',
    overflow: 'hidden',
  },
  transparentBackdrop: {
    background: 'rgb(255 255 255 / 0%)',
  },
  separator: {
    height: 19,
    width: '100%',
  },
  chatSection: {
    background: 'url("static/background-pattern.png")',
    backgroundPosition: 'center',
    backgroundColor: '#f3f4fb',
    backgroundRepeat: 'repeat',
    backgroundSize: 800,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  chatContainer: {
    width: '100%',
    height: `calc(100% - ${toolbarHeight + 10}px)`,
    overflow: 'hidden',
  },
  noAmazdsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: colors.amazdGrey1,
    paddingTop: 144,
  },
  noAmazdsText: {
    marginTop: '24px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  noAmazdsBold: {
    fontWeight: 500,
  },
  amazdTabs: {
    maxWidth: 345,
  },
  unReadSwitch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  unReadSwitchLabel: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: '16px',
  },
}));
