import React from 'react';

import ScheduleEmptySvg from '@amazd/common/assets/illustrations/scheduleEmptySvg';
import { useChannelPeerUser } from '@amazd/common/hooks/useStreamChannel';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Typography } from '@mui/material';
import { useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';

import useStyles from './styles';

const ScheduleEmptyContent = () => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  const { channel } = useChatSidebarContext();
  const { peerUser } = useChannelPeerUser(channel);

  return (
    <div className={classes.root}>
      <ScheduleEmptySvg />
      <Typography className={classes.title}>{t('schedule-tab-empty-title')}</Typography>
      <Typography className={classes.subtitle}>
        {t('schedule-tab-empty-sub-title', { customerName: peerUser?.first_name })}
      </Typography>
    </div>
  );
};

export default ScheduleEmptyContent;
