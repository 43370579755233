import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    padding: 16,
  },
  noResultFoundWrapper: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noResultFoundTitle: {
    fontWeight: 500,
    fontSize: 18,
    height: 24,
    color: colors.black,
    marginTop: 4,
  },
  noResultFoundDescription: {
    width: 326,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: 12,
    color: colors.grey70,
    marginTop: 4,
  },
  buttonGoToCatalogue: {
    marginTop: 12,
  },
}));
