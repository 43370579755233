import React from 'react';

const ScheduleEmptySvg: React.FC<any> = (): React.ReactElement => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1317_9613)">
      <path
        d="M81.5322 39H85.7762C89.2162 39 92.0002 41.784 92.0002 45.224V84.78C92.0002 88.216 89.2162 91 85.7762 91H42.2242C38.7842 91 36.0002 88.216 36.0002 84.776V79M62.4 51H44M41.5202 19V27M65.5202 19V27M23.9979 79H69.2459C71.9219 79 74.4179 77.664 75.9019 75.436L78.8379 71.028C80.5905 68.3996 81.5258 65.3112 81.5259 62.152V31C81.5259 28.8783 80.683 26.8434 79.1827 25.3431C77.6824 23.8429 75.6476 23 73.5259 23H33.5259C31.4041 23 29.3693 23.8429 27.869 25.3431C26.3687 26.8434 25.5259 28.8783 25.5259 31V59.224C25.5259 61.708 24.9459 64.156 23.8379 66.38L20.4219 73.212C20.1172 73.8217 19.9734 74.4991 20.0041 75.18C20.0347 75.8609 20.2389 76.5226 20.5971 77.1024C20.9553 77.6823 21.4558 78.1609 22.051 78.493C22.6461 78.8251 23.3163 78.9996 23.9979 79Z"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <circle cx="94" cy="27" r="2" fill="#FFB13F" />
    <path
      d="M17 45C17 47.2091 15.2091 49 13 49C10.7909 49 9 47.2091 9 45C9 42.7909 10.7909 41 13 41C15.2091 41 17 42.7909 17 45Z"
      fill="#7E05FE"
    />
    <circle cx="56" cy="102" r="3" fill="#FF0066" />
    <defs>
      <clipPath id="clip0_1317_9613">
        <rect width="82" height="82" fill="white" transform="translate(15 14)" />
      </clipPath>
    </defs>
  </svg>
);

export default ScheduleEmptySvg;
