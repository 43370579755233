import { useEffect, useState } from 'react';

import { getClient } from '@amazd/common/apollo';
import { ShopCategory, PaginatedShopProduct, ShopProduct } from '@amazd/common/types/shop.types';
import { gql } from '@apollo/client';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { ProductShortFragment } from 'components/Shopify/utils/product-fragment';
import { getUniques } from 'utils';

export function useCollectionByIdQuery() {
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<ShopProduct[]>([]);
  const [children, setChildren] = useState<ShopCategory[]>([]);
  const [pageInfo, setPageInfo] = useState<{ nextPage?: string } | undefined>({});
  const { selectedCollection } = useShopifyCataloguePopupContext();

  const getCollection = async ({ mergeWithItems, page }: { mergeWithItems: ShopProduct[]; page?: string }) => {
    setLoading(true);

    const [category, pagedProducts]: [ShopCategory, PaginatedShopProduct] = await Promise.all([
      getClient()
        .query({
          query: gql`
            query ShopCategory($categoryId: String!) {
              shopCategory(categoryId: $categoryId) {
                id
                title
                children {
                  id
                  productsCount
                  title
                }
              }
            }
          `,
          variables: {
            categoryId: selectedCollection?.id,
          },
          // Used backend caching for shopCategory
          fetchPolicy: 'no-cache',
        })
        .then((res: any) => res?.data?.shopCategory),
      getClient()
        .query({
          query: gql`
            query ShopProductsFromCategory($categoryId: String!, $pagination: ShopCatalogPaginationArgs) {
              shopProductsFromCategory(categoryId: $categoryId, pagination: $pagination) {
                pageInfo {
                  nextPage
                }
                items {
                 ${ProductShortFragment}
                }
              }
            }
          `,
          variables: {
            categoryId: selectedCollection?.id,
            pagination: {
              page,
            },
          },
          // Used backend caching for shopProductsFromCategory
          fetchPolicy: 'no-cache',
        })
        .then((res: any) => res?.data?.shopProductsFromCategory),
    ]);

    setItems(getUniques([...mergeWithItems, ...(pagedProducts?.items ?? [])]));
    setChildren(category?.children || []);
    setPageInfo(pagedProducts?.pageInfo);
    setLoading(false);
  };

  const loadNextPage = () => {
    if (loading) return;

    if (pageInfo?.nextPage) {
      getCollection({ mergeWithItems: items, page: pageInfo.nextPage });
    }
  };

  useEffect(() => {
    setPageInfo({});
    setItems([]);
    setChildren([]);
    getCollection({
      mergeWithItems: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection]);

  return {
    loading,
    items,
    children,
    loadNextPage,
  };
}
