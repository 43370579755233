import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ConversationStartDateIcon: React.FC<any> = (props): React.ReactElement => (
  <SvgIcon viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M12.375 2.25V4.5M5.625 2.25V4.5M9.7575 8.25L11.2575 9.75L9.7575 11.25M6.75 9.75H11.25M4.5 3.375H13.5C14.7426 3.375 15.75 4.38236 15.75 5.625V13.5C15.75 14.7426 14.7426 15.75 13.5 15.75H4.5C3.25736 15.75 2.25 14.7426 2.25 13.5V5.625C2.25 4.38236 3.25736 3.375 4.5 3.375Z"
      fill={'none'}
      stroke="#727488"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ConversationStartDateIcon;
