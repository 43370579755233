import React from 'react';

import { ShopProductVariant } from '@amazd/common/types/shop.types';
import { useProduct } from 'components/Shopify/cataloguePopup/productVariantPicker/hooks/useProduct';
import { useProductOptions } from 'components/Shopify/cataloguePopup/productVariantPicker/hooks/useProductOptions';

import { ProductOptionsContext } from './context';

interface ProductOptionsProviderProps {
  children: React.ReactNode;
  initialVariant?: ShopProductVariant | null;
}

export function ProductOptionsProvider({ children, initialVariant }: ProductOptionsProviderProps) {
  const product = useProduct();
  const productOptions = useProductOptions({
    variants: product.variants as ShopProductVariant[],
    initialVariant,
  });

  return <ProductOptionsContext.Provider value={productOptions}>{children}</ProductOptionsContext.Provider>;
}
