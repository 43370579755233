import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    padding: '20px 16px 0px 16px',
    '&::-webkit-scrollbar': {
      color: colors.grey40,
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));
