/* eslint-disable quote-props */
import { colors } from '@amazd/common/static';
import { makeStyles } from 'tss-react/mui';

import { InboxListItemProps } from './types';

export const chatSideBarWidth = 375;

export const useStyles = makeStyles<InboxListItemProps>()(() => ({
  defaultRoot: {
    height: 98,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(122,131,204,0.1)',
    },
    borderBottom: '1px solid',
    borderBottomColor: colors.grey30,
  },
  defaultName: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 230,
  },
  defaultMessage: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    height: 20,
    color: colors.grey90,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 230,
    marginTop: 1,
  },
  unread: {
    backgroundColor: colors.white,
  },
  unreadName: {
    fontWeight: 700,
  },
  unreadMessage: {
    fontWeight: 700,
    color: colors.black,
  },
  selected: {
    backgroundColor: colors.purple20,
    '&:hover': {
      backgroundColor: '#DECCFA',
    },
  },
  selectedName: {
    color: colors.black,
  },
  selectedMessage: {
    color: colors.black,
  },
  read: {
    backgroundColor: colors.white,
  },
  readName: {
    color: colors.grey90,
  },
  readMessage: {
    color: colors.grey90,
  },
  avatar: {
    '& img': {
      borderRadius: '100%',
    },
  },
  itemDivider: {
    height: 1,
    backgroundColor: colors.grey30,
  },
  expertSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: -6,
  },
  companyHandle: {
    fontSize: '12px',
    color: colors.amazdGrey3,
    marginBottom: 4,
    lineHeight: '10px',
  },
  timeTag: {
    marginTop: 7,
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));
