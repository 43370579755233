import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    width: 480,
    height: 48,
    backgroundColor: colors.white,
    border: 'solid',
    borderWidth: 1,
    borderColor: colors.white,
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: 8,
    padding: '14px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  rootFocused: {
    borderColor: colors.purple100,
    boxShadow: '0px 0px 0px 4px rgba(126, 5, 254, 0.1)',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 22,
    cursor: 'pointer',
  },
  textarea: {
    marginLeft: 8,
    height: 22,
    fontFamily: 'FilsonPro',
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
    backgroundColor: colors.white,
    border: 'none',
    resize: 'none',
    width: '100%',
    outline: 'none',
    '&::placeholder': {
      fontFamily: 'FilsonPro',
      color: colors.grey70,
      fontWeight: 400,
      fontSize: 16,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));
