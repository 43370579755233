import { colors } from '@amazd/common/static';
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  titleWrapper: {
    marginLeft: 8,
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.black,
  },
  cardWrapper: {
    padding: 16,
    marginTop: 8,
    backgroundColor: colors.grey20,
    borderRadius: 8,
  },
}));
