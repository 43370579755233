import { gql, useMutation } from '@apollo/client';

export const useShareProductMutation = () => {
  const [shareProductEvent] = useMutation(
    gql`
      mutation amazdShopifySendProductMessage($args: AmazdShopifySendProductMessageDto!) {
        amazdShopifySendProductMessage(args: $args) {
          amazdId
        }
      }
    `,
  );

  const shareProduct = async (amazdId: string, product: any, variant?: any) => {
    await shareProductEvent({
      variables: {
        args: {
          amazdId,
          product,
          variant,
        },
      },
    });
  };

  return {
    shareProduct,
  };
};
