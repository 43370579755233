import { useEffect, useReducer, useRef, useState } from 'react';

import { useAuthInfo } from '@amazd/common/hooks/auth';
import type { Channel, ChannelFilters, ChannelSort, Event, EventTypes, StreamChat } from 'stream-chat';

import { hasAccessTo, Permission } from '../utils/permissions';
import { useStreamClient } from './useStreamClient';

const getChannelById = async (client: StreamChat, cid: string) => {
  const options = {
    state: true,
    watch: true,
    presence: true,
  };

  const [type, id] = cid.split(':');
  const channel = client.channel(type, id);
  await channel.watch(options);

  return channel;
};

const distinct = (channels: Channel[]) => {
  const seen: any = {};
  return channels.filter((item) => {
    return seen[item.id as string] ? false : (seen[item.id as string] = true);
  });
};

export const useChannelList = (
  query?: {
    page: number;
    filter: ChannelFilters;
    showUnReadOnly?: boolean;
  },
  options?: {
    onUnreadChannelsChange?: (count: number) => void;
  },
): {
  client?: StreamChat;
  channels?: Channel[];
  connected: boolean;
  loading: boolean;
  findChannel: (amazdId: string) => Promise<Channel>;
} => {
  const { ownUser } = useAuthInfo();
  const { client, connected } = useStreamClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [channels, setChannels] = useState<Channel[]>();
  const [, forceRerender] = useReducer((x) => x + 1, 0);
  const unreadChannelsCountRef = useRef<number | undefined>();
  const channelToBeRemoved = useRef<string>();

  const queryRef = useRef(query);
  queryRef.current = query;
  const loadChannels = async () => {
    if (connected && query && client.wsConnection) {
      setLoading(true);

      if (query.page === 0) {
        setChannels([]);
      }
      const sort = query.showUnReadOnly
        ? [{ unread_count: -1, last_message_at: -1, last_updated: -1 }]
        : [{ last_message_at: -1, last_updated: -1 }];

      const options = {
        state: true,
        watch: true,
        presence: true,
        offset: query.page * 10,
        limit: 10, // 30 is maximum
      };

      const queryResult = await client.queryChannels(query.filter, sort as ChannelSort, options);

      const filteredQueryResult = query.showUnReadOnly ? queryResult.filter((c) => c.countUnread()) || [] : queryResult;
      if (channels && query.page > 0) {
        setChannels(distinct([...channels, ...filteredQueryResult]));
      } else {
        setChannels(filteredQueryResult);
      }
      setLoading(false);
    } else {
      setChannels(undefined);
    }
  };

  useEffect(() => {
    loadChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, query]);

  const moveToTop = (cid: string) => {
    setChannels((channels) => {
      if (channels) {
        const indexOf = channels.findIndex((x) => x.cid === cid);
        const channel = channels.find((x) => x.cid === cid);
        if (channel) {
          channels.splice(indexOf, 1);
          channels.unshift(channel);
        }
        return [...channels];
      }
      return [...(channels || [])];
    });
  };

  const findChannel = async (amazdId: string) => {
    const queryResult = await client.queryChannels({ id: amazdId }, {}, { limit: 1 });
    return queryResult[0];
  };

  const addChannel = async (cid: string) => {
    if (channelToBeRemoved.current === cid) {
      // To avoid race condition when a channel is removed and before we could stop
      // watching channel, a new message is received
      return;
    }
    const channel = await getChannelById(client, cid);
    if (!channel) {
      throw new Error('Channel not found');
    }
    // do not add channel if archived status doesn't match the filter
    if (queryRef.current?.filter.hidden !== channel.data?.hidden) {
      return;
    }

    // in case expert filter is set and current channel is from other expert
    const expertUserId = queryRef?.current?.filter.expert_user_id;
    if (expertUserId) {
      if (channel?.data?.expert_user_id !== expertUserId) {
        return;
      }
    }
    const ownUserFilterApplied = queryRef?.current?.filter.members && !queryRef.current.filter.team;
    if (ownUserFilterApplied) {
      if (channel.data?.expert_user_id !== ownUser?.id) {
        return;
      }

      if (!(!queryRef.current?.showUnReadOnly || (queryRef.current?.showUnReadOnly && channel.countUnread()))) return;
    }

    await channel.watch();

    setChannels((channels) => distinct([channel, ...(channels || [])]));
  };

  const addChannelIfNotExist = async (cid: string) => {
    let exist = null;
    setChannels((channels) => {
      exist = channels?.find((x) => x.cid === cid);
      return channels;
    });
    if (!exist) {
      await addChannel(cid);
    }
  };

  const removeChannel = async (cid: string) => {
    channelToBeRemoved.current = cid;
    setChannels((channels) => {
      if (channels) {
        const channel = channels.find((x) => x.cid === cid);
        if (channel) {
          channel.stopWatching().then(() => (channelToBeRemoved.current = ''));
          channels?.splice(channels.indexOf(channel), 1);
        }
      }
      return [...(channels || [])];
    });
  };

  const reload = () => {
    loadChannels();
  };

  const handleEvents = async (event: Event) => {
    const cid = event.cid || (event.custom_data as any)?.cid;

    if (event.unread_channels !== undefined) {
      if (unreadChannelsCountRef.current !== event.unread_channels) {
        unreadChannelsCountRef.current = event.unread_channels;
        options?.onUnreadChannelsChange?.(event.unread_channels);
      }
    }

    switch (event.type) {
      case 'message.new':
        await addChannelIfNotExist(cid);
        moveToTop(cid);
        break;
      case 'notification.message_new':
      case 'notification.added_to_channel':
        addChannel(cid);
        break;
      // case 'channel.deleted': // should never be a case
      case 'channel.visible':
      case 'channel.hidden':
        removeChannel(cid);
        break;
      case 'notification.removed_from_channel':
        if (!hasAccessTo(ownUser?.role, Permission.SEE_TEAM)) {
          await removeChannel(cid);
        }
        reload();
        break;
      case 'member.removed':
      case 'member.added':
      case 'channel.updated':
      case 'user.updated':
      case 'user.presence.changed':
      case 'message.read':
        forceRerender();
        break;
      case 'connection.recovered':
        reload();
        break;
      default:
        break;
    }

    // manager special events
    const managerSpecial = event.type.startsWith('manager');
    if (managerSpecial) {
      const originalType = (event?.custom_data as any)?.original_type as EventTypes;
      switch (originalType) {
        case 'message.new':
        case 'channel.created':
          addChannelIfNotExist(cid);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (client && connected) {
      client.on(handleEvents);
    }

    return () => {
      client.off(handleEvents);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, connected]);

  return { client, connected, channels, findChannel, loading };
};
