import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  date: {
    fontWeight: 400,
    fontSize: 14,
    color: colors.black,
  },
  price: {
    fontWeight: 700,
    fontSize: 16,
    color: colors.black,
    textAlign: 'right',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 12,
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    padding: '4px 8px',
    borderRadius: 4,
  },
  labelIdWrapper: {
    marginLeft: 8,
  },
  label: {
    fontWeight: 500,
    fontSize: 14,
    color: colors.black,
  },
}));
