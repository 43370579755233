import React, { useEffect, useState } from 'react';

import NoActiveAmazdsIcon from '@amazd/common/icons/NoActiveAmazdsIcon';
import NoArchivedAmazds from '@amazd/common/icons/NoArchivedAmazds';
import { Action } from '@amazd/common/redux/types';
import { useTranslation } from '@amazd/common/utils/i18n';

export interface AmazdsListTab {
  noAmazdIcon: React.ReactElement;
  noAmazdText: string;
  hasNoAmazds: boolean;
  tabName: string;
  loadNext: () => Action;
  reload: (expertId?: string) => Action;
}

export interface AmazdsTab {
  noAmazdIcon: React.ReactElement;
  noAmazdText: string;
  tabName: string;
  archived: boolean;
}

export const useAmazdsTabs = (): { tabs?: AmazdsTab[] } => {
  const { t } = useTranslation();

  const [tabs, setTabs] = useState<AmazdsTab[]>();

  useEffect(() => {
    const tabs = [
      {
        tabName: t('inbox-active-tab-header'),
        noAmazdIcon: <NoActiveAmazdsIcon />,
        noAmazdText: t('no-active-amazds-text'),
        archived: false,
      },
      {
        tabName: t('inbox-done-tab-header'),
        noAmazdIcon: <NoArchivedAmazds />,
        noAmazdText: t('no-done-amazds-text'),
        archived: true,
      },
    ];
    setTabs(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tabs };
};
