import { colors } from '@amazd/common/static';
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    backgroundColor: colors.white,
    padding: 12,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
    height: 102,
  },
  rootDisabled: {
    backgroundColor: colors.grey30,
    padding: 12,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
    height: 102,
  },
  label: {
    fontFamily: 'FilsonPro',
    fontSize: 10,
    fontWeight: 400,
    color: colors.grey70,
  },
  textarea: {
    fontFamily: 'FilsonPro',
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
    backgroundColor: colors.white,
    border: 'none',
    width: '100%',
    marginTop: 4,
    outline: 'none',
    resize: 'none',
    '&::placeholder': {
      fontFamily: 'FilsonPro',
      color: colors.grey70,
      fontWeight: 400,
      fontSize: 16,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  textareaDisabled: {
    fontFamily: 'FilsonPro',
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
    backgroundColor: colors.grey30,
    border: 'none',
    width: '100%',
    marginTop: 4,
    outline: 'none',
    resize: 'none',
    '&::placeholder': {
      fontFamily: 'FilsonPro',
      color: colors.grey70,
      fontWeight: 400,
      fontSize: 16,
    },
  },
}));
