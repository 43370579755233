import { colors } from '@amazd/common/static';
import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

const HEIGHT = 16;
const WIDTH = 28;

export default makeStyles((theme: CustomTheme) => ({
  root: {
    width: WIDTH,
    height: HEIGHT,
    padding: 0,
    margin: theme.spacing(1),
    '& .MuiIconButton-root': {
      width: 'unset',
      height: 'unset',
    },
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: colors.amazdPurple,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#56C087',
      border: '6px solid #fff',
    },
    '&:hover': {
      background: 'inherit',
    },
    '&:hover + $track': {
      backgroundColor: colors.grey50,
      opacity: 1,
      border: 'none',
    },
    '&$disabled': {
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        cursor: 'not-allowed',
      },
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
      },
    },
  },
  thumb: {
    width: HEIGHT - 4,
    height: HEIGHT - 4,
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  },
  track: {
    borderRadius: HEIGHT / 2,
    border: 'none',
    backgroundColor: colors.grey40,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  disabled: { opacity: 1 },
}));
