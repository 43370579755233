import { useEffect, useState } from 'react';

import { getClient } from '@amazd/common/apollo';
import { useMyShopDetails } from '@amazd/common/hooks/useShopDetails';
import { ShopCategory } from '@amazd/common/types/shop.types';
import { gql } from '@apollo/client';
import { getUniques } from 'utils';

export function useCollectionsQuery() {
  const [items, setItems] = useState<ShopCategory[]>([]);
  const [pageInfo, setPageInfo] = useState<{ nextPage?: string }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { shopDetails } = useMyShopDetails();

  const getCollections = async ({ mergeWithItems, page }: { mergeWithItems: ShopCategory[]; page?: string }) => {
    if (!shopDetails) return;
    setLoading(true);

    const data = (
      await getClient().query({
        query: gql`
          query ShopRootCategories {
            shopRootCategories {
              items {
                id
                title
                productsCount
              }
              pageInfo {
                nextPage
              }
            }
          }
        `,
        variables: {
          pagination: {
            page,
          },
        },
        fetchPolicy: 'network-only',
      })
    ).data;

    setItems(getUniques([...mergeWithItems, ...(data.shopRootCategories?.items ?? [])]));
    setPageInfo(data?.shopRootCategories?.pageInfo);
    setLoading(false);
  };

  const loadNextPage = () => {
    if (loading) return;

    if (pageInfo?.nextPage) {
      getCollections({ mergeWithItems: items, page: pageInfo?.nextPage });
    }
  };

  useEffect(() => {
    if (shopDetails?.shopDomain) {
      setPageInfo({});
      setItems([]);
      getCollections({
        mergeWithItems: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopDetails?.shopDomain]);

  return {
    items,
    pageInfo,
    loading,
    loadNextPage,
  };
}
