import { reportError } from '@amazd/common/utils';
import { gql, useQuery } from '@apollo/client';

const amazdQuery = gql`
  query ($id: String!) {
    amazd(id: $id) {
      appointments {
        id
        creatorAsUserTypeId
        receiverAsUserTypeId
        amazdId
        duration
        startingAt
      }
      expert {
        userType {
          id
        }
      }
      owner {
        userType {
          email
          phone
          spokenLanguages
          timezone
        }
      }
    }
  }
`;

export const useAmazd = (amazdId?: string) => {
  const { data, loading, refetch, error } = useQuery(amazdQuery, {
    variables: {
      id: amazdId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  if (error) {
    reportError(error, { extraInfo: { amazdId } });
  }
  return { amazd: data?.amazd, loading, reload: refetch };
};
