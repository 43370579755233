import React from 'react';

import CatalogueIcon from '@amazd/common/icons/CatalogueIcon';
import ChevronRightIcon from '@amazd/common/icons/ChevronRightIcon';
import { truncateText } from '@amazd/common/utils';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Breadcrumbs } from '@mui/material';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { colors } from 'styles/theme';

import useStyles from './styles';

export const ProductCatalogueSubHeader = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { page, changePage, selectedCollection, selectedProduct, breadcrumbs, openBreadcrumb } =
    useShopifyCataloguePopupContext();

  const goToCatalogue = () => {
    if (page !== 'catalogue') changePage('catalogue');
  };

  const hasSelection = !!(selectedCollection?.title || selectedProduct?.title);

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<ChevronRightIcon color={colors.grey80} style={{ fontSize: 20 }} />}
        aria-label="breadcrumb"
      >
        {[
          <div key="root" className={classes.rootCategory}>
            <CatalogueIcon color={hasSelection ? colors.grey80 : colors.black} style={{ fontSize: 18 }} />
            <div
              className={`${classes.text} ${classes.textCatalogue} ${hasSelection && classes.previous}`}
              onClick={goToCatalogue}
            >
              {t('product-catalogue-sub-header-catalogue')}
            </div>
          </div>,
          ...breadcrumbs.map((breadcrumb, index) => (
            <div
              key={breadcrumb.id}
              className={`${classes.text} ${index === breadcrumbs.length - 1 ? '' : classes.previous}`}
              onClick={() => openBreadcrumb(breadcrumb.id)}
            >
              {truncateText(breadcrumb.label, 50)}
            </div>
          )),
        ]}
      </Breadcrumbs>
    </div>
  );
};
