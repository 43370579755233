import { colors } from '@amazd/common/static';
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  tag: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    padding: '3px 6px 2px 6px',
    backgroundColor: colors.grey30,
    borderRadius: '4px',
    color: colors.black,
  },
  tagHasRight: {
    padding: '3px 0px 2px 6px !important',
  },
  label: {
    textAlign: 'left',
    fontWeight: 500,
    fontSize: 12,
  },
}));
