import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    marginLeft: 16,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
