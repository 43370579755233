import { colors } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  root: {
    padding: '0px 16px',
  },
  body: {
    marginTop: 8,
    padding: '32px 24px',
    backgroundColor: colors.grey20,
    borderRadius: 8,
  },
  title: {
    marginTop: 16,
    marginLeft: 8,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '22px',
  },
  bulletList: {
    marginBottom: 16,
    width: 270,
  },
  joinButton: {
    marginTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  joınButtonSubInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  joınButtonSubInfo: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: '16px',
    marginTop: 8,
    textAlign: 'center',
    width: 244,
    color: colors.grey70,
  },
  cancelButton: {
    cursor: 'pointer',
    color: colors.red100,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '22px',
    marginTop: 25,
  },
  cancelRoot: {
    width: '100%',
    height: '100%',
    padding: 22,
    paddingTop: 0,
  },
  container: {
    background: 'white',
    borderRadius: 16,
  },
  titleWrapper: {
    marginBottom: 9,
  },
  cancelTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 500,
  },
  lightText: {
    color: colors.amazdGrey3,
  },
  cancelText: {
    lineHeight: '24px',
  },
  linkButton: {
    margin: '0 auto',
    width: 'fit-content',
    marginTop: 15,
    fontWeight: 400,
    color: `${colors.amazdPurple} !important`,
  },
  paragraph: {
    fontSize: 14,
    color: colors.amazdGrey3,
    marginTop: 20,
    marginBottom: 10,
  },
  bulletWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    width: 280,
    '& svg': {
      marginRight: 8,
    },
    '& p': {
      width: 254,
    },
  },
  bullet: {
    fontSize: 14,
    lineHeight: '20px',
  },
  joinCallWrapper: {
    marginTop: 36,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  joinCallButton: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  joinCallSubInfo: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: '16px',
    color: colors.grey70,
    textAlign: 'center',
    marginTop: 8,
  },

  cancelContainer: {
    padding: 22,
    position: 'relative',
    width: '100%',
  },
  manageBookingContainer: {
    padding: 22,
    position: 'relative',
    width: '100%',
  },
  animation: {
    position: 'absolute',
    top: 0,
  },
  justBookedContainer: {
    position: 'relative',
    padding: 22,
  },
}));
