import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 98.67,
    paddingBottom: 4,
  },
  valueWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  valueCurrency: {
    fontWeight: 500,
    fontSize: 16,
    height: 28,
    color: colors.black,
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    fontWeight: 500,
    fontSize: 24,
    color: colors.black,
    height: 32,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontWeight: 400,
    fontSize: 12,
    alignItems: 'center',
    color: colors.grey80,
  },
}));
