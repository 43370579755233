import React, { createRef, KeyboardEvent, useState } from 'react';

import CloseCircleIcon from '@amazd/common/icons/CloseCircleIcon';
import SearchIcon from '@amazd/common/icons/SearchIcon';
import { useTranslation } from '@amazd/common/utils/i18n';
import { useSpring, animated } from '@react-spring/web';
import { colors } from 'styles/theme';

import useStyles from './styles';

export interface SearchProductInputProps {
  value: string;
  setValue: (value: string) => void;
  onBlur?: () => void;
}

export const SearchProductInput = ({ value, setValue, ...rest }: SearchProductInputProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [focus, setFocus] = useState(true);

  const textareaRef = createRef<HTMLTextAreaElement>();

  const animationCloseIcon = useSpring({
    opacity: focus && value ? 1 : 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const onClickSearch = () => {
    textareaRef.current?.focus();
  };

  const onClickClose = () => {
    setValue('');
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
    if (rest.onBlur) rest.onBlur();
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') textareaRef.current?.blur();
  };

  return (
    <div className={`${classes.root} ${focus && classes.rootFocused}`}>
      <div className={classes.iconWrapper} onClick={onClickSearch}>
        <SearchIcon color={colors.grey70} style={{ fontSize: 20 }} />
      </div>
      <textarea
        id="search_product"
        ref={textareaRef}
        className={classes.textarea}
        placeholder={t('product-catalogue-search-bar-placeholder')}
        rows={1}
        autoFocus={focus}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={onKeyDown}
      />
      <div className={classes.iconWrapper} onClick={onClickClose}>
        <animated.div style={animationCloseIcon}>
          <CloseCircleIcon color={colors.grey70} style={{ fontSize: 20 }} />
        </animated.div>
      </div>
    </div>
  );
};
