import React, { useState } from 'react';

import { ProductUnavailableLabel } from '@amazd/common/components/shopify/productUnavailableLabel';
import { ShopProductOption } from '@amazd/common/types/shop.types';
import { trackWishbagShared } from '@amazd/common/utils/analytics';
import { useTranslation } from '@amazd/common/utils/i18n';
import { getCurrencySymbol } from '@amazd/common/utils/money';
import { getProductInfo } from '@amazd/common/utils/product';
import { ShopifyButton } from 'components/Shopify/button';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { useProduct } from 'components/Shopify/cataloguePopup/productVariantPicker/hooks/useProduct';
import { Wish2BagButton } from 'components/Shopify/wish2BagButton';
import type { Channel } from 'stream-chat';

import { useShareProductMutation } from './hooks/useShareProductMutation';
import { SelectProductOption } from './selectOption';
import useStyles from './styles';

export interface ProductVariantDetailProps {
  channel: Channel;
}

export const ProductVariantDetail = ({ channel }: ProductVariantDetailProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openSelect, setOpenSelect] = useState(false);

  const { setOpen, changePage } = useShopifyCataloguePopupContext();
  const product = useProduct();
  const {
    title,
    priceRangeV2,
    featuredImage,
    selectedVariant,
    options,
    selectedOptions,
    variants,
    publishedOnCurrentPublication,
  } = product;

  const { shareProduct } = useShareProductMutation();

  const { isUnavailable, price } = getProductInfo(product, selectedVariant);

  const onCancel = () => {
    setOpen(false);
    changePage('catalogue');
  };

  const onShare = async () => {
    shareProduct(channel.id as string, product, selectedVariant);
    setOpen(false);
    changePage('catalogue');
    console.log(channel);
    trackWishbagShared({
      channel_id: channel.id || '',
      expert_id: (channel.data as any).expert_user_id,
      shopper_id: (channel.data as any).owner_user_id,
      sent_by: 'expert',
    });
  };
  const image = selectedVariant?.image || featuredImage;

  return (
    <>
      <div className={`${classes.body} ${openSelect && classes.bodyOpenSelect}`}>
        <div className={classes.imgWrapper}>
          <img className={classes.img} src={image?.url} />
        </div>
        <div className={classes.right}>
          <div className={classes.infoWrapper}>
            <div className={classes.info}>
              <div className={classes.name}>{title}</div>
              <div className={classes.price}>
                {getCurrencySymbol(priceRangeV2?.minVariantPrice?.currencyCode as CurrencyCode)}
                {price}
              </div>
              {isUnavailable && (
                <div className={classes.outOfStock}>
                  <ProductUnavailableLabel
                    type={publishedOnCurrentPublication === false ? 'not-published' : 'out-of-stock'}
                  />
                </div>
              )}
            </div>
            <div className={classes.wish2Bag}>
              {!isUnavailable && (
                <Wish2BagButton product={product} variant={selectedVariant} tooltipPlacement={'top'} />
              )}
            </div>
          </div>
          <div className={classes.optionWrapper}>
            {!!variants?.length &&
              variants.length > 1 &&
              options?.map((optionWithValues, index) => {
                return (
                  <SelectProductOption
                    key={index.toString()}
                    optionWithValues={optionWithValues as ShopProductOption}
                    value={
                      selectedOptions && Object.keys(selectedOptions).includes(optionWithValues?.name as string)
                        ? selectedOptions[optionWithValues?.name as string]
                        : ''
                    }
                    onToggle={(value) => setOpenSelect(value)}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttonCancel}>
          <ShopifyButton type={'secondary'} size={'regular'} onClick={onCancel}>
            {t('product-catalogue-product-variant-cancel')}
          </ShopifyButton>
        </div>
        <ShopifyButton type={'primary'} size={'regular'} onClick={onShare}>
          {t('product-catalogue-product-variant-send-product')}
        </ShopifyButton>
      </div>
    </>
  );
};
