import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    backgroundColor: colors.white,
    padding: '5px 6px',
    border: 'solid',
    borderWidth: 1,
    borderColor: colors.grey30,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
  },
  disabled: {
    opacity: '0.4',
  },
  text: {
    minWidth: 28,
    textAlign: 'center',
    color: colors.black,
    fontWeight: 400,
    fontSize: 12,
  },
  icon: {
    fontSize: 14,
  },
  iconActive: {
    cursor: 'pointer',
  },
}));
