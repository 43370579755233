import { Amazd } from '@amazd/common/redux/types';
import { reportError } from '@amazd/common/utils';
import { gql, useQuery } from '@apollo/client';

const amazdQuery = gql`
  query ($id: String!) {
    amazd(id: $id) {
      id
      createdAt
      amazdUsers {
        id
        isRole
        userType {
          id
          avatar
          handle
          description
          user {
            id
            firstName
            lastName
            email
          }
          amazdsCount
          description
          company {
            id
            name
            handle
            description
            expertsCount
            image
          }
        }
      }
      owner {
        id
        isRole
        userType {
          id
          avatar
          handle
          description
          user {
            id
            firstName
            lastName
            email
          }
          company {
            id
            name
            handle
            description
            expertsCount
            image
          }
        }
      }
      expert {
        id
        isRole
        userType {
          id
          avatar
          handle
          description
          user {
            id
            firstName
            lastName
            email
          }
          company {
            id
            name
            handle
            description
            expertsCount
            image
          }
        }
      }
      appointments {
        id
        creatorAsUserTypeId
        receiverAsUserTypeId
        amazdId
        duration
        startingAt
      }
    }
  }
`;

export const useAmazd = (amazdId?: string) => {
  const { data, loading, refetch, error } = useQuery(amazdQuery, {
    variables: {
      id: amazdId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  if (error) {
    reportError(error, { extraInfo: { amazdId } });
  }

  return { amazd: data?.amazd as Amazd, loading, reload: refetch };
};
