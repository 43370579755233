import React, { useEffect } from 'react';

import Loader from '@amazd/common/components/Loader';
import { useTrail, animated } from '@react-spring/web';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { ProductProvider } from 'components/Shopify/cataloguePopup/productVariantPicker/context/productProvider';
import { ProductCatalogueSubHeader } from 'components/Shopify/cataloguePopup/subHeader';
import type { Channel } from 'stream-chat';

import { useProductQuery } from './hooks/useProductQuery';
import { ProductVariantDetail } from './productVariantDetail';

export interface ProductVariantPickerProps {
  channel: Channel;
}

export const ProductVariantPicker = ({ channel }: ProductVariantPickerProps) => {
  const { page } = useShopifyCataloguePopupContext();
  const open = page === 'productVariantPicker';
  const [trail, api] = useTrail(1, () => ({
    opacity: 0,
    from: { opacity: 0 },
  }));

  useEffect(() => {
    if (open)
      setTimeout(() => {
        api.start({ opacity: 1 });
      }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { product, loading } = useProductQuery();

  return (
    <>
      <ProductCatalogueSubHeader />
      {loading ? (
        <Loader size={100} />
      ) : (
        product && (
          <ProductProvider data={product as any} initialVariant={product?.variants?.[0]}>
            {trail.map((style, index) => (
              <animated.div
                key={index.toString()}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  minHeight: '100px',
                  overflow: 'auto',
                  ...style,
                }}
              >
                <ProductVariantDetail channel={channel} />
              </animated.div>
            ))}
          </ProductProvider>
        )
      )}
    </>
  );
};
