import { colors } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 7,
  },
  rootColorful: {
    marginTop: 5,
  },
  default: {
    fontSize: 10,
    lineHeight: '12px',
  },
  colorful: {
    fontWeight: 500,
    color: colors.black,
    borderRadius: 4,
    padding: '2px 4px',
  },
  notColorful: {
    fontWeight: 400,
    color: colors.grey80,
  },
  green: {
    backgroundColor: colors.green40,
  },
  orange: {
    backgroundColor: colors.orange40,
  },
  red: {
    backgroundColor: colors.red40,
  },
}));
