import React, { useEffect } from 'react';

import { Loader } from '@amazd/common/components';
import { useAmazd } from 'hooks/useAmazd';
import { useAmazdAppointment } from 'hooks/useAmazdAppointment';

import ScheduleContent from './scheduleContent';
import ScheduleEmptyContent from './scheduleEmptyContent';
import { ScheduleCallViewProps } from './types';

const ScheduleCallView: React.FC<ScheduleCallViewProps> = ({ amazdId, onJoinCall }: ScheduleCallViewProps) => {
  const { amazd, reload: reloadAmazd, loading: amazdLoading } = useAmazd(amazdId);
  const { appointment } = useAmazdAppointment(amazd);

  useEffect(() => {
    const reloadInterval = setInterval(() => {
      reloadAmazd();
    }, 30000);
    return () => clearInterval(reloadInterval);
  }, [amazd, reloadAmazd]);

  if (amazdLoading && !amazd) {
    return <Loader size={128} />;
  }

  if (appointment)
    return <ScheduleContent reloadAmazd={reloadAmazd} appointment={appointment} onJoinCall={onJoinCall} />;

  return <ScheduleEmptyContent />;
};

export default ScheduleCallView;
