import React, { useEffect, useState } from 'react';

import { useTranslation } from '@amazd/common/utils/i18n';
import { RecentSearchCard } from 'components/RecentSearchCard';

import { inboxSearchHistory } from './searchHistory';
import { useStyles } from './styles';

export interface InboxRecentSearchProps {
  onSearchItem: (item: string) => void;
}

export default function InboxRecentSearch({ onSearchItem }: InboxRecentSearchProps): JSX.Element | null {
  const classes = useStyles();

  const { t } = useTranslation();

  const [historyItems, setHistoryItems] = useState<Array<string>>([]);

  const getHistory = () => {
    setHistoryItems(inboxSearchHistory.getHistory());
  };

  useEffect(() => {
    getHistory();
  }, []);

  const onClearHistory = () => {
    inboxSearchHistory.clearHistory();
    getHistory();
  };

  if (!historyItems.length) return null;

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <div className={classes.recentSearchesText}>{t('inbox-recent-search')}</div>
        <div className={classes.clearAllButton} onClick={onClearHistory}>
          {t('inbox-clear-all')}
        </div>
      </div>
      <div className={classes.recentSearches}>
        {historyItems.map((item, index) => (
          <RecentSearchCard
            key={index.toString()}
            title={item}
            onSearch={() => {
              onSearchItem(item);
            }}
            onDelete={() => {
              inboxSearchHistory.removeItemFromHistory(item);
              getHistory();
            }}
          />
        ))}
      </div>
    </div>
  );
}
