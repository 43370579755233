import React from 'react';

import { useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';
import ScheduleCallView from 'views/ScheduleCallView';

export function CallTab() {
  const { channel, showScheduleTab, onJoinVideoCall } = useChatSidebarContext();

  return (
    <ScheduleCallView amazdId={channel?.id as string} showScheduleTab={showScheduleTab} onJoinCall={onJoinVideoCall} />
  );
}
