import React, { useState } from 'react';

import { useShopDetailsByAmazdId } from '@amazd/common/hooks/useShopDetails';
import { useChannelPeerUser } from '@amazd/common/hooks/useStreamChannel';
import CloseXIcon from '@amazd/common/icons/CloseXIcon';
import InfoIcon from '@amazd/common/icons/InfoIcon';
import { ShopPlatform } from '@amazd/common/types';
import { useTranslation } from '@amazd/common/utils/i18n';
import { getCurrencySymbol } from '@amazd/common/utils/money';
import { Box, Button, CircularProgress, TextField, Tooltip, Typography } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import { ShopifyButton } from 'components/Shopify/button';
import { useShopifyCartContext } from 'components/Shopify/wishBag/hooks/useCart';
import { colors } from 'styles/theme';
import { useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';

import { LineItem } from './lineItem';
import useStyles from './styles';

export function WishBagContent() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { channel } = useChatSidebarContext();
  const { peerUser } = useChannelPeerUser(channel);
  const [discountInput, setDiscountInput] = useState('');
  const [discountError, setDiscountError] = useState(false);
  const customerName = peerUser?.first_name;

  const {
    details: { shopPlatform },
  } = useShopDetailsByAmazdId(channel?.id as string);

  const {
    lineItems,
    subTotal,
    onSendWishBagReminder,
    loadingSend,
    discountTotal,
    applyDiscountToWishBag,
    loadingApplyDiscount,
    removeDiscountFromWishBag,
    loadingRemoveDiscount,
    loadingSave,
    itemsCount,
    discounts,
  } = useShopifyCartContext();

  const reversedLineItems = [...lineItems].reverse();
  const isDiscountApplied = !!discounts?.length;
  let canApplyDiscount = true;

  if (shopPlatform === ShopPlatform.MAGENTO && discounts?.length) {
    // Magento supports only one coupon per cart
    canApplyDiscount = false;
  }

  const currencySymbol = getCurrencySymbol(
    lineItems[0].product.priceRangeV2?.minVariantPrice?.currencyCode as CurrencyCode,
  );

  const animationDiscountApplyButton = useSpring({
    color: discountInput ? colors.amazdPurple : colors.grey50,
    fontSize: 16,
    fontWeight: 400,
  });

  const handleApplyDiscount = async () => {
    if (!discountInput) return;

    const applied = await applyDiscountToWishBag(discountInput);

    if (!applied) setDiscountError(true);
    else {
      setDiscountInput('');
      setDiscountError(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.scrollable}>
        <Tooltip title={t('wish-bag-title-tooltip')}>
          <div className={classes.header}>
            {t('wish-bag-content-title-for-customer', { customerName })}{' '}
            <InfoIcon color={colors.grey60} style={{ fontSize: 20, marginLeft: 6 }} />
          </div>
        </Tooltip>
        <div className={classes.body}>
          {reversedLineItems.map((lineItem, index) => (
            <LineItem key={index.toString()} lineItem={lineItem} />
          ))}
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.subTotalLabel}>
          <div className={classes.price}>
            {t(itemsCount === 1 ? 'wish-bag-content-subtitle-single' : 'wish-bag-content-subtitle-multiple', {
              count: itemsCount,
            })}
          </div>
          <div className={isDiscountApplied ? classes.price : classes.priceBold}>
            {loadingSave ? <CircularProgress size={12} /> : itemsCount && `${currencySymbol}${subTotal.toFixed(2)}`}
          </div>
        </div>
        {(isDiscountApplied || loadingApplyDiscount) && (
          <div className={classes.discountSummaryWrapper}>
            <div className={classes.discountRow}>
              <div className={classes.discountTextWrapper}>
                {t(discounts.length === 1 ? 'wish-bag-content-discount-single' : 'wish-bag-content-discount-multiple', {
                  count: discounts.length,
                })}
              </div>
              <div className={classes.price}>
                {loadingSave || loadingApplyDiscount || loadingRemoveDiscount ? (
                  <CircularProgress size={12} />
                ) : discountTotal ? (
                  `- ${currencySymbol}${discountTotal.toFixed(2)}`
                ) : (
                  ''
                )}
              </div>
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {discounts.map((discount) => (
                <Box
                  key={discount.code}
                  sx={{
                    m: '0 8px 8px 0',
                    bgcolor: colors.grey20,
                    p: '1px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '4px',
                  }}
                >
                  <Tooltip title={discount.code}>
                    <Typography noWrap maxWidth={120} sx={{ fontSize: '12px' }}>
                      {discount.code}
                    </Typography>
                  </Tooltip>
                  {discount.isFreeShipping && (
                    <Typography sx={{ fontSize: '12px', color: colors.grey70, ml: '4px' }}>
                      {t('wish-bag-content-discount-free-shipping')}
                    </Typography>
                  )}
                  <div
                    className={`${classes.discountLabelClose} ${!loadingRemoveDiscount && classes.cursorPointer}`}
                    onClick={() => {
                      if (loadingRemoveDiscount || loadingApplyDiscount) return;
                      removeDiscountFromWishBag(discount.code);
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <CloseXIcon color={colors.black} style={{ fontSize: 22 }} />
                    </Box>
                  </div>
                </Box>
              ))}
            </Box>

            <div className={classes.divider} />
            <div className={classes.totalLabel}>
              <div className={classes.price}>{t('wish-bag-content-summary-total')}</div>
              <div className={classes.priceBold}>
                {loadingSave || loadingApplyDiscount || loadingRemoveDiscount ? (
                  <CircularProgress size={12} />
                ) : (
                  `${currencySymbol}${(subTotal - discountTotal).toFixed(2)}`
                )}
              </div>
            </div>
          </div>
        )}
        {canApplyDiscount && (
          <div className={classes.discountInputWrapper}>
            <TextField
              error={discountError}
              helperText={discountError && t('wish-bag-content-summary-discount-input-error')}
              className={classes.discountInput}
              disabled={loadingApplyDiscount || loadingRemoveDiscount}
              label={t('wish-bag-content-summary-discount-input-label')}
              variant="outlined"
              data-testid="discount-code-input"
              value={discountInput}
              onChange={(e) => {
                setDiscountInput(e.target.value);
                if (discountError) setDiscountError(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleApplyDiscount();
              }}
            />
            <div className={classes.discountApplyWrapper}>
              <Button
                size={'small'}
                color={'primary'}
                disabled={!discountInput || loadingApplyDiscount || loadingRemoveDiscount || loadingSave}
                sx={{ '&.Mui-disabled > div': { color: 'rgb(186, 189, 206)!important' } }}
                onClick={handleApplyDiscount}
              >
                <animated.div style={animationDiscountApplyButton}>
                  {t('wish-bag-content-summary-discount-input-apply')}
                </animated.div>
              </Button>
            </div>
          </div>
        )}
        <div
          className={isDiscountApplied ? classes.buttonSendWishBagReminderWDiscount : classes.buttonSendWishBagReminder}
        >
          <ShopifyButton
            type={'primary'}
            size={'regular'}
            fullWidth
            onClick={onSendWishBagReminder}
            disabled={loadingSend}
            loading={loadingSend}
          >
            {t('wish-bag-content-send-wishbag-reminder')}
          </ShopifyButton>
        </div>
      </div>
    </div>
  );
}
