import { colors } from '@amazd/common/static';
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    padding: 16,
  },
  headerContainer: {
    textAlign: 'center',
  },
  headerTitle: {
    color: colors.black,
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 8,
  },
  headerMargin: {
    marginTop: 40,
  },
  headerSubTitle: {
    fontWeight: 400,
    fontSize: 14,
    color: colors.grey80,
    marginBottom: 32,
  },
  buttonSend: {
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 16,
  },
  buttonAnswered: {
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 16,
    color: '#fff !important',
    background: `${colors.green100} !important`,
  },
  resendMessage: {
    color: colors.black,
    fontWeight: 400,
    fontSize: 12,
    marginTop: 8,
  },

  resultsHeading: {
    marginTop: 32,
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 8,
    paddingLeft: 8,
  },
  resultRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colors.black,
    borderRadius: 8,
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 17,
    paddingBottom: 17,
    marginBottom: 8,
  },
  rowScore: {
    fontWeight: 700,
    fontSize: 18,
  },
  greyText: {
    color: colors.grey80,
  },
  rowSuccess: {
    background: colors.green10,
  },
  rowWarning: {
    background: colors.orange10,
  },
  rowDanger: {
    background: colors.red05,
  },
}));
