import { colors } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  root: {
    marginTop: 56,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: '500',
    fontSize: 18,
    lineHeight: '24px',
    marginTop: 16,
    width: 326,
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 4,
    fontWeight: '400',
    color: colors.grey80,
    fontSize: 14,
    lineHeight: '20px',
    width: 326,
    textAlign: 'center',
  },
}));
