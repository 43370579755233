import React, { useEffect } from 'react';

import { Loader } from '@amazd/common/components';
import { useAuthInfo } from '@amazd/common/hooks/auth';
import NPSIcon from '@amazd/common/icons/NPSIcon';
import { hasXdaysPassed } from '@amazd/common/utils/date';
import { reportError } from '@amazd/common/utils/exception-reporter';
import { useTranslation } from '@amazd/common/utils/i18n';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { differenceInCalendarDays, intlFormat, parseISO } from 'date-fns';
import { useAmazd } from 'hooks/useAmazd';
import { useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';

import { CREATE_SURVEY, SURVEYS_BY_AMAZD_ID } from './graphql/survey.graphql';
import useStyles from './styles';
import { SurveyModel } from './survey.types';

export const NUM_SURVEY_DAYS_THRESHOLD = 30;

export function SurveyTab() {
  const classes = useStyles();
  const { channel } = useChatSidebarContext();
  const amazdId = channel?.id as string;
  const { ownUser } = useAuthInfo();

  const { t } = useTranslation();

  const {
    loading: loadingSurveysById,
    data: surveysByIdData,
    error: errorFetchingSurveys,
    refetch: refetchSurveys,
  } = useQuery<{ surveys: SurveyModel[] }>(SURVEYS_BY_AMAZD_ID, {
    variables: { amazdId },
  });

  const [createSurvey, { loading: isCreatingSurvey, error: errorCreatingSurvey }] = useMutation(CREATE_SURVEY);

  const { amazd, loading: loadingAmazd } = useAmazd(amazdId);

  useEffect(() => {
    errorCreatingSurvey && reportError(errorCreatingSurvey, { extraInfo: { amazdId } });
    errorFetchingSurveys && reportError(errorFetchingSurveys, { extraInfo: { amazdId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCreatingSurvey, errorFetchingSurveys]);

  const handleSendSurveyClick = async () => {
    await createSurvey({
      variables: {
        amazdId,
      },
    });
    await refetchSurveys();
  };

  if (loadingSurveysById || loadingAmazd) {
    return (
      <div className={classes.root}>
        <Loader size={100} />
      </div>
    );
  }

  const isCurrentUserTheExpert = () => {
    if (amazd?.expert?.userType?.user?.id === ownUser?.id) return true;

    return false;
  };

  const shopperName = amazd?.owner?.userType?.user?.firstName;

  let sendButton;
  const recentSurvey = findMostRecentSurvey(surveysByIdData?.surveys ?? []);
  if (!recentSurvey || hasXdaysPassed(NUM_SURVEY_DAYS_THRESHOLD, parseISO(recentSurvey.createdAt))) {
    const isDisabled = isCreatingSurvey || !isCurrentUserTheExpert();

    sendButton = (
      <Button
        className={classes.buttonSend}
        disabled={isDisabled}
        type="submit"
        color="primary"
        variant="contained"
        onClick={handleSendSurveyClick}
      >
        {t('survey-send-button')}
      </Button>
    );
  } else {
    const now = new Date().getTime();
    const daysRemaining = NUM_SURVEY_DAYS_THRESHOLD - differenceInCalendarDays(now, parseISO(recentSurvey.createdAt));
    sendButton = (
      <>
        <Button
          className={recentSurvey.score ? classes.buttonAnswered : classes.buttonSend}
          disabled={true}
          type="submit"
          color="primary"
          variant="contained"
        >
          {recentSurvey.score ? t('survey-answered-button') : t('survey-sent-button')}
        </Button>
        <div className={classes.resendMessage}>{t('survey-resend-message', { days: daysRemaining })}</div>
      </>
    );
  }

  const surveysWithScores = surveysByIdData?.surveys?.filter((survey) => survey.score !== null) ?? [];
  const isAtLeastOneSurveyAnswered = surveysWithScores.length;

  let resultsBlock;
  if (isAtLeastOneSurveyAnswered) {
    resultsBlock = (
      <>
        <h4 className={classes.resultsHeading}>{t('survey-results-heading')}</h4>
        {surveysWithScores.map(({ answeredAt, id, score = 0 }) => {
          return (
            <div
              key={id}
              className={clsx(
                classes.resultRow,
                score < 7 && classes.rowDanger,
                score >= 7 && score <= 8 && classes.rowWarning,
                score > 8 && classes.rowSuccess,
              )}
            >
              <span>
                {intlFormat(parseISO(answeredAt ?? ''), {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </span>{' '}
              <span className={classes.rowScore}>
                {score}
                <span className={classes.greyText}>/10</span>
              </span>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        {!isAtLeastOneSurveyAnswered && <NPSIcon style={{ width: 112, height: 112, fill: 'white' }} />}
        <div className={clsx(classes.headerTitle, isAtLeastOneSurveyAnswered && classes.headerMargin)}>
          Net Promoter Score
        </div>
        <div className={classes.headerSubTitle}>{t('survey-subtitle-message', { name: shopperName })}</div>

        {sendButton}
      </div>
      <div>{resultsBlock}</div>
    </div>
  );
}

const findMostRecentSurvey = (surveys: SurveyModel[]) => {
  return surveys[0]; // as items are ordered by createdAt desc, it will always be the first item in the array
};
