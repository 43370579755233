import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    minHeight: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 576,
    padding: '10px 32px',
    backgroundColor: colors.grey20,
  },
  rootCategory: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontWeight: 500,
    fontSize: 14,
    height: 20,
    color: colors.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  textCatalogue: {
    marginLeft: 4,
  },
  previous: {
    color: colors.grey80,
    fontWeight: 400,
  },
}));
