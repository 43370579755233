import { Storage } from '@amazd/common/storage';

export class SearchHistory extends Storage<Array<string>> {
  getHistory(): Array<string> {
    return this.getItem() || [];
  }

  addItemToHistory(item: string) {
    if (item) {
      const history = this.getHistory();
      const newHistory = history.filter((h) => h !== item);
      this.setItem([item, ...newHistory]);
    }
  }

  removeItemFromHistory(item: string) {
    const history = this.getHistory();
    this.setItem(history.filter((h) => h !== item));
  }

  clearHistory() {
    this.removeItem();
  }
}
