import React from 'react';

import { Loader, UserAvatar } from '@amazd/common/components';
import { useAuthInfo } from '@amazd/common/hooks/auth';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Box, Divider, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import Select from 'components/Select';
import { CompanyExpert, useMyCompanyExperts } from 'views/InboxView/useCompanyExperts';

import { getStyles } from './styles';

export default function ExpertPicker({
  onFilterChange,
  selectedFilterOption,
  customValues,
}: {
  onFilterChange: (change: SelectChangeEvent<unknown>, fullRecord?: CompanyExpert) => void;
  selectedFilterOption?: string;
  customValues?: {
    value: string;
    label: string;
  }[];
}) {
  const { ownUserType } = useAuthInfo();
  const { myCompanyExperts } = useMyCompanyExperts({});
  const { t } = useTranslation();
  const styles = getStyles();

  if (!myCompanyExperts) {
    return <Loader size={48} />;
  }

  const handleOnFilterChange = (event: SelectChangeEvent<unknown>) => {
    const fullOption = myCompanyExperts.find((expert) => expert.id === event.target.value);
    onFilterChange(event, fullOption);
  };

  return (
    <Select
      disableUnderline={true}
      variant="standard"
      style={{ width: '100%' }}
      MenuProps={{
        style: { maxHeight: '90vh' },
      }}
      value={selectedFilterOption}
      onChange={handleOnFilterChange}
    >
      <Box sx={styles.header}>
        <span style={{ fontSize: 16 }}>{t('inbox-filter-header')}</span>
      </Box>
      <Divider style={{ width: '100%' }} />
      {customValues?.map((custom) => (
        <MenuItem value={custom.value} key={custom.value} style={{ padding: '8px 16px' }}>
          <Typography noWrap variant="body1">
            {custom.label}
          </Typography>
        </MenuItem>
      ))}
      {!!customValues?.length && <Divider style={{ width: '100%', margin: 16 }} />}
      {myCompanyExperts &&
        myCompanyExperts
          .filter((x) => x.id !== ownUserType?.id)
          .map((expert) => {
            return (
              <MenuItem value={expert.id} key={expert.id} style={{ padding: '8px 16px' }}>
                <UserAvatar user={expert} size={24} />
                <Box sx={styles.nameContainer}>
                  <Typography
                    noWrap
                    variant="body1"
                    style={{ maxWidth: 260 }}
                  >{`${expert.firstName} ${expert.lastName}`}</Typography>
                </Box>
              </MenuItem>
            );
          })}
    </Select>
  );
}
