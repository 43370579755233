import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ChevronRightIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      d="M10 16L14 12L10 8"
      stroke={color}
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronRightIcon;
