import React, { useEffect, useState } from 'react';

import { useTranslation } from '@amazd/common/utils/i18n';
import { RecentSearchCard } from 'components/RecentSearchCard';
import { shopifyCatalogueSearchHistory } from 'components/Shopify/cataloguePopup/searchProduct/searchHistory';

import useStyles from './styles';

export interface SearchProductRecentSearchesProps {
  onSearchItem: (item: string) => void;
}

export const SearchProductRecentSearches = ({ onSearchItem }: SearchProductRecentSearchesProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [historyItems, setHistoryItems] = useState<Array<string>>([]);

  const getHistory = () => {
    setHistoryItems(shopifyCatalogueSearchHistory.getHistory());
  };

  useEffect(() => {
    getHistory();
  }, []);

  const onClearHistory = () => {
    shopifyCatalogueSearchHistory.clearHistory();
    getHistory();
  };

  if (!historyItems.length) return null;

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <div className={classes.recentSearchesText}>{t('product-catalogue-search-recent-searches')}</div>
        <div className={classes.clearAllButton} onClick={onClearHistory}>
          {t('product-catalogue-search-clear-all')}
        </div>
      </div>
      <div className={classes.recentSearches}>
        {historyItems.map((item, index) => (
          <RecentSearchCard
            key={index.toString()}
            title={item}
            onSearch={() => {
              onSearchItem(item);
            }}
            onDelete={() => {
              shopifyCatalogueSearchHistory.removeItemFromHistory(item);
              getHistory();
            }}
          />
        ))}
      </div>
    </div>
  );
};
