import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const TrailingDownIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...rest}>
    <path
      d="M6.66699 8.3335L10.0003 11.6668L13.3337 8.3335"
      stroke={color}
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default TrailingDownIcon;
