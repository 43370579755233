import React from 'react';

import { CircularProgress } from '@mui/material';

import useStyles from './styles';

export interface ShopifyButtonProps {
  type?: 'primary' | 'secondary';
  size?: 'small' | 'regular';
  fullWidth?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export const ShopifyButton = ({
  onClick,
  type,
  size,
  fullWidth,
  disabled,
  loading,
  children,
  ...rest
}: ShopifyButtonProps) => {
  const classes = useStyles();

  const getTypeClass = () => {
    switch (type) {
      case 'secondary':
        return classes.typeSecondary;
      default:
        return classes.typePrimary;
    }
  };

  const getSizeClass = () => {
    switch (size) {
      case 'regular':
        return classes.sizeRegular;
      default:
        return classes.sizeSmall;
    }
  };

  const handleOnClick = () => {
    if (!disabled && onClick) onClick();
  };

  return (
    <div className={`${classes.root}`} onClick={handleOnClick}>
      <div
        className={`${classes.button} ${getTypeClass()} ${getSizeClass()} ${fullWidth && classes.fullWidth} ${
          disabled && classes.disabled
        }`}
        {...rest}
      >
        {loading && <CircularProgress size={16} color={'inherit'} style={{ marginRight: 8 }} />}
        {children}
      </div>
    </div>
  );
};
