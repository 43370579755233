import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const InfoIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 18 18" fill="none" {...rest}>
    <circle
      cx="9.00006"
      cy="9.00006"
      r="6.0025"
      stroke={color}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.33301 11.3344H9.87321" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.10585 11.334V8.49951H8.33887"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.06661 6.49705C9.06661 6.58913 8.99196 6.66378 8.89988 6.66378C8.80779 6.66378 8.73314 6.58913 8.73314 6.49705C8.73314 6.40496 8.80779 6.33031 8.89988 6.33031"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.90001 6.33018C8.99209 6.33018 9.06674 6.40483 9.06674 6.49692"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default InfoIcon;
