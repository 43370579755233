import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ChevronLeftIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 32 32" fill="none" {...rest}>
    <path
      d="M18.6668 10.6667L13.3335 16.0001L18.6668 21.3334"
      stroke={color}
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronLeftIcon;
