export const ProductShortFragment = `
  id
  title
  sku
  stockStatus
  featuredImage {
    id
    url
  }
  thumbnailImage {
    id
    url
  }
  openUrl
  availableForSale
  publishedOnCurrentPublication
  totalInventory
  tracksInventory
  priceRangeV2 {
    minVariantPrice {
      currencyCode
      amount
    }
    maxVariantPrice {
      currencyCode
      amount
    }
  }
  categories {
    items {
      id
      title
    }
  }
`;

export const ProductFragment = `
  ${ProductShortFragment}
  options {
    id
    name
    position
    values
  }
  variants {
    id
    title
    sku
    stockStatus
    openUrl
    availableForSale
    image {
      id
      url
    }
    price
    inventoryQuantity
    selectedOptions {
      id
      name
      value
      optionId
    }
  }
`;
