import React, { useState } from 'react';

import TrailingDownIcon from '@amazd/common/icons/TrailingDownIcon';
import { ShopProductOption } from '@amazd/common/types/shop.types';
import { useSpring, animated, useSpringRef, useChain } from '@react-spring/web';
import { useProduct } from 'components/Shopify/cataloguePopup/productVariantPicker/hooks/useProduct';
import { colors } from 'styles/theme';

import useStyles from './styles';

export interface SelectProductOptionProps {
  value: string;
  optionWithValues: ShopProductOption;
  onToggle?: (value: boolean) => void;
}

export const SelectProductOption = ({ value, optionWithValues, onToggle }: SelectProductOptionProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const toggle = () => {
    if (onToggle) onToggle(!open);
    setOpen(!open);
  };

  const animationRootWrapperRef = useSpringRef();
  const animationRootWrapper = useSpring({
    ref: animationRootWrapperRef,
    position: open ? 'absolute' : 'relative',
    top: open ? 0 : undefined,
    backgroundColor: colors.white,
    margin: '4px 0px',
  });

  const animationOptionWrapperRef = useSpringRef();
  const animationOptionWrapper = useSpring({
    ref: animationOptionWrapperRef,
    height: open ? undefined : 0,
    width: '100%',
    overflow: open ? 'visible' : 'hidden',
    opacity: open ? 1 : 0,
  });

  useChain(
    open ? [animationRootWrapperRef, animationOptionWrapperRef] : [animationOptionWrapperRef, animationRootWrapperRef],
    [open ? 0 : 0, open ? 0 : 0],
  );

  const { setSelectedOption } = useProduct();

  const { name, values: options } = optionWithValues;

  const onSelectItem = (item: string) => {
    if (setSelectedOption) setSelectedOption(name as string, item);
    toggle();
  };

  if (!options) return null;

  return (
    <animated.div style={animationRootWrapper as any} className={`${open && classes.rootOpen}`}>
      <div className={`${classes.select} ${open && classes.selectOpen}`} onClick={toggle}>
        <div>
          <div className={classes.label}>{name}</div>
          <div className={classes.selectedValue}>{value}</div>
        </div>
        <div className={classes.selectedValue}>
          <TrailingDownIcon color={colors.grey70} style={{ fontSize: 20 }} />
        </div>
      </div>
      <animated.div style={animationOptionWrapper}>
        <div className={classes.optionsWrapper}>
          {options.map((item: string, index) => (
            <div key={index.toString()} className={classes.option} onClick={() => onSelectItem(item)}>
              {item}
            </div>
          ))}
        </div>
      </animated.div>
    </animated.div>
  );
};
