import React, { HTMLAttributes, useState } from 'react';

import { useStyles } from './styles';

export interface SegmentPillProps {
  options: Array<string>;
  defaultSelected?: string;
  className?: string;
  onChange?: (value: string, index: number) => void;
}

export function SegmentPill({ options, defaultSelected, className, onChange }: SegmentPillProps) {
  const classes = useStyles();

  const [selected, setSelected] = useState(defaultSelected);

  const handleOnChange = (option: string) => {
    setSelected(option);
    if (onChange) onChange(option, options.indexOf(option));
  };

  return (
    <div className={`${classes.root} ${className}`}>
      {options.map((option) => (
        <Pill key={option} onClick={() => handleOnChange(option)} title={option} selected={option === selected} />
      ))}
    </div>
  );
}

interface PillProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  selected?: boolean;
}
function Pill({ title, selected, ...rest }: PillProps) {
  const classes = useStyles();

  return (
    <div className={`${classes.pill} ${selected && classes.pillSelected}`} {...rest}>
      {title}
    </div>
  );
}
