import React from 'react';

import AlarmIcon from '@amazd/common/icons/AlarmIcon';
import DeleteXIcon from '@amazd/common/icons/DeleteXIcon';
import { colors } from 'styles/theme';

import useStyles from './styles';

export interface RecentSearchCardProps {
  title?: string;
  onSearch: () => void;
  onDelete: () => void;
}

export const RecentSearchCard = ({ title, onSearch, onDelete }: RecentSearchCardProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.left} onClick={onSearch}>
        <AlarmIcon color={colors.grey70} style={{ fontSize: 16 }} />
        <div className={classes.text}>{title}</div>
      </div>
      <div className={classes.closeIcon} onClick={onDelete}>
        <DeleteXIcon color={colors.grey80} style={{ fontSize: 24 }} />
      </div>
    </div>
  );
};
