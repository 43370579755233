import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    margin: '6px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  text: {
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
    margin: '0px 8px',
  },
  closeIcon: {
    cursor: 'pointer',
  },
}));
