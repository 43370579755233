import React, { useEffect } from 'react';

import Loader from '@amazd/common/components/Loader';
import { animated, useTrail } from '@react-spring/web';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { ProductCatalogueCard } from 'components/Shopify/cataloguePopup/productCatalogue/catalogueCard';
import { ShopifyProductList } from 'components/Shopify/cataloguePopup/productList';
import { ProductCatalogueSubHeader } from 'components/Shopify/cataloguePopup/subHeader';

import { useCollectionByIdQuery } from './hooks/useCollectionByIdQuery';
import useStyles from './styles';

const SCROLL_THRESHOLD_PX = 50;

export const ProductCatalogueDetail = () => {
  const classes = useStyles();

  const { page } = useShopifyCataloguePopupContext();

  const open = page === 'catalogueDetail';
  const [trail, api] = useTrail(1, () => ({
    opacity: 0,
    from: { opacity: 0 },
  }));

  useEffect(() => {
    if (open)
      setTimeout(() => {
        api.start({ opacity: 1 });
      }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { items, children, loading, loadNextPage } = useCollectionByIdQuery();

  const handleScroll = (e: any) => {
    const reached = e.target.scrollHeight - e.target.scrollTop - SCROLL_THRESHOLD_PX <= e.target.clientHeight;
    if (reached) loadNextPage();
  };

  return (
    <>
      <ProductCatalogueSubHeader />
      {trail.map((style, index) => (
        <animated.div key={index.toString()} className={classes.animatedContainer} style={style}>
          <div className={classes.body} onScroll={handleScroll}>
            {!!children?.length && (
              <div className={classes.children}>
                {children.map((item) => (
                  <ProductCatalogueCard key={item.id} item={item} loading={loading} />
                ))}
              </div>
            )}
            {!!items?.length && <ShopifyProductList items={items} />}
            {loading && <Loader size={100} />}
          </div>
        </animated.div>
      ))}
    </>
  );
};
