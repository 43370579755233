import { SxProps } from '@mui/material';

export const getStyles = (): Record<string, SxProps> => ({
  header: {
    minHeight: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  nameContainer: {
    paddingLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
});
