import { useAuthInfo } from '@amazd/common/hooks/auth';
import { Amazd } from '@amazd/common/redux/types';

export const useAmazdAppointment = (amazd?: Amazd) => {
  const { ownUserType } = useAuthInfo();

  const appointment = amazd?.appointments?.find((appointment) => {
    const startsAt = new Date(appointment.startingAt);
    const endsAt = new Date(startsAt.getTime() + appointment.duration * 60000);
    const currentData = new Date();
    const overdue = currentData > endsAt;
    return !overdue;
  });

  const isOwnUserExpertInAmazd = amazd?.expert.userType.id === ownUserType?.id;
  if (appointment && ownUserType) {
    if (isOwnUserExpertInAmazd) {
      appointment.receiverAsUserTypeId === ownUserType.id;
    }
    appointment.creatorAsUserTypeId === ownUserType.id;
  }

  return { appointment };
};
