import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      padding: 25,
      maxWidth: 680,
    },
  },
  cancelButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  content: {
    minWidth: 600,
    padding: 0,
    paddingLeft: 15,
    paddingRight: 15,
  },
  descriptionInput: {
    padding: 0,
    marginTop: 16,
    width: '100%',
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
    '& textarea': {
      padding: '12px !important',
    },
  },
}));
