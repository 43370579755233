/* eslint-disable quote-props */
import { colors } from '@amazd/common/static';
import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    height: 60,
    display: 'flex',
    alignItems: 'flex-start',
    background: 'white',
    zIndex: theme.zIndex.drawer + 1,
    flexDirection: 'column',
    borderBottom: '1px solid',
    borderBottomColor: colors.grey40,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 15,
  },
  userInfo: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 200,
  },
  assignToSection: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
  assignToSectionValue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  assignToSectionValueArrowIcon: {
    marginLeft: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  companyHandle: {
    fontSize: '14px',
    color: colors.amazdGrey3,
  },
  customerSidebarIcon: {
    fontSize: '14px',
    marginLeft: 6,
    marginRight: 6,
    display: 'flex',
    flexDirecton: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customerSidebarIconDivider: {
    width: 1,
    height: 24,
    backgroundColor: colors.grey50,
    marginLeft: 16,
    marginRight: 14,
  },
}));
