import { colors } from '@amazd/common/static';
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  leftWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
  },
  label: {
    fontWeight: 400,
    fontSize: 14,
    color: colors.grey80,
  },
  valueWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    width: 160,
  },
  value: {
    fontWeight: 500,
    fontSize: 14,
    color: colors.black,
    textAlign: 'right',
    marginTop: 2,
  },
}));
