import { gql, useQuery } from '@apollo/client';

const mutation = gql`
  query amazdNote($amazdId: String!) {
    amazdNote(amazdId: $amazdId) {
      amazdId
      note
    }
  }
`;

export const useAmazdNote = (amazdId?: string) => {
  const { data, error, loading } = useQuery(mutation, {
    variables: {
      amazdId,
    },
    skip: !amazdId,
  });
  return {
    amazdNote: data?.amazdNote?.note || '',
    error,
    loading,
  };
};
