import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CloseCircleIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...rest}>
    <path
      d="M12.8887 8.172C13.1816 7.8791 13.1816 7.40423 12.8887 7.11134C12.5958 6.81844 12.1209 6.81844 11.828 7.11134L12.8887 8.172ZM7.11134 11.828C6.81844 12.1209 6.81844 12.5958 7.11134 12.8887C7.40423 13.1816 7.8791 13.1816 8.172 12.8887L7.11134 11.828ZM11.828 12.8887C12.1209 13.1816 12.5958 13.1816 12.8887 12.8887C13.1816 12.5958 13.1816 12.1209 12.8887 11.828L11.828 12.8887ZM8.172 7.11134C7.8791 6.81844 7.40423 6.81844 7.11134 7.11134C6.81844 7.40423 6.81844 7.8791 7.11134 8.172L8.172 7.11134ZM11.828 7.11134L7.11134 11.828L8.172 12.8887L12.8887 8.172L11.828 7.11134ZM12.8887 11.828L8.172 7.11134L7.11134 8.172L11.828 12.8887L12.8887 11.828ZM10 16.75C6.2647 16.75 3.25 13.7288 3.25 10H1.75C1.75 14.5562 5.4353 18.25 10 18.25V16.75ZM3.25 10C3.25 6.2647 6.27122 3.25 10 3.25V1.75C5.44377 1.75 1.75 5.4353 1.75 10H3.25ZM10 3.25C13.7283 3.25 16.75 6.27171 16.75 10H18.25C18.25 5.44329 14.5567 1.75 10 1.75V3.25ZM16.75 10C16.75 13.7283 13.7283 16.75 10 16.75V18.25C14.5567 18.25 18.25 14.5567 18.25 10H16.75Z"
      fill={color}
    />
  </SvgIcon>
);

export default CloseCircleIcon;
