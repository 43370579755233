import React from 'react';

import { Switch, SwitchProps } from '@mui/material';

import useStyles from './styles';

const IOSSwitch = React.forwardRef(({ className, ...props }: SwitchProps, ref: any) => {
  const classes = useStyles();

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      className={className}
      {...props}
      ref={ref}
    />
  );
});

IOSSwitch.displayName = `IOSSwitch`;
export default IOSSwitch;
