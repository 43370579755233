import { useCallback, useEffect, useMemo, useState } from 'react';

import { ShopProductVariant } from '@amazd/common/types/shop.types';

import { getOptions, getSelectedVariant } from './helpers';
import { SelectedOptions, ProductOptionsHookValue } from './types';

/**
 * The `useProductOptions` hook returns an object that enables you to keep track of the
 * selected variant and/or selling plan state, as well as callbacks for modifying the state.
 */
export function useProductOptions({
  variants,
  initialVariant,
}: {
  /** The product's `Variant`. */
  variants?: ShopProductVariant[];
  initialVariant?: ShopProductVariant | null;
}): ProductOptionsHookValue {
  // All the options available for a product, based on all the variants
  const options = useMemo(() => getOptions(variants || []), [variants]);

  /**
   * Track the selectedVariant within the hook. If `initialVariant`
   * is passed, use that as an initial value.
   */
  const [selectedVariant, setSelectedVariant] = useState<ShopProductVariant | undefined | null>(initialVariant);

  /**
   * Track the selectedOptions within the hook. If a `initialVariant`
   * is passed, use that to select initial options.
   */
  const [selectedOptions, setSelectedOptions] = useState(
    selectedVariant?.selectedOptions
      ? selectedVariant.selectedOptions.reduce((memo, optionSet) => {
          memo[optionSet?.name ?? ''] = optionSet?.value ?? '';
          return memo;
        }, {} as SelectedOptions)
      : {},
  );

  /**
   * When the initialVariant changes, we need to make sure we
   * update the selected variant and selected options. If not,
   * then the selected variant and options will reference incorrect
   * values.
   */
  useEffect(() => {
    setSelectedVariant(initialVariant);

    const selectedOptions = selectedVariant?.selectedOptions
      ? selectedVariant.selectedOptions.reduce((memo, optionSet) => {
          memo[optionSet?.name ?? ''] = optionSet?.value ?? '';
          return memo;
        }, {} as SelectedOptions)
      : {};
    setSelectedOptions(selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialVariant, variants]);

  /**
   * Allow the developer to select an option.
   */
  const setSelectedOption = useCallback(
    (name: string | undefined, value: string | undefined) => {
      const newSelectedOptions = {
        ...selectedOptions,
        [name || '']: value || '',
      };

      setSelectedOptions(newSelectedOptions);
    },
    [selectedOptions],
  );

  useEffect(() => {
    /**
     * When selected options change, select the correct variant.
     */
    const variant = variants && getSelectedVariant(variants, selectedOptions);
    if (variant) setSelectedVariant(variant);
  }, [variants, selectedOptions]);

  const isOptionInStock = useCallback(
    (option: string | undefined, value: string | undefined) => {
      if (!variants) {
        console.warn('Using `isOptionInStock` without available `variants`');
        return false;
      }
      const proposedVariant = getSelectedVariant(variants, {
        ...selectedOptions,
        ...{ [option || '']: value || '' },
      });

      return proposedVariant?.availableForSale ?? true;
    },
    [selectedOptions, variants],
  );

  return {
    variants,
    options,
    selectedVariant,
    setSelectedVariant,
    selectedOptions,
    setSelectedOption,
    setSelectedOptions,
    isOptionInStock,
  };
}
