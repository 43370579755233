import { gql, useQuery } from '@apollo/client';

export interface DataModel {
  myCompanyExperts: CompanyExpert[];
}

export interface CompanyExpert {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly avatar: string;
}

export const myCompanyExpertsQuery = gql`
  query myCompanyExperts {
    myCompanyExperts {
      id
      firstName
      lastName
      avatar
    }
  }
`;

export const useMyCompanyExperts = (options: { skip?: boolean }) => {
  const { data, loading, error } = useQuery<DataModel>(myCompanyExpertsQuery, {
    ...options,
  });
  return { myCompanyExperts: data?.myCompanyExperts, loading, error };
};
