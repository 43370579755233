import React from 'react';

import useStyles from './styles';

export interface LineItemAlertProps {
  title: string;
}

export function LineItemAlert({ title }: LineItemAlertProps) {
  const classes = useStyles();

  return <div className={classes.root}>{title}</div>;
}
