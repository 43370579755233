import { Select as BaseSelect } from '@mui/material';
import { styled } from '@mui/system';

const SelectV2 = styled(BaseSelect)({
  display: 'flex',
  height: 40,
  '& .MuiSelect-select.MuiInputBase-input': {
    padding: '0 !important',
    paddingLeft: '8px !important',
    paddingRight: '16px !important',
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  borderRadius: '32px !important',
  backgroundColor: '#F3F4FC',
  '& .MuiSelect-select:focus': {
    backgroundColor: 'unset',
  },
});

export default SelectV2;
