import React, { ReactNode } from 'react';

import { ShopProduct, ShopProductVariant } from '@amazd/common/types/shop.types';

import { ProductContext } from './context';
import { ProductOptionsProvider } from './ProductOptionsProvider';

export interface ProductProviderProps {
  /** A `ReactNode` element. */
  children: ReactNode;
  /** A [Product object] */
  data: ShopProduct;

  initialVariant?: ShopProductVariant | null;
}

/**
 * The `ProductProvider` component sets up a context with product details. Descendents of
 * this component can use the `useProduct` hook.
 */
export function ProductProvider({ children, data: product, initialVariant }: ProductProviderProps) {
  return (
    <ProductContext.Provider value={product}>
      <ProductOptionsProvider initialVariant={initialVariant}>{children}</ProductOptionsProvider>
    </ProductContext.Provider>
  );
}
