import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ClockIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 19 18" fill="none" {...rest}>
    <path
      d="M6.63101 2.06714C6.24835 2.22571 6.06668 2.66446 6.22525 3.04712C6.38382 3.42978 6.82257 3.61144 7.20523 3.45288L6.63101 2.06714ZM15.7306 6.41251L15.0374 6.69883L15.0376 6.69936L15.7306 6.41251ZM12.0781 15.225L12.3644 15.9182L12.365 15.918L12.0781 15.225ZM3.26112 11.5725L3.95431 11.2862L3.95378 11.2849L3.26112 11.5725ZM7.19942 3.44892C7.58238 3.29107 7.76487 2.85266 7.60703 2.4697C7.44918 2.08674 7.01077 1.90425 6.62781 2.0621L7.19942 3.44892ZM10.0329 5.98876C10.0329 5.57454 9.69708 5.23876 9.28287 5.23876C8.86866 5.23876 8.53287 5.57454 8.53287 5.98876H10.0329ZM9.28287 9.47626H8.53287C8.53287 9.7381 8.66942 9.98098 8.89313 10.117L9.28287 9.47626ZM11.6306 11.782C11.9845 11.9973 12.4459 11.8849 12.6612 11.531C12.8764 11.1771 12.764 10.7157 12.4101 10.5005L11.6306 11.782ZM7.20523 3.45288C10.2656 2.18474 13.771 3.63279 15.0374 6.69883L16.4238 6.12619C14.8402 2.29223 10.4557 0.482279 6.63101 2.06714L7.20523 3.45288ZM15.0376 6.69936C16.3042 9.75916 14.851 13.2655 11.7913 14.532L12.365 15.918C16.1902 14.3345 18.007 9.95085 16.4236 6.12565L15.0376 6.69936ZM11.7918 14.5318C8.72346 15.7992 5.21769 14.3447 3.95431 11.2862L2.56793 11.8588C4.14855 15.6854 8.53278 17.5008 12.3644 15.9182L11.7918 14.5318ZM3.95378 11.2849C2.68022 8.21769 4.13355 4.71259 7.19942 3.44892L6.62781 2.0621C2.79369 3.64243 0.977017 8.02733 2.56846 11.8601L3.95378 11.2849ZM8.53287 5.98876V9.47626H10.0329V5.98876H8.53287ZM8.89313 10.117L11.6306 11.782L12.4101 10.5005L9.67261 8.83547L8.89313 10.117Z"
      fill={color}
    />
  </SvgIcon>
);

export default ClockIcon;
