import React from 'react';

import { useTranslation } from '@amazd/common/utils/i18n';
import { Skeleton } from '@mui/material';
import { InfoCard } from 'views/InboxView/ChatSidebar/OverviewTab/card';

import useStyles from './styles';

interface LastOrderCardProps {
  loading?: boolean;
  createdAt: Date | string;
  priceStr?: string;
  orderName?: string;
  orderId?: string;
}

export function LastOrderCard({ loading, createdAt, priceStr, orderName, orderId }: LastOrderCardProps) {
  const { t, formatDate } = useTranslation();

  const classes = useStyles();

  const getFormattedOrderId = () => {
    const orderIdArr = orderId?.split('/');
    if (orderIdArr && orderIdArr.length) return orderIdArr[orderIdArr.length - 1];
  };

  return (
    <InfoCard title={t('customer-sidebar-last-order')} loading={loading}>
      <div>
        <div className={classes.top}>
          <span className={classes.date}>
            {loading ? <Skeleton width={105} /> : formatDate(createdAt, 'MMM dd, yyyy')}
          </span>
          <span className={classes.price}>{loading ? <Skeleton width={40} /> : priceStr}</span>
        </div>
        <div className={classes.bottom}>
          <div className={classes.labelWrapper}>
            <span className={classes.label}>{loading ? <Skeleton width={41} /> : orderName}</span>
          </div>
          <div className={`${classes.labelWrapper} ${classes.labelIdWrapper}`}>
            <span className={classes.label}>
              {loading ? (
                <Skeleton width={98} />
              ) : (
                t('customer-sidebar-last-order-id', {
                  orderId: getFormattedOrderId(),
                })
              )}
            </span>
          </div>
        </div>
      </div>
    </InfoCard>
  );
}
