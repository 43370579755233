import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const InboxSearchEmptySvg: React.FC<any> = (props): React.ReactElement => (
  <SvgIcon viewBox="0 0 112 112" fill="none" {...props}>
    <path
      d="M78.546 85.75H32.6397C30.6135 85.75 28.6703 84.9451 27.2376 83.5124C25.8049 82.0797 25 80.1365 25 78.1103V42.1397C25 41.1364 25.1976 40.143 25.5815 39.2161C25.9655 38.2892 26.5282 37.447 27.2376 36.7376C28.6703 35.3049 30.6135 34.5 32.6397 34.5H78.546C79.5493 34.5 80.5427 34.6976 81.4696 35.0815C82.3965 35.4655 83.2387 36.0282 83.9481 36.7376C84.6575 37.447 85.2202 38.2892 85.6041 39.2161C85.9881 40.143 86.1857 41.1364 86.1857 42.1397V78.1103C86.1857 80.1365 85.3808 82.0797 83.9481 83.5124C82.5153 84.9451 80.5722 85.75 78.546 85.75V85.75Z"
      stroke="#BABDCE"
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31 34.6982V31.0833C31 29.271 31.7199 27.5329 33.0014 26.2514C34.2829 24.9699 36.021 24.25 37.8333 24.25H73.4862C75.2986 24.25 77.0366 24.9699 78.3181 26.2514C79.5996 27.5329 80.3196 29.271 80.3196 31.0833V34.6982"
      stroke="#BABDCE"
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.834 48V50.3333"
      stroke="#BABDCE"
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.166 48V50.3333"
      stroke="#BABDCE"
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41 67C41 67 46.6271 61 56 61C65.3771 61 71 67 71 67"
      stroke="#BABDCE"
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="path-6-outside-1_2113_118430"
      maskUnits="userSpaceOnUse"
      x="62"
      y="62"
      width="42"
      height="42"
      fill="black"
    >
      <rect fill="white" x="62" y="62" width="42" height="42" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.9989 72.2891C98.7177 78.008 98.7177 87.28 92.9989 92.9989C87.28 98.7177 78.008 98.7177 72.2891 92.9989C66.5703 87.28 66.5703 78.008 72.2891 72.2891C78.008 66.5703 87.28 66.5703 92.9989 72.2891"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.9989 72.2891C98.7177 78.008 98.7177 87.28 92.9989 92.9989C87.28 98.7177 78.008 98.7177 72.2891 92.9989C66.5703 87.28 66.5703 78.008 72.2891 72.2891C78.008 66.5703 87.28 66.5703 92.9989 72.2891"
      fill="white"
    />
    <path
      d="M97.2415 68.0465C94.8984 65.7033 91.0994 65.7033 88.7562 68.0465C86.4131 70.3896 86.4131 74.1886 88.7562 76.5318L97.2415 68.0465ZM88.7562 76.5318C91.0994 78.8749 94.8984 78.8749 97.2415 76.5318C99.5847 74.1886 99.5847 70.3896 97.2415 68.0465L88.7562 76.5318ZM88.7562 76.5318C92.1319 79.9075 92.1319 85.3805 88.7562 88.7562L97.2415 97.2415C105.303 89.1795 105.303 76.1085 97.2415 68.0465L88.7562 76.5318ZM88.7562 88.7562C85.3805 92.1319 79.9075 92.1319 76.5318 88.7562L68.0465 97.2415C76.1085 105.303 89.1795 105.303 97.2415 97.2415L88.7562 88.7562ZM76.5318 88.7562C73.1561 85.3805 73.1561 79.9075 76.5318 76.5318L68.0465 68.0465C59.9845 76.1085 59.9845 89.1795 68.0465 97.2415L76.5318 88.7562ZM76.5318 76.5318C79.9075 73.1561 85.3805 73.1561 88.7562 76.5318L97.2415 68.0465C89.1795 59.9845 76.1085 59.9845 68.0465 68.0465L76.5318 76.5318Z"
      fill="white"
      mask="url(#path-6-outside-1_2113_118430)"
    />
    <path
      d="M99.3349 99.3332L93.6582 93.6565"
      stroke="#BABDCE"
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.9989 72.2891C98.7177 78.008 98.7177 87.28 92.9989 92.9989C87.28 98.7177 78.008 98.7177 72.2891 92.9989C66.5703 87.28 66.5703 78.008 72.2891 72.2891C78.008 66.5703 87.28 66.5703 92.9989 72.2891"
      stroke="#BABDCE"
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="94" cy="39" r="2" fill="#FFB13F" />
    <circle cx="15" cy="56" r="3" fill="#FF0066" />
    <path
      d="M52 14C52 16.2091 50.2091 18 48 18C45.7909 18 44 16.2091 44 14C44 11.7909 45.7909 10 48 10C50.2091 10 52 11.7909 52 14Z"
      fill="#7E05FE"
    />
  </SvgIcon>
);

export default InboxSearchEmptySvg;
