import { Fragment } from 'react';

import InfoIcon from '@amazd/common/icons/InfoIcon';
import PlaceholderImageIcon from '@amazd/common/icons/PlaceholderImageIcon';
import { colors } from '@amazd/common/static';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Avatar, Grid, Link, Skeleton, Typography, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { VisitedPage } from 'views/InboxView/ChatSidebar/OverviewTab/hooks/useCustomerLastVisitedPages';

type Props = {
  loading: boolean;
  pages: VisitedPage[];
};

export function VisitedPages({ loading, pages }: Props) {
  const { t } = useTranslation();
  return (
    <Box py="16px">
      <Box ml="8px">
        {loading ? (
          <Skeleton width={50} />
        ) : (
          <Tooltip title={t('last-pages-visited-tooltip')}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                color: colors.black,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {t('last-pages-visited-title')}
              <InfoIcon color={colors.grey60} style={{ fontSize: 20, marginLeft: 6 }} />
            </Typography>
          </Tooltip>
        )}
      </Box>
      <Box sx={{ marginTop: '8px' }}>
        {loading ? (
          <Skeleton width={50} />
        ) : pages.length > 0 ? (
          pages.map(({ title, url, visitedAt, favicon, img }) => (
            <Fragment key={visitedAt}>
              <Grid
                container
                sx={{
                  padding: '16px',
                  borderRadius: '8px',
                  backgroundColor: colors.grey20,
                  mb: '8px',
                  ':last-child': { mb: 0 },
                }}
              >
                {favicon && (
                  <Grid xs={1} item>
                    <Avatar
                      variant="square"
                      sx={{ width: '16px', height: '16px', background: 'white', borderRadius: '5px', mt: '6px' }}
                      src={favicon}
                    />
                  </Grid>
                )}
                <Grid xs={9 + (favicon ? 0 : 1)} sx={{ pr: '16px' }} item>
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: '2px' }}>
                    <Link
                      sx={{ display: 'block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                      href={url}
                      target="_blank"
                      rel="noopener"
                      underline="none"
                    >
                      {url}
                    </Link>
                  </Typography>
                  <Typography
                    sx={{
                      mt: '4px',
                      color: colors.grey80,
                      fontSize: '12px',
                    }}
                  >
                    {format(new Date(visitedAt), "MMM d',' yyyy 'at' k:m")}
                  </Typography>
                </Grid>
                <Grid xs={2} item>
                  {img ? (
                    <Avatar
                      variant="square"
                      sx={{ width: '56px', height: '56px', background: 'white', borderRadius: '5px', ml: '-4px' }}
                      src={img}
                    />
                  ) : (
                    <Avatar
                      variant="square"
                      sx={{
                        width: '56px',
                        height: '56px',
                        background: 'white',
                        borderRadius: '5px',
                        ml: '-4px',
                        '& .MuiSvgIcon-root': {
                          width: '56px',
                          height: '56px',
                        },
                      }}
                    >
                      <PlaceholderImageIcon />
                    </Avatar>
                  )}
                </Grid>
              </Grid>
            </Fragment>
          ))
        ) : (
          <Typography
            sx={{
              padding: '16px',
              borderRadius: '8px',
              backgroundColor: colors.grey20,
              color: colors.grey80,
              fontSize: '14px',
            }}
          >
            {t('last-pages-visited-empty')}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
