import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
    width: 576,
    height: 424,
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
    marginBottom: 8,
  },
  body: {
    // height: 'calc(100% - 48px)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100px',
    height: '100%',
  },
}));
