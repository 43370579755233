import React from 'react';

import InfoIcon from '@amazd/common/icons/InfoIcon';
import { colors } from 'styles/theme';

import { useStyles } from './styles';

export interface AlertProps {
  type?: 'info';
  message: string;
}

export function Alert({ type = 'info', message }: AlertProps) {
  const classes = useStyles();

  const getColorByType = () => {
    switch (type) {
      case 'info':
        return colors.blue120;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <InfoIcon color={getColorByType()} style={{ fontSize: 16 }} />
      </div>
      <span className={classes.message} style={{ color: getColorByType() }}>
        {message}
      </span>
    </div>
  );
}
