import React from 'react';

import { Skeleton } from '@mui/material';

import useStyles from './styles';

export interface InfoItemProps {
  valueCurrency?: string;
  value: number | string | undefined;
  label: string;
  loading?: boolean;
}

export function InfoItem({ valueCurrency, value, label, loading }: InfoItemProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.valueWrapper}>
        <span className={classes.valueCurrency}>{loading ? <Skeleton width={11} /> : valueCurrency}</span>
        <span className={classes.value}>{loading ? <Skeleton width={18} /> : value}</span>
      </div>
      <div>
        <span className={classes.label}>{loading ? <Skeleton width={80} /> : label}</span>
      </div>
    </div>
  );
}
