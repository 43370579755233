import React from 'react';

import { animated, useSpring } from '@react-spring/web';
import { useScreenSize } from 'hooks/useScreenSize';
import { defaultTheme, screenSize1280 } from 'styles/theme';

import useStyles, { CUSTOMER_SIDEBAR_WIDTH } from './styles';

interface AnimatedCustomerSidebar {
  showSideBar: boolean | undefined;
  setShowSideBar: (value: boolean) => void;
  animationPlaying: boolean | undefined;
  setAnimationPlaying: (value: boolean) => void;
  children: React.ReactNode;
}

export function AnimatedCustomerSidebar(props: AnimatedCustomerSidebar) {
  const size = useScreenSize();
  if (size.x >= screenSize1280) return <AnimatedCustomerSidebarBiggerThan1280 {...props} />;
  return <AnimatedCustomerSidebarSmallerThan1280 {...props} />;
}

type AnimatedCustomerSidebarBiggerThan1280Props = AnimatedCustomerSidebar;

function AnimatedCustomerSidebarBiggerThan1280({
  showSideBar,
  children,
  animationPlaying,
  setAnimationPlaying,
}: AnimatedCustomerSidebarBiggerThan1280Props) {
  const classes = useStyles();

  const animation = useSpring({
    width: showSideBar ? `${CUSTOMER_SIDEBAR_WIDTH}px` : '0px',
    height: '100%',
    onStart: () => setAnimationPlaying(true),
    onResolve: () => setAnimationPlaying(false),
    onPause: () => setAnimationPlaying(false),
  });

  return (
    <div
      className={
        showSideBar || (animationPlaying && !showSideBar)
          ? classes.rootShowBiggerThan1280
          : classes.rootNotShowBiggerThan1280
      }
    >
      <animated.div style={animation}>
        <div
          className={
            showSideBar ? classes.sidepanelWrapperBiggerThan1280 : classes.sidepanelWrapperHiddenBiggerThan1280
          }
        >
          <div className={classes.sidepanelRoot}>{children}</div>
        </div>
      </animated.div>
    </div>
  );
}

type AnimatedCustomerSidebarSmallerThan1280Props = AnimatedCustomerSidebar;

function AnimatedCustomerSidebarSmallerThan1280({
  showSideBar,
  setShowSideBar,
  children,
}: AnimatedCustomerSidebarSmallerThan1280Props) {
  const classes = useStyles();

  const animation = useSpring({
    width: `${CUSTOMER_SIDEBAR_WIDTH}px`,
    position: 'absolute',
    right: showSideBar ? 0 : -CUSTOMER_SIDEBAR_WIDTH,
    top: 54,
    bottom: 0,
    zIndex: defaultTheme.zIndex.drawer,
  });

  return (
    <div className={showSideBar ? classes.rootShowSmallarThan1280 : classes.rootNotShowSmallarThan1280}>
      <div
        className={showSideBar ? classes.overlayShow : classes.overlayNotShow}
        onClick={() => setShowSideBar(false)}
      />
      <animated.div style={animation as any}>
        <div
          className={
            showSideBar ? classes.sidepanelWrapperSmallarThan1280 : classes.sidepanelWrapperHiddenSmallarThan1280
          }
        >
          <div className={classes.sidepanelRoot}>{children}</div>
        </div>
      </animated.div>
    </div>
  );
}
