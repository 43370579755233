import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const TrailingPlusIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...rest}>
    <path
      d="M7.75004 2.33398C7.75004 1.91977 7.41425 1.58398 7.00004 1.58398C6.58583 1.58398 6.25004 1.91977 6.25004 2.33398H7.75004ZM6.25004 11.6673C6.25004 12.0815 6.58583 12.4173 7.00004 12.4173C7.41425 12.4173 7.75004 12.0815 7.75004 11.6673H6.25004ZM11.6667 7.75065C12.0809 7.75065 12.4167 7.41487 12.4167 7.00065C12.4167 6.58644 12.0809 6.25065 11.6667 6.25065V7.75065ZM2.33337 6.25065C1.91916 6.25065 1.58337 6.58644 1.58337 7.00065C1.58337 7.41486 1.91916 7.75065 2.33337 7.75065L2.33337 6.25065ZM6.25004 2.33398V11.6673H7.75004V2.33398H6.25004ZM11.6667 6.25065L2.33337 6.25065L2.33337 7.75065L11.6667 7.75065V6.25065Z"
      fill={color}
    />
  </SvgIcon>
);

export default TrailingPlusIcon;
