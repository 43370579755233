import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const TrailingMinusIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...rest}>
    <path
      d="M2.91663 7H11.0833"
      stroke={color}
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default TrailingMinusIcon;
