import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    padding: '24px 48px',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  recentSearchesText: {
    fontWeight: 500,
    fontSize: 18,
    color: colors.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearAllButton: {
    fontWeight: 500,
    fontSize: 10,
    color: colors.purple100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  recentSearches: {
    paddingTop: 12,
  },
}));
