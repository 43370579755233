import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 24,
    paddingBottom: 48,
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  bodyOpenSelect: {
    // overflow: 'hidden',
  },
  imgWrapper: {
    marginTop: 24,
    width: 152,
    height: 152,
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: colors.grey20,
  },
  img: {
    width: 152,
    height: 152,
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: colors.grey20,
    objectFit: 'contain',
  },
  right: {
    marginLeft: 24,
    marginTop: 24,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 344,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  info: {
    width: '100%',
  },
  wish2Bag: {
    marginTop: 8,
    marginLeft: 16,
  },
  name: {
    marginTop: 8,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    color: colors.black,
  },
  price: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
    marginTop: 6,
  },
  outOfStock: {
    margin: '8px 0px 0px',
  },
  optionWrapper: {
    marginTop: 12,
  },
  footer: {
    padding: '16px 32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    boxShadow: '0px -1px 0px #E7E9F9',
  },
  buttonCancel: {
    marginRight: 8,
  },
}));
