import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles((theme: CustomTheme) => ({
  rootOpen: {
    zIndex: theme.zIndex.drawer + 1,
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 12px',
    margin: '4px 0px',
    backgroundColor: colors.white,
    border: 'solid',
    borderWidth: 1,
    borderColor: colors.grey40,
    borderRadius: 8,
    cursor: 'pointer',
    width: 344,
  },
  selectOpen: {
    borderColor: colors.purple100,
    boxShadow: '0px 0px 0px 4px rgba(126, 5, 254, 0.1)',
  },
  label: {
    fontWeight: 400,
    fontSize: 10,
    color: colors.grey70,
  },
  selectedValue: {
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
  },
  optionsWrapper: {
    marginTop: 8,
    padding: '8px 0px 8px 8px',
    backgroundColor: colors.white,
    maxHeight: 160,
    border: 'solid',
    borderWidth: 1,
    borderColor: colors.grey40,
    borderRadius: 8,
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
    overflowY: 'auto',
  },
  option: {
    padding: 8,
    fontWeight: 400,
    fontSize: 14,
    height: 36,
    color: colors.black,
    cursor: 'pointer',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey20,
    },
  },
}));
