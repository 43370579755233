import React from 'react';

import { Icon } from '@amazd/common/assets/icons';
import { UserAvatar } from '@amazd/common/components';
import { Greeting, Reassign } from '@amazd/common/components/ChatSystemMessageBox';
import { useChannelPeerUser, useChannelActors } from '@amazd/common/hooks/useStreamChannel';
import { colors } from '@amazd/common/static';
import { useTranslation } from '@amazd/common/utils/i18n';
import { hasAccessTo, Permission } from '@amazd/common/utils/permissions';
import { IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip, useTheme } from '@mui/material';
import MediaPlaceholder from 'components/ChatMediaPlaceholder';
import { useCompany } from 'hooks/useCompanies';

import { useStyles } from './styles';
import { TimeTag } from './timeTag';
import { InboxListItemProps } from './types';

export default function InboxListItem({
  channel,
  ownUser,
  ownUserType,
  active,
  onChannelSelect,
  onArchive,
  onUnarchive,
  isArchievedList,
  selectedFilterOption,
}: InboxListItemProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const { classes } = useStyles({ active } as InboxListItemProps);

  const { expert } = useChannelActors(channel);
  const { peerUser, peerUserCompanyId } = useChannelPeerUser(channel);
  const peerUserCompany = useCompany(peerUserCompanyId);

  const lastMessage = channel?.lastMessage();
  const lastMessageSentByExpert = lastMessage?.user?.id === expert?.id;
  const messageType = (lastMessage?.customData as any)?.type || lastMessage?.type;
  const hasUnread = channel?.countUnread() > 0;
  const isMyChat = selectedFilterOption === 'my-chats' || selectedFilterOption === ownUser.id;
  const isOwnUserMember = channel.data?.expert_user_id === ownUser.id;

  const [file] = lastMessage?.attachments || [];

  const showCompanyHandle = !!peerUserCompanyId && peerUserCompanyId !== ownUserType?.company?.id;

  const getRootClassName = () => {
    if (active) return classes.selected;
    if (hasUnread) return classes.unread;
    return classes.read;
  };

  const getNameClassName = () => {
    if (active) return classes.selectedName;
    if (hasUnread) return classes.unreadName;
    return classes.readName;
  };

  const getMessageClassName = () => {
    if (active) return classes.selectedMessage;
    if (hasUnread) return classes.unreadMessage;
    return classes.readMessage;
  };

  const handleArchivedMove = () => {
    if (!channel.id) {
      console.error('handleArchivedMove: !channel.id');
      return;
    }
    if (!isOwnUserMember) return;

    if (isArchievedList) {
      onUnarchive(channel.id);
    } else {
      onArchive(channel.id);
    }
  };

  return (
    <>
      <ListItem
        className={`${classes.defaultRoot} ${getRootClassName()}`}
        onClick={() => {
          onChannelSelect(channel);
        }}
        style={{ alignItems: 'stretch', padding: '14px 16px' }}
        disablePadding
        sx={{
          '&:hover': {
            '.amazd-archive-unarchive-button': {
              opacity: 1,
            },
          },
        }}
      >
        {peerUser && (
          <>
            <ListItemAvatar style={{ minWidth: 52 }}>
              <UserAvatar
                size={40}
                className={classes.avatar}
                firstName={peerUser.first_name}
                lastName={peerUser.last_name}
                src={peerUser.image}
                online={peerUser.online}
              />
            </ListItemAvatar>
            <ListItemText
              style={{ display: 'flex', alignItems: 'center' }}
              primary={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '22px',
                    marginTop: 2,
                  }}
                >
                  <span className={`${classes.defaultName} ${getNameClassName()}`}>{peerUser.name}</span>
                  {showCompanyHandle && <span className={classes.companyHandle}>{`@${peerUserCompany?.handle}`}</span>}
                  <>
                    <span className={`${classes.defaultMessage} ${getMessageClassName()}`}>
                      {lastMessage &&
                        (messageType === 'REASSIGN' ? (
                          <Reassign
                            isPreview={true}
                            isWidgetScreen={false}
                            channel={channel}
                            message={lastMessage}
                            ownUserType={ownUserType}
                          />
                        ) : messageType === 'GREETING' ? (
                          <Greeting
                            isPreview={true}
                            isWidgetScreen={false}
                            channel={channel}
                            message={lastMessage}
                            ownUserType={ownUserType}
                          />
                        ) : messageType === 'call' ? (
                          t('chat-message-button-joinCall')
                        ) : file ? (
                          <MediaPlaceholder
                            lastMessageSentByExpert={lastMessageSentByExpert}
                            message={lastMessage}
                            fontSize={10}
                            iconSize={12}
                            color={theme.palette.text.secondary}
                          />
                        ) : (
                          <span>
                            {lastMessage && (
                              <span>
                                {lastMessageSentByExpert && !lastMessage?.customData && t('chat-message-you')}
                                {lastMessage?.text}
                              </span>
                            )}
                          </span>
                        ))}
                    </span>
                    {lastMessage && (
                      <TimeTag
                        className={classes.timeTag}
                        date={lastMessage.created_at}
                        isColorful={
                          !isArchievedList && !lastMessageSentByExpert && !['REASSIGN', 'call'].includes(messageType)
                        }
                      />
                    )}
                  </>
                </div>
              }
            ></ListItemText>
            <div className={classes.right}>
              <>
                {hasAccessTo(ownUser.role, Permission.SEE_TEAM) && expert && !isMyChat ? (
                  <Tooltip arrow={true} placement="right" title={expert.name as string}>
                    <div className={classes.expertSection}>
                      <UserAvatar
                        size={24}
                        firstName={expert.first_name as string}
                        lastName={expert.last_name as string}
                        src={expert.image as string | undefined}
                        online={expert.online}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div></div>
                )}
                <Tooltip
                  placement="bottom"
                  title={
                    !isOwnUserMember
                      ? `${t('amazds-settings-move-to-disabled-tooltip')}`
                      : isArchievedList
                      ? t('amazds-settings-move-to-active')
                      : t('amazds-settings-move-to-done')
                  }
                >
                  <IconButton
                    onClick={handleArchivedMove}
                    color="primary"
                    data-testid="amazd-archive-unarchive-button"
                    className="amazd-archive-unarchive-button"
                    disableRipple={!isOwnUserMember}
                    sx={{
                      width: '36px',
                      height: '36px',
                      position: 'absolute',
                      bottom: '5px',
                      right: '8px',
                      zIndex: 1002,
                      padding: 0,
                      opacity: 0,
                      transition: '0.2s',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    {isArchievedList ? (
                      <Icon name="move-to-active" color={isOwnUserMember ? colors.grey90 : colors.grey60} size={20} />
                    ) : (
                      <Icon name="move-to-done" color={isOwnUserMember ? colors.grey90 : colors.grey60} size={20} />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            </div>
          </>
        )}
      </ListItem>
    </>
  );
}
