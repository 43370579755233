import { useAuthInfo } from '@amazd/common/hooks/auth';
import { useImpersonation } from '@amazd/common/hooks/useImpersonation';
import { useMyShopDetails } from '@amazd/common/hooks/useShopDetails';
import { useChannelPeerUser } from '@amazd/common/hooks/useStreamChannel';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Tooltip } from '@mui/material';
import { ShopifyCatalogueButton } from 'components/Shopify/catalogueButton';
import { ShopifyCataloguePopup } from 'components/Shopify/cataloguePopup';
import { MessageInput, useChannelStateContext } from 'stream-chat-react';

import useStyles from './styles';

export const MessageInputWrapper = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { ownUser } = useAuthInfo();
  const { channel } = useChannelStateContext();
  const { isOwnUserMember } = useChannelPeerUser(channel);
  const { isImpersonating } = useImpersonation();
  const { shopDetails } = useMyShopDetails();

  const isExpert = ownUser?.userTypes?.find((userType) => userType.isExpert);
  const showShopifyCatalogue = channel && isExpert && isOwnUserMember && shopDetails?.shopDomain;

  return (
    <Tooltip arrow={true} placement="top" title={!isOwnUserMember ? `${t('inbox-input-disabled-tooltip')}` : ''}>
      <div>
        {/* needed for tooltip */}
        {showShopifyCatalogue && <ShopifyCataloguePopup channel={channel} />}
        <div
          className={!isOwnUserMember ? classes.messageInputDisabled : ''}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          {showShopifyCatalogue && <ShopifyCatalogueButton />}
          <MessageInput disabled={isImpersonating} grow={true} maxRows={12} />
        </div>
      </div>
    </Tooltip>
  );
};
