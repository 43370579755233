import { useMyShopDetails } from '@amazd/common/hooks/useShopDetails';
import { reportError } from '@amazd/common/utils';
import { gql, useQuery } from '@apollo/client';

export type ShopCustomer = {
  id?: string;
  createdAt?: string;
  numberOfOrders?: number;
  amountSpent?: ShopMoney;
  averageOrderAmount?: ShopMoney;
  lastOrder?: ShopOrder;
  tags?: string[];
};

export type ShopMoney = {
  amount?: number;
  currencyCode?: string;
};

export class ShopOrder {
  id?: string;
  name?: string;
  createdAt?: string;
  totalPrice?: ShopMoney;
}

const query = gql`
  query ($shopperId: String!) {
    shopCustomer(userId: $shopperId) {
      id
      createdAt
      numberOfOrders
      amountSpent {
        amount
        currencyCode
      }
      averageOrderAmount {
        amount
        currencyCode
      }
      lastOrder {
        id
        name
        createdAt
        totalPrice {
          amount
          currencyCode
        }
      }
      tags
    }
  }
`;

export const useShopCustomerQuery = (shopperId?: string) => {
  const { shopDetails } = useMyShopDetails();

  const { data, loading, refetch, error } = useQuery(query, {
    variables: {
      shopperId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: !shopDetails?.shopPlatform,
  });

  if (error) {
    reportError(error, {
      extraInfo: {
        shopperId,
      },
    });
  }
  return {
    shopCustomer: data?.shopCustomer as ShopCustomer | null,
    loading,
    reload: refetch,
  };
};
