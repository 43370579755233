import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    marginTop: 16,
    padding: '0px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginTop: 16,
    fontWeight: 500,
    fontSize: 18,
    color: colors.black,
  },
  subtitle: {
    marginTop: 8,
    fontWeight: 400,
    fontSize: 14,
    color: colors.grey80,
    textAlign: 'center',
  },
  descriptionWrapper: {
    margin: '32px 0px 28px 0px',
  },
  descriptionTitle: {
    fontWeight: 400,
    fontSize: 14,
    color: colors.black,
    marginBottom: 24,
  },
  li: {
    fontWeight: 400,
    fontSize: 14,
    color: colors.black,
    marginBottom: 20,
  },
}));
