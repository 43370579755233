import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';

import { ShopCategory, ShopProduct } from '@amazd/common/types/shop.types';
import { PageHistory } from 'components/Shopify/models/pageHistory.model';
import type { Channel } from 'stream-chat';

type PageType = 'catalogue' | 'catalogueDetail' | 'search' | 'productVariantPicker';

type ShopifyCatalogueBreadcrumb = {
  id?: string;
  childId?: string;
  parentId?: string;
  label?: string;
  item?: ShopCategory | ShopProduct;
};

type ShopifyCataloguePopupContextType = {
  show: boolean;
  page: PageType;
  changePage: (page: PageType, item?: ShopCategory | ShopProduct) => void;
  goBack: () => void;
  openBreadcrumb: (id: string | undefined) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedCollection?: ShopCategory | null;
  selectedProduct?: ShopProduct | null;
  breadcrumbs: ShopifyCatalogueBreadcrumb[];
};

const initialValue: ShopifyCataloguePopupContextType = {
  show: false,
  page: 'catalogue',
  changePage: () => null,
  openBreadcrumb: () => null,
  goBack: () => null,
  open: false,
  setOpen: () => null,
  breadcrumbs: [],

  selectedCollection: null,
  selectedProduct: null,
};

export const ShopifyCataloguePopupContext = createContext<ShopifyCataloguePopupContextType>(initialValue);

interface ShopifyCataloguePopupProviderProps {
  channel: Channel;
  value?: Omit<ShopifyCataloguePopupContextType, 'changePage' | 'goBack'>;
  children?: ReactElement<any, any>;
}

const history = new PageHistory<PageType>('catalogue');
export const ShopifyCataloguePopupProvider = ({
  channel,
  value = initialValue,
  ...rest
}: ShopifyCataloguePopupProviderProps) => {
  const [open, setOpen] = useState<boolean>(value.open);
  const [page, setPage] = useState<PageType>(value.page || 'catalogue');
  const [selectedCollection, setSelectedCollection] = useState<ShopCategory | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<ShopProduct | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<ShopifyCatalogueBreadcrumb[]>([]);

  const addBreadcrumb = (item?: ShopCategory | ShopProduct) => {
    if (!item?.id) return;
    if (breadcrumbs.find((b) => b.id === item.id)) return;

    setBreadcrumbs((prev) => {
      if (prev.find((b) => b.id === item.id)) prev;

      return [
        ...prev.map((crumb) => {
          if (crumb.id === selectedCollection?.id) {
            return { ...crumb, childId: item.id };
          }
          return crumb;
        }),
        {
          parentId: selectedCollection?.id,
          label: item.title,
          id: item.id,
          item,
        },
      ];
    });
  };

  const changePage = (newPage: PageType, item?: ShopCategory | ShopProduct) => {
    if (newPage === 'catalogue') {
      setSelectedCollection(null);
      setSelectedProduct(null);
      setBreadcrumbs([]);
    }

    if (newPage === 'catalogueDetail') {
      addBreadcrumb(item);
      setSelectedCollection(item as ShopCategory);
      setSelectedProduct(null);
    }

    if (newPage === 'productVariantPicker') {
      if (page === 'search') {
        history.clear();
        history.push('catalogue');
        const newSelectedProduct = item as ShopProduct;
        if (newSelectedProduct?.categories?.items?.length) {
          history.push('catalogueDetail');
          addBreadcrumb(newSelectedProduct.categories.items[0]);
        } else {
          setSelectedCollection(null);
          setBreadcrumbs([]);
        }
      }
      addBreadcrumb(item);
      setSelectedProduct(item as ShopProduct);
    }
    if (page !== newPage) history.push(page);
    setPage(newPage);
  };

  const openBreadcrumb = (id?: string) => {
    if (selectedProduct) {
      if (selectedProduct?.id === id) return;
    } else if (selectedCollection) {
      if (selectedCollection?.id === id) return;
    }

    const breadcrumb = breadcrumbs.find((b) => b.id === id);
    if (!breadcrumb?.item) return;

    setBreadcrumbs(breadcrumbs.slice(0, breadcrumbs.indexOf(breadcrumb) + 1));
    changePage('catalogueDetail', breadcrumb.item);
  };

  const goBack = () => {
    const lastPage = history.pop();
    if (lastPage) setPage(lastPage);
  };

  useEffect(() => {
    if (!open) changePage('catalogue');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [channel?.id]);

  return (
    <ShopifyCataloguePopupContext.Provider
      value={{
        ...value,
        page,
        changePage,
        goBack,
        openBreadcrumb,
        open,
        setOpen,
        selectedCollection,
        selectedProduct,
        breadcrumbs,
      }}
      {...rest}
    />
  );
};

export const useShopifyCataloguePopupContext = () => useContext(ShopifyCataloguePopupContext);
