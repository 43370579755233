import { useEffect, useState } from 'react';

export const useScreenSize = (): {
  x: number;
  y: number;
} => {
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });

  const updateSize = () => {
    const { innerHeight, innerWidth } = window;
    setSize({
      x: innerWidth,
      y: innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return size;
};
