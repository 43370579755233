import React from 'react';

import { Loader } from '@amazd/common/components';
import useLogin from 'hooks/useLogin';
import { NextPage } from 'next';
import InboxView from 'views/InboxView';

const AmazdsPage: NextPage = () => {
  // this will check if it's not logged in then it will redirect to login page
  const [, user] = useLogin(true);

  if (!user) {
    return <Loader size={256} />;
  }

  return <InboxView />;
};

export default AmazdsPage;
