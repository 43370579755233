import { useEffect, useState } from 'react';

import { getClient } from '@amazd/common/apollo';
import { ShopProduct } from '@amazd/common/types/shop.types';
import { ProductFragment } from '@amazd/common/utils/product';
import { gql } from '@apollo/client';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';

export function useProductQuery() {
  const [product, setProduct] = useState<ShopProduct | undefined | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedProduct } = useShopifyCataloguePopupContext();

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProduct = async () => {
    setLoading(true);

    const product: ShopProduct = (
      await getClient().query({
        query: gql`
          query ShopProduct($args: ShopGetProductArgs!) {
            shopProduct(args: $args) {
              ${ProductFragment}
            }
          }
        `,
        variables: {
          args: {
            sku: selectedProduct?.sku,
            id: selectedProduct?.id,
          },
        },
        fetchPolicy: 'no-cache', // We already have backend caching for the catalog.
      })
    ).data?.shopProduct;

    setProduct(product);
    setLoading(false);
  };

  return { loading, product };
}
