import React from 'react';

import { ImageIcon } from '@amazd/common/icons';
import ClipIcon from '@amazd/common/icons/ClipIcon';
import VideoIcon from '@amazd/common/icons/VideoIcon';
import { StreamMessage } from '@amazd/common/redux/types/chat.types';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Typography } from '@mui/material';

import { useStyles } from './styles';

export type FileTypes = 'image' | 'video' | 'file' | '';

export default function MediaPlaceholder({
  message,
  fontSize,
  iconSize,
  color,
  lastMessageSentByExpert,
}: {
  message: StreamMessage;
  fontSize?: number;
  iconSize?: number;
  color?: string;
  lastMessageSentByExpert?: boolean;
}): JSX.Element {
  const classes = useStyles(iconSize);
  const { t } = useTranslation();
  const [file] = message.attachments || [];
  const fileType = file ? (file.type as FileTypes) : '';

  const iconStyle = {
    height: iconSize,
    width: iconSize,
    marginLeft: iconSize ? iconSize / 5 : undefined,
    marginRight: iconSize ? iconSize / 3 : undefined,
    color,
  };

  const icons = {
    image: <ImageIcon className={classes.icon} style={iconStyle} />,
    video: <VideoIcon className={classes.icon} style={iconStyle} />,
    file: <ClipIcon className={classes.icon} style={iconStyle} />,
    '': <></>,
  };

  const placeholderText = {
    image: 'Image',
    video: 'Video',
    file: 'File',
    '': '',
  };

  return (
    <span className={classes.root}>
      {lastMessageSentByExpert && t('chat-message-you')}
      {icons[fileType]}
      <Typography className={classes.text} component="span" style={{ fontSize, color }} variant="subtitle1">
        {message.text || placeholderText[fileType]}
      </Typography>
    </span>
  );
}
