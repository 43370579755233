import React from 'react';

import WishBagEmptySvg from '@amazd/common/assets/illustrations/wishBagEmptySvg';
import { useChannelPeerUser } from '@amazd/common/hooks/useStreamChannel';
import WishBagAddIcon from '@amazd/common/icons/WishBagAddIcon';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Button } from '@mui/material';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { colors } from 'styles/theme';
import { useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';

import useStyles from './styles';

export function WishBagEmpty() {
  const { t } = useTranslation();

  const { setOpen } = useShopifyCataloguePopupContext();
  const { setShowSideBar, channel } = useChatSidebarContext();
  const { peerUser } = useChannelPeerUser(channel);

  const classes = useStyles();

  const onClickOpenProductCatalogue = () => {
    setOpen(true);
    if (window.innerWidth < 1280) setShowSideBar(false);
  };

  const customerName = peerUser?.first_name;

  return (
    <div className={classes.root}>
      <WishBagEmptySvg style={{ fontSize: 112, marginTop: 24 }} />
      <div className={classes.title}>{t('wish-bag-empty-title')}</div>
      <div className={classes.subtitle}>
        <div>{t('wish-bag-empty-subtitle')}</div>
        <div>{peerUser?.first_name}.</div>
      </div>

      <div className={classes.descriptionWrapper}>
        <div className={classes.descriptionTitle}>{t('wish-bag-empty-how-is-work-title')}</div>
        <div className={classes.li}>{t('wish-bag-empty-how-is-work-shared-1', { customerName })}</div>
        <div className={classes.li}>{t('wish-bag-empty-how-is-work-shared-2', { customerName })}</div>
        <div className={classes.li}>{t('wish-bag-empty-how-is-work-shared-3', { customerName })}</div>
        <div className={classes.li}>{t('wish-bag-empty-how-is-work-shared-4', { customerName })}</div>
      </div>
      <Button variant={'outlined'} color={'primary'} onClick={onClickOpenProductCatalogue}>
        <WishBagAddIcon color={colors.purple100} style={{ fontSize: 24, marginRight: 6 }} />
        {t('wish-bag-empty-start-sharing-product')}
      </Button>
    </div>
  );
}
