import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ToggleSidebarIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      d="M12 21V3M8.5 8H6.5M8.5 12H6.5M8.5 16H6.5M8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8C3 5.23858 5.23858 3 8 3Z"
      stroke={color}
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ToggleSidebarIcon;
