import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export const useStyles = makeStyles(() => ({
  rootNotSearchMode: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    backgroundColor: colors.grey20,
    borderRadius: 31,
    cursor: 'pointer',
    margin: '0px 10px',
    width: 356,
  },
  labelNotSearchMode: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
  },
  rootSearchMode: {
    display: 'flex',
    width: 375,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '2px solid',
    borderRadius: 31,
    borderColor: colors.purple100,
    height: 40,
    padding: '10px 10px 10px 16px',
    width: '100%',
  },
  input: {
    overflow: 'hidden',
    outline: 'none',
    resize: 'none',
    width: '100%',
    fontFamily: 'FilsonPro',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    border: 'none',
    '&::placeholder': {
      fontFamily: 'FilsonPro',
      color: colors.grey70,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));
