import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const UnreadListEmptySvg: React.FC<any> = (props): React.ReactElement => (
  <SvgIcon viewBox="0 0 112 112" fill="none" {...props}>
    <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M76.039 18.3799L72.124 22.2949"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.124 18.3799L46.749 22.0049"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.5811 15.625V20.7362"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.8594 93.7252C73.5045 94.646 81.4759 92.1955 87.3411 86.3266V86.3266C93.4819 80.1859 96.1463 71.3807 94.4461 62.8656L89.9439 40.3616C89.4183 37.7299 87.1055 35.834 84.4194 35.834V35.834C81.3091 35.834 78.7861 38.357 78.7861 41.4672V46.8431"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.7158 41.1414L62.1256 34.5512C60.0013 32.4269 56.5576 32.4269 54.4369 34.5512V34.5512C52.3127 36.6754 52.3127 40.1192 54.4369 42.2398"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5848 40.772C35.4605 38.6478 35.4605 35.204 37.5848 33.0834V33.0834C39.709 30.9592 43.1528 30.9592 45.2734 33.0834L59.371 47.181L67.0597 54.8697V45.122C67.0597 42.0118 69.5827 39.4888 72.6929 39.4888V39.4888C75.379 39.4888 77.6918 41.3847 78.2174 44.0164L82.2738 64.3055C83.9775 72.8207 81.3132 81.6258 75.1688 87.7665V87.7665C65.026 97.9093 48.5794 97.9093 38.4367 87.7665L28.6129 77.9392L19.641 68.9673C17.5168 66.843 17.5168 63.3993 19.641 61.2787V61.2787C21.7653 59.1544 25.209 59.1544 27.3297 61.2787"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.8679 72.8134L22.2074 56.1529C20.0832 54.0287 20.0832 50.5849 22.2074 48.4643V48.4643C24.3317 46.34 27.7754 46.34 29.8961 48.4643"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5562 65.1208L24.7699 43.3345C22.6457 41.2103 22.6457 37.7665 24.7699 35.6459V35.6459C26.8942 33.5217 30.3379 33.5217 32.4586 35.6459L54.2448 57.4322"
        stroke="#BABDCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="27" cy="97" r="3" fill="#FF0066" />
      <circle cx="11" cy="40" r="2" fill="#FFB13F" />
      <path
        d="M99 25C99 27.2091 97.2091 29 95 29C92.7909 29 91 27.2091 91 25C91 22.7909 92.7909 21 95 21C97.2091 21 99 22.7909 99 25Z"
        fill="#7E05FE"
      />
    </svg>
  </SvgIcon>
);

export default UnreadListEmptySvg;
