import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ConversationCallScheduledIcon: React.FC<any> = (props): React.ReactElement => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path
      d="M10.539 9.22575H12V7.764M6 9.22575C6.28435 8.70356 6.70421 8.26769 7.21539 7.96399C7.72657 7.6603 8.31016 7.50002 8.90475 7.50002C9.49934 7.50002 10.0829 7.6603 10.5941 7.96399C11.1053 8.26769 11.5251 8.70356 11.8095 9.22575M5.625 12H6.375M8.625 12H9.375M11.625 12H12.375M5.625 2.25V4.5M12.375 2.25V4.5M4.5 3.375H13.5C14.7426 3.375 15.75 4.38236 15.75 5.625V13.5C15.75 14.7426 14.7426 15.75 13.5 15.75H4.5C3.25736 15.75 2.25 14.7426 2.25 13.5V5.625C2.25 4.38236 3.25736 3.375 4.5 3.375Z"
      fill={'none'}
      stroke="#727488"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ConversationCallScheduledIcon;
