import React, { useEffect, useState } from 'react';

import { UserAvatar } from '@amazd/common/components';
import { useShopDetailsByAmazdId } from '@amazd/common/hooks/useShopDetails';
import { StreamUserExtended, useChannelActors } from '@amazd/common/hooks/useStreamChannel';
import ConversationCallScheduledIcon from '@amazd/common/icons/ConversationCallScheduledIcon';
import ConversationStartDateIcon from '@amazd/common/icons/ConversationStartDateIcon';
import { colors } from '@amazd/common/static';
import { useTranslation } from '@amazd/common/utils/i18n';
import { getCurrencySymbol, formatMoney } from '@amazd/common/utils/money';
import { Button, CircularProgress, Skeleton, Tooltip, Typography } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import { Alert } from 'components/Alert';
import Tag from 'components/Tag';
import { TextArea } from 'components/TextArea';
import { useAmazdAppointment } from 'hooks/useAmazdAppointment';
import ISO6391 from 'iso-639-1';
import { useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';
import { useAmazdNote } from 'views/InboxView/ChatSidebar/OverviewTab/useAmazdNote';
import { useSaveAmazdNote } from 'views/InboxView/ChatSidebar/OverviewTab/useSaveAmazdNote';

import { InfoCard } from './card';
import { ConversationItem } from './conversationItem';
import { useAmazd } from './hooks/useAmazd';
import { useCustomerLastVisitedPages } from './hooks/useCustomerLastVisitedPages';
import { useShopCustomerQuery } from './hooks/useShopCustomerQuery';
import { InfoItem } from './infoItem';
import { LastOrderCard } from './lastOrderCard';
import useStyles from './styles';
import { VisitedPages } from './visited-pages';

export interface CustomerOverviewTabProps {
  peerUser: StreamUserExtended;
  disableExpertFunctions: boolean;
}

export function CustomerOverviewTab({ peerUser, disableExpertFunctions }: CustomerOverviewTabProps) {
  const { t, formatDate } = useTranslation();

  const classes = useStyles();

  const [note, setNote] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);

  const { channel } = useChatSidebarContext();
  const { owner } = useChannelActors(channel);
  const amazdId = channel?.id as string;

  const { loading: visitedPagesLoading, data: visitedPages } = useCustomerLastVisitedPages(amazdId);
  const {
    details: { shopDomain, shopPlatformLabel },
  } = useShopDetailsByAmazdId(amazdId);

  const { loading: loadingShopifyInfo, shopCustomer } = useShopCustomerQuery(owner?.id);

  const { loading: loadingAmazdNote, amazdNote } = useAmazdNote(amazdId);
  const { saveAmazdNote } = useSaveAmazdNote();

  const { loading: loadingAmazd, amazd } = useAmazd(amazdId);
  const ownerUserType = amazd?.owner?.userType;
  const { appointment } = useAmazdAppointment(amazd);

  useEffect(() => {
    setNote(amazdNote || '');
  }, [amazdNote]);

  const onUpdateCustomerNote = async () => {
    setLoadingSave(true);
    const saveReuslt = await saveAmazdNote({ variables: { args: { amazdId, note } } });
    setNote(saveReuslt.data.saveAmazdNote.note);
    setLoadingSave(false);
  };

  const showCustomerNoteSaveButton = !(disableExpertFunctions || amazdNote === note);

  const animationCustomerNoteSaveButton = useSpring({
    color: showCustomerNoteSaveButton ? colors.amazdPurple : colors.grey50,
    fontSize: 12,
    fontWeight: 400,
  });

  return (
    <>
      {shopDomain && !shopCustomer && (
        <Tooltip
          arrow={true}
          placement="bottom"
          title={t('customer-sidebar-no-shop-customer-found-tooltip-2', { platform: shopPlatformLabel })}
        >
          <div>
            <Alert
              type={'info'}
              message={t('customer-sidebar-no-shop-customer-found-message-v2', { platform: shopPlatformLabel })}
            />
          </div>
        </Tooltip>
      )}
      <div className={classes.root}>
        <InfoCard title={t('customer-sidebar-about')} loading={loadingAmazd}>
          <div className={shopCustomer ? classes.customerCardWithShopify : classes.customerCard}>
            {loadingAmazd ? (
              <Skeleton variant={'circular'} width={40} height={40} />
            ) : (
              <UserAvatar
                firstName={peerUser?.first_name as string}
                lastName={peerUser?.last_name as string}
                size={40}
                online={peerUser?.online}
                src={peerUser?.image}
              />
            )}
            <div className={classes.customerInfoWrapper}>
              <div>
                <span className={classes.customerFullName}>
                  {loadingAmazd ? <Skeleton width={150} /> : `${peerUser?.first_name} ${peerUser?.last_name}`}
                </span>
              </div>
              {shopCustomer && shopCustomer.createdAt && (
                <div>
                  <span className={classes.customerRegistrationDate}>
                    {loadingShopifyInfo ? (
                      <Skeleton width={75} />
                    ) : (
                      t('customer-sidebar-customer-since-date', {
                        date: formatDate(shopCustomer.createdAt, 'MMM yyyy'),
                      })
                    )}
                  </span>
                </div>
              )}
              <div>
                <span className={classes.customerRegistrationDate}>
                  {loadingAmazd ? (
                    <Skeleton width={75} />
                  ) : (
                    ownerUserType?.spokenLanguages &&
                    t('customer-sidebar-spoken-languages', {
                      languages: ownerUserType?.spokenLanguages
                        ?.map((language: string) => ISO6391.getName(language))
                        .join(', '),
                    })
                  )}
                </span>
              </div>
              <Typography
                component="span"
                sx={{
                  color: colors.grey80,
                  fontSize: 12,
                }}
              >
                {t('user-form-timezone')}: {ownerUserType?.timezone}
              </Typography>
              <div>
                <Typography
                  component="span"
                  sx={{
                    color: colors.grey80,
                    fontSize: 12,
                  }}
                >
                  {t('customer-sidebar-email')}: {amazd?.owner?.userType?.email || 'N/A'}
                </Typography>
              </div>
              <div>
                <Typography
                  component="span"
                  sx={{
                    color: colors.grey80,
                    fontSize: 12,
                  }}
                >
                  {t('customer-sidebar-phone')}: {amazd?.owner?.userType?.phone || 'N/A'}
                </Typography>
              </div>
              {shopCustomer?.tags && !loadingShopifyInfo && (
                <div className={classes.shopperTagWrapper}>
                  {shopCustomer?.tags.map((tag: string, index: number) => (
                    <Tag
                      key={index.toString()}
                      label={tag}
                      mr="4px"
                      mt="4px"
                      maxLabelLength={36}
                      tooltipProps={{ title: tag, placement: 'top' }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          {shopCustomer && (
            <div className={classes.customerShopifyInfoWrapper}>
              <InfoItem
                valueCurrency={getCurrencySymbol(shopCustomer.amountSpent?.currencyCode as CurrencyCode)}
                value={formatMoney(Number(shopCustomer.amountSpent?.amount))}
                label={t('customer-sidebar-info-total-spent')}
                loading={loadingShopifyInfo}
              />
              <InfoItem
                valueCurrency={getCurrencySymbol(
                  (shopCustomer.averageOrderAmount?.currencyCode ||
                    shopCustomer.amountSpent?.currencyCode) as CurrencyCode,
                )}
                value={formatMoney(Number(shopCustomer.averageOrderAmount?.amount || 0))}
                label={t('customer-sidebar-info-average-order')}
                loading={loadingShopifyInfo}
              />
              <InfoItem
                value={shopCustomer.numberOfOrders}
                label={t('customer-sidebar-info-orders')}
                loading={loadingShopifyInfo}
              />
            </div>
          )}
          <div className={classes.customerNoteWrapper}>
            <Tooltip
              arrow={true}
              placement="bottom"
              title={disableExpertFunctions ? `${t('customer-sidebar-customer-note-disabled-tooltip')}` : ''}
            >
              <div>
                {loadingAmazdNote ? (
                  <Skeleton width={'100%'} height={102} />
                ) : (
                  <TextArea
                    label={t('customer-sidebar-customer-note-label')}
                    placeholder={t('customer-sidebar-customer-note-label')}
                    disabled={disableExpertFunctions}
                    onChangeValue={(value) => setNote(value)}
                    value={note}
                  />
                )}
                <div className={classes.customerNoteSaveWrapper}>
                  <Button
                    size={'small'}
                    color={'primary'}
                    disabled={loadingSave || !showCustomerNoteSaveButton}
                    onClick={onUpdateCustomerNote}
                  >
                    {loadingSave ? (
                      <CircularProgress size={12} />
                    ) : (
                      <animated.div style={animationCustomerNoteSaveButton}>
                        {loadingAmazdNote ? (
                          <Skeleton width={80} />
                        ) : (
                          <Typography>{t('customer-sidebar-customer-note-save')}</Typography>
                        )}
                      </animated.div>
                    )}
                  </Button>
                </div>
              </div>
            </Tooltip>
          </div>
        </InfoCard>
        {shopCustomer?.lastOrder && (
          <LastOrderCard
            loading={loadingShopifyInfo}
            createdAt={shopCustomer.lastOrder.createdAt as string}
            priceStr={`${getCurrencySymbol(
              shopCustomer.lastOrder.totalPrice?.currencyCode as CurrencyCode,
            )}${formatMoney(Number(shopCustomer.lastOrder.totalPrice?.amount))}`}
            orderName={shopCustomer.lastOrder.name}
            orderId={shopCustomer.lastOrder.id}
          />
        )}
        <VisitedPages loading={visitedPagesLoading} pages={visitedPages} />
        <InfoCard title={t('customer-sidebar-conversation-insights')} loading={loadingShopifyInfo}>
          <ConversationItem
            icon={<ConversationStartDateIcon style={{ fontSize: 18 }} />}
            label={t('customer-sidebar-conversation-insights-start-date')}
            value={formatDate(peerUser?.created_at as string, 'MMMM dd, yyyy')}
            loading={loadingShopifyInfo}
          />
          <div style={{ height: 16 }} />
          <ConversationItem
            icon={<ConversationCallScheduledIcon style={{ fontSize: 18 }} />}
            label={t('customer-sidebar-conversation-insights-call-scheduled')}
            value={
              appointment ? (
                <>
                  {formatDate(appointment?.startingAt, 'MMMM dd, yyyy')}
                  <br />
                  {formatDate(appointment?.startingAt, 'HH:mm')}-
                  {formatDate(
                    new Date().setTime(new Date(appointment?.startingAt).getTime() + appointment.duration * 60 * 1000),
                    'HH:mm',
                  )}{' '}
                  ({appointment.duration} {t('time-unit-min')})
                </>
              ) : (
                t('customer-sidebar-conversation-insights-call-scheduled-none')
              )
            }
            loading={loadingAmazd}
          />
        </InfoCard>
      </div>
    </>
  );
}
