import { useEffect, useState } from 'react';

import { Company } from '@amazd/common/redux/types';
import { gql, useQuery } from '@apollo/client';

const query = gql`
  query {
    companies {
      id
      name
      handle
      description
      image
    }
  }
`;

export const useCompanies = () => {
  const { data, loading, refetch } = useQuery(query, { notifyOnNetworkStatusChange: true });

  return { companies: data?.companies as Company[], loading, reload: refetch };
};

export const useCompany = (companyId?: string) => {
  const { companies, loading } = useCompanies();
  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    const found = companies?.find((x) => x.id === companyId);
    setCompany(found);
  }, [companyId, loading, companies]);

  return company;
};
