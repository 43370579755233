import React from 'react';

import { Icon } from '@amazd/common/assets/icons';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { colors } from 'styles/theme';

import useStyles from './styles';

export const ShopifyCatalogueButton = () => {
  const classes = useStyles();

  const { open, setOpen } = useShopifyCataloguePopupContext();

  // render if company has shopify integration
  return (
    <div className={classes.root} onClick={() => setOpen(!open)}>
      <Icon name={'line-add-product'} color={colors.grey80} size={28} />
    </div>
  );
};
