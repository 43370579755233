import React, { useEffect } from 'react';

import Loader from '@amazd/common/components/Loader';
import { animated, useTrail } from '@react-spring/web';
import { useShopifyCataloguePopupContext } from 'components/Shopify/cataloguePopup/context/useShopifyCataloguePopup';
import { ProductCatalogueSubHeader } from 'components/Shopify/cataloguePopup/subHeader';

import { ProductCatalogueCard } from './catalogueCard';
import { useCollectionsQuery } from './hooks/useCollectionsQuery';
import useStyles from './styles';

const SCROLL_THRESHOLD_PX = 50;

export const ProductCatalogue = () => {
  const classes = useStyles();

  const { page: pagePopup } = useShopifyCataloguePopupContext();
  const open = pagePopup === 'catalogue';
  const [trail, api] = useTrail(1, () => ({
    opacity: 0,
    from: { opacity: 0 },
  }));

  const { items, loading, loadNextPage } = useCollectionsQuery();

  useEffect(() => {
    if (open)
      setTimeout(() => {
        api.start({ opacity: 1 });
      }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleScroll = (e: any) => {
    const reached = e.target.scrollHeight - e.target.scrollTop - SCROLL_THRESHOLD_PX <= e.target.clientHeight;
    if (reached) loadNextPage();
  };

  return (
    <>
      <ProductCatalogueSubHeader />
      {trail.map((style, index) => (
        <animated.div
          key={index.toString()}
          style={{
            display: 'flex',
            height: '100%',
            minHeight: '100px',
            ...style,
          }}
        >
          <div className={classes.root} onScroll={handleScroll}>
            <div className={classes.body}>
              {items &&
                items.map((item, index) => (
                  <ProductCatalogueCard key={index.toString()} item={item} loading={loading} />
                ))}
              {loading && <Loader size={100} />}
            </div>
          </div>
        </animated.div>
      ))}
    </>
  );
};
