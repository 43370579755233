import React from 'react';

import useStyles from './styles';

interface BulletListItemProps {
  className?: string;
  left: React.ReactNode;
  title: string;
  title2?: string;
  titlePost?: string;
}

const BulletListItem = ({ left, title, title2, titlePost, className }: BulletListItemProps) => {
  const { classes } = useStyles();

  return (
    <div className={`${classes.root} ${className}`}>
      <div className={classes.left}>{left}</div>
      <div className={classes.title}>
        {title}
        {title2 && (
          <>
            <br />
            {title2}
          </>
        )}
        <span className={classes.titlePost}>{titlePost && ` ${titlePost}`}</span>
      </div>
    </div>
  );
};

export default BulletListItem;
