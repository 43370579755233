import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    overflowY: 'auto',
    height: 406,
    paddingBottom: 48,
    '&::-webkit-scrollbar': {
      color: colors.grey40,
    },
  },
  searchbarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 8px 0px 8px',
  },
  backIconWrapper: {
    cursor: 'pointer',
    paddingRight: 8,
  },
  listRoot: {
    padding: '24px 8px 0px 20px',
  },
  listHeader: {
    fontWeight: 500,
    fontSize: 14,
    color: colors.black,
    margin: '0px 24px 16px',
  },
}));
