import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    marginRight: 8,
    cursor: 'pointer',
    width: 255,
  },
  productImageWrapper: {
    width: 80,
    height: 80,
    minWidth: 80,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: colors.grey10,
  },
  productImage: {
    width: 80,
    height: 80,
    border: 'solid',
    borderWidth: 1,
    borderColor: colors.grey10,
    borderRadius: 8,
    overflow: 'hidden',
    objectFit: 'contain',
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 8,
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    color: colors.black,
    marginTop: 4,
  },
  price: {
    marginTop: 4,
    fontWeight: 400,
    fontSize: 14,
    color: colors.black,
  },
}));
