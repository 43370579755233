import React, { useEffect, useState } from 'react';

import { useAuthInfo } from '@amazd/common/hooks/auth';
import { useShopDetailsByAmazdId } from '@amazd/common/hooks/useShopDetails';
import { StreamUserExtended, useChannelActors, useChannelPeerUser } from '@amazd/common/hooks/useStreamChannel';
import { CloseIcon } from '@amazd/common/icons';
import CustomerInfoIcon from '@amazd/common/icons/CustomerInfoIcon';
import ScheduleCallIcon from '@amazd/common/icons/ScheduleCallIcon';
import SurveyTabIcon from '@amazd/common/icons/SurveyTabIcon';
import WishBagIcon from '@amazd/common/icons/WishBagIcon';
import { colors } from '@amazd/common/static';
import { trackCallsPageView } from '@amazd/common/utils/analytics';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useScreenSize } from 'hooks/useScreenSize';
import { useRouter } from 'next/router';
import { screenSize1280 } from 'styles/theme';
import { CompanyExpert } from 'views/InboxView/useCompanyExperts';

import { AnimatedCustomerSidebar } from './animatedSidebar';
import { CallTab } from './CallTab';
import { ChatSidebarTabKey, useChatSidebarContext } from './context/sidebarContext';
import { CustomerOverviewTab } from './OverviewTab';
import useStyles from './styles';
import { SurveyTab } from './SurveyTab';
import { AntTab, AntTabs } from './tabs';
import { WishBagTab } from './WishBagTab';

export interface ChatSidebarProps {
  companyExperts?: CompanyExpert[];
}

export const ChatSidebar = ({ companyExperts }: ChatSidebarProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { ownUser } = useAuthInfo();
  const { showSideBar, setShowSideBar, channel, selectedTab, setSelectedTab } = useChatSidebarContext();

  const { expert, owner } = useChannelActors(channel);
  const { peerUser } = useChannelPeerUser(channel);

  const showCustomerTabs = expert?.id === ownUser?.id || companyExperts;
  // if own user is not a member of this conversation
  const disableExpertFunctions = ownUser?.id !== expert?.id && ownUser?.id !== owner?.id;

  const size = useScreenSize();
  const isScreenBiggerThan1280 = size.x >= screenSize1280;

  const [animationPlaying, setAnimationPlaying] = useState(false);
  const {
    details: { shopDomain },
    loading: detailsLoading,
  } = useShopDetailsByAmazdId(channel?.id as string);

  const showShopTabs = !!shopDomain && !detailsLoading;

  const { query } = useRouter();

  const getInitialTab = () => {
    if (expert?.id !== ownUser?.id) return ChatSidebarTabKey.call;

    if (query.selectedTab === 'wishbag') {
      return showShopTabs ? ChatSidebarTabKey.wishBag : ChatSidebarTabKey.overview;
    }

    if (query.selectedTab === 'call') {
      return showShopTabs ? ChatSidebarTabKey.call : ChatSidebarTabKey.call - 1;
    }

    if (query.selectedTab === 'survey') {
      return showShopTabs ? ChatSidebarTabKey.survey : ChatSidebarTabKey.survey - 1;
    }

    return ChatSidebarTabKey.overview;
  };

  useEffect(() => {
    if (query?.selectedTab) {
      setSelectedTab(getInitialTab());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopDomain]);

  useEffect(() => {
    if (disableExpertFunctions && selectedTab !== ChatSidebarTabKey.overview)
      setSelectedTab(ChatSidebarTabKey.overview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerUser, disableExpertFunctions]);

  const renderTabs = () => {
    if (showCustomerTabs) {
      switch (selectedTab) {
        case 0:
          return (
            <CustomerOverviewTab
              peerUser={peerUser as StreamUserExtended}
              disableExpertFunctions={disableExpertFunctions}
            />
          );
        case 1:
          return showShopTabs ? <WishBagTab /> : <CallTab />;
        case 2: {
          trackCallsPageView();
          return showShopTabs ? <CallTab /> : <SurveyTab />;
        }
        case 3:
          return <SurveyTab />;
      }
    } else {
      trackCallsPageView();
      return <CallTab />;
    }
  };

  if (!channel || !channel.id) return null;

  return (
    <AnimatedCustomerSidebar
      showSideBar={showSideBar}
      setShowSideBar={setShowSideBar}
      animationPlaying={animationPlaying}
      setAnimationPlaying={setAnimationPlaying}
    >
      <>
        <div className={classes.header}>
          {showCustomerTabs ? (
            <>
              <AntTabs
                value={selectedTab}
                onChange={(_, newValue) => {
                  setSelectedTab(newValue);
                }}
              >
                <AntTab icon={<CustomerInfoIcon color={colors.amazdPurple} style={{ fontSize: 18 }} />} />
                {showShopTabs &&
                  (disableExpertFunctions ? (
                    <Tooltip
                      arrow={true}
                      placement="bottom"
                      title={disableExpertFunctions ? `${t('sidepanel-wishbag-disabled-tooltip')}` : ''}
                    >
                      <div>
                        <AntTab icon={<WishBagIcon color={colors.grey60} style={{ fontSize: 24 }} />} />
                      </div>
                    </Tooltip>
                  ) : (
                    <AntTab icon={<WishBagIcon color={colors.amazdPurple} style={{ fontSize: 24 }} />} />
                  ))}
                <AntTab icon={<ScheduleCallIcon color={colors.amazdPurple} style={{ fontSize: 40 }} />} />
                <AntTab icon={<SurveyTabIcon color={colors.amazdPurple} style={{ fontSize: '20px' }} />} />
              </AntTabs>
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                {(!animationPlaying || !isScreenBiggerThan1280) && (
                  <IconButton onClick={() => setShowSideBar(false)}>
                    <CloseIcon color={'inherit'} style={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </Box>
            </>
          ) : (
            <div />
          )}
        </div>
        <div className={classes.tabWrapper}>{renderTabs()}</div>
      </>
    </AnimatedCustomerSidebar>
  );
};
